import { WhatsappTemplatePayload } from "@jugl-web/rest-api/integrations/types";

export const getWhatsappTemplateComponentsList = ({
  header,
  body,
  footer,
  variables,
}: {
  header: string;
  footer: string;
  body: string;
  variables: string[];
}): WhatsappTemplatePayload["template"]["components"] => {
  const components = [];

  if (header) {
    components.push({
      text: header,
      format: "TEXT",
      type: "HEADER",
    });
  }

  if (body) {
    components.push({
      text: body,
      type: "BODY",
      example: {
        body_text: [variables?.length ? variables : [""]],
      },
    });
  }

  if (footer) {
    components.push({
      text: footer,
      type: "Footer",
    });
  }

  return components;
};
