import { Checkbox, PlainButton } from "@jugl-web/ui-components/cross-platform";
import { cx, onEnter } from "@jugl-web/utils";
import { Linkify } from "@jugl-web/utils/utils/Linkify";
import { Mentionify } from "@jugl-web/utils/utils/Mentionify";
import { FC, MouseEvent } from "react";
import { AssigneePickerButton } from "./AssigneePickerButton";
import { DueDatePickerButton } from "./DueDatePickerButton";
import { TaskChecklistItemContainer } from "./TaskChecklistItemContainer";
import { TaskChecklistItemContextMenu } from "./TaskChecklistItemContextMenu";
import { TaskChecklistItemNumber } from "./TaskChecklistItemNumber";
import { useTaskChecklistContext } from "./TaskChecklistProvider";
import { ReactComponent as MenuLargeIcon } from "./icons/menu-large.svg";
import { TaskChecklistItem } from "./types";
import { SpecificOrderCompletionState } from "./utils";

export interface ViewTaskChecklistItemProps {
  item: TaskChecklistItem;
  index: number;
  specificOrderCompletionState: SpecificOrderCompletionState;
  onUpdate: (updatedItem: TaskChecklistItem) => void;
  onToggleComplete: () => void;
  onEnterEditMode: () => void;
  onDelete: (itemId: string) => void;
}

export const ViewTaskChecklistItem: FC<ViewTaskChecklistItemProps> = ({
  item,
  index,
  specificOrderCompletionState,
  onUpdate,
  onToggleComplete,
  onEnterEditMode,
  onDelete,
}) => {
  const {
    entityId,
    displayDueDateAs,
    onlyReportees,
    isManageable,
    isCompletable,
    isAssignable,
    canEditItem,
    onTriggerDeleteConfirmationDialog,
  } = useTaskChecklistContext();

  const handleClick = (event: MouseEvent) => {
    const isLink = !!(event.target as HTMLElement).closest("a");

    if (!isLink) {
      onEnterEditMode();
    }
  };

  const handleDeleteWithConfirmation = (itemId: string) => {
    onTriggerDeleteConfirmationDialog({
      onConfirm: () => onDelete(itemId),
      metadata: { item },
    });
  };

  const isItemEditable = isManageable || canEditItem(item);

  return (
    <TaskChecklistItemContainer
      className={cx(
        "relative flex items-center transition-all duration-300",
        isItemEditable && "hover:shadow-task-checklist-item cursor-pointer"
      )}
      tabIndex={isItemEditable ? 0 : -1}
      onClick={isItemEditable ? handleClick : undefined}
      onKeyDown={isItemEditable ? onEnter(onEnterEditMode) : undefined}
    >
      <TaskChecklistItemNumber
        index={index}
        isHighlighted={specificOrderCompletionState.isCurrent}
        className="mr-4"
      />
      <div className="flex min-w-[40%] grow flex-col gap-1.5 self-center">
        <div
          className={cx(
            "whitespace-pre-wrap break-words text-sm leading-[21px]",
            item.isCompleted
              ? "font-normal text-[#828282] line-through"
              : "font-normal text-black"
          )}
        >
          <Linkify>
            <Mentionify>{item.text}</Mentionify>
          </Linkify>
        </div>
        {(item.assigneeId || item.dueDate) && (
          <div className="flex items-center gap-3">
            {isAssignable && item.assigneeId && (
              <AssigneePickerButton
                itemMode="normal"
                isDisabled={!isItemEditable}
                entityId={entityId}
                assigneeId={item.assigneeId}
                onlyReportees={onlyReportees}
                onAssigneeIdChange={(assigneeId) =>
                  onUpdate({ ...item, assigneeId })
                }
              />
            )}
            {item.dueDate && (
              <DueDatePickerButton
                itemMode="normal"
                displayAs={displayDueDateAs}
                isDisabled={!isItemEditable}
                dueDate={item.dueDate}
                onDueDateChange={(dueDate) => onUpdate({ ...item, dueDate })}
              />
            )}
          </div>
        )}
      </div>
      <div className="ml-2 flex items-center gap-4">
        {isManageable && (
          <div className="leading-[0]">
            <TaskChecklistItemContextMenu
              itemId={item.id}
              onDelete={handleDeleteWithConfirmation}
              renderTrigger={({ Trigger, triggerRef }) => (
                <Trigger
                  as={PlainButton}
                  ref={triggerRef}
                  className="hover:bg-grey-200 shrink-0 rounded-full transition-colors"
                  onClick={(event) => event.stopPropagation()}
                >
                  <MenuLargeIcon />
                </Trigger>
              )}
            />
          </div>
        )}
        {isCompletable && isItemEditable && (
          <Checkbox
            isChecked={item.isCompleted}
            isDisabled={specificOrderCompletionState.isDisabled}
            onClick={(event) => event.stopPropagation()}
            onKeyDown={onEnter((event) => {
              event.stopPropagation();
              onToggleComplete();
            })}
            onChange={onToggleComplete}
          />
        )}
      </div>
    </TaskChecklistItemContainer>
  );
};
