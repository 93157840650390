import { useTranslations } from "@jugl-web/utils";
import { TabsLayout } from "@web-src/modules/common/components/TabsLayout";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { FC, PropsWithChildren } from "react";

type TabId = "account" | "task-updates";

export const WhatsappTabsLayout: FC<
  PropsWithChildren<{
    selectedTab: TabId;
    rightContent?: React.ReactNode;
    areTabsHidden?: boolean;
  }>
> = ({ children, selectedTab, rightContent, areTabsHidden }) => {
  const { navigateToPage } = useNavigation();
  const { t } = useTranslations();

  return (
    <TabsLayout
      breadcrumbProps={{
        items: [
          {
            title: t({
              id: "whatsapp-tabs-layout-component.whatsapp-integration",
              defaultMessage: "WhatsApp Integration",
            }),
          },
        ],
      }}
      rightContent={rightContent}
      selectedTab={selectedTab}
      tabs={
        !areTabsHidden
          ? [
              {
                id: "account",
                title: t({
                  id: "whatsapp-tabs-layout-component.account",
                  defaultMessage: "Account",
                }),
                onClick: () => navigateToPage("integrationsWhatsappAccount"),
              },
              {
                id: "task-updates",
                title: t({
                  id: "whatsapp-tabs-layout-component.task-updates",
                  defaultMessage: "Task Updates",
                }),
                onClick: () => navigateToPage("integrationsWhatsappTemplates"),
              },
            ]
          : []
      }
    >
      {children}
    </TabsLayout>
  );
};
