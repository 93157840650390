import format from "date-fns/format";
import { sanitize } from "dompurify";
import React from "react";
import { ReactComponent as BubbleIcon } from "./assets/bubble.svg";
import whatsappBg from "./assets/whatsapp-bg.webp";

export const TemplatePreview: React.FC<{
  header: string;
  bodyPlain: string;
  bodyHtml: string;
  footer: string;
}> = ({ header, bodyPlain, bodyHtml, footer }) => (
  <div
    className="w-[320px] shrink-0 rounded-lg bg-[#ece6de] bg-cover bg-center p-10"
    style={{ backgroundImage: `url(${whatsappBg})` }}
  >
    <div className="text-dark font-secondary relative flex min-h-[80px] w-full flex-col break-words rounded-lg bg-white px-3 py-2 leading-3">
      {header && <span className="mb-2.5 font-bold">{header}</span>}
      {bodyPlain && (
        <div
          className="mb-2.5"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: sanitize(bodyHtml, { ADD_ATTR: ["target"] }),
          }}
        />
      )}
      {footer && <span className="mb-2 text-sm text-[#828282]">{footer}</span>}
      <div className="mt-auto ml-auto">
        <span className="font-primary text-[13px] leading-[16px] text-[#9C9CA3]">
          {format(new Date(), "h:mm a")}
        </span>
      </div>
      <BubbleIcon className="absolute left-0 top-0 -translate-x-1/2" />
    </div>
  </div>
);
