import React, {
  useMemo,
  useState,
  useCallback,
  createContext,
  PropsWithChildren,
  useContext,
} from "react";
import { ThemeProvider } from "@mui/material/styles";
import { cx, HookOutOfContextError } from "@jugl-web/utils";

// utils
import { getSupportedPlatForm } from "@web-src/utils/device";
import { useLocalStorage } from "@jugl-web/utils/hooks/useStorage";

// themes
import getTheme from "@web-src/themes/theme";
import { Argument, ArgumentArray } from "classnames";
import { APP_THEME_KEY } from "@jugl-web/utils/storage";

type ThemeMode = "light" | "dark" | "blue";

interface CustomThemeContextValue {
  customTheme: ThemeMode;
  setCustomTheme: (theme: CustomThemeContextValue["customTheme"]) => void;
  tcx: (
    ...args: Array<Argument | Partial<Record<ThemeMode, ArgumentArray>>>
  ) => string;
  darkMode: boolean;
  device: string | null;
  setDevice?: (e: string) => void;
}

const CustomThemeContext = createContext<CustomThemeContextValue | null>(null);

const CustomThemeProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [device, setDevice] = useState(() => getSupportedPlatForm());
  const theme = getTheme();
  const [customTheme, setCustomTheme] = useLocalStorage<
    CustomThemeContextValue["customTheme"]
  >(APP_THEME_KEY, "blue");

  const setDeviceHandler = (type: string) => {
    setDevice(type);
  };

  // Example usage (remove later):
  // tcx("bg-gray-500 ...", {
  //   blue: "text-blue-500",
  //   dark: "text-dark-500",
  //   light: "text-light-500",
  // }, ...);
  const tcx: CustomThemeContextValue["tcx"] = useCallback(
    (...args) =>
      cx(
        args.map((item) => {
          if (
            item &&
            typeof item === "object" &&
            ("blue" in item || "dark" in item || "light" in item)
          ) {
            return cx((item as Record<ThemeMode, ArgumentArray>)[customTheme]);
          }
          return item;
        })
      ),
    [customTheme]
  );

  const contextValue = useMemo(
    () => ({
      tcx,
      customTheme,
      setCustomTheme,
      darkMode: false,
      device,
      setDevice: setDeviceHandler,
    }),
    [customTheme, device, setCustomTheme, tcx]
  );

  return (
    <CustomThemeContext.Provider value={contextValue}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </CustomThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(CustomThemeContext);

  if (!context) {
    throw new HookOutOfContextError("useTheme", "ThemeContext");
  }

  return context;
};

export { CustomThemeContext, CustomThemeProvider };
