import { useRestApiProvider } from "@jugl-web/rest-api";
import { TasksSource } from "@jugl-web/rest-api/tasks";
import { useToast, useTranslations } from "@jugl-web/utils";
import { useCallback } from "react";

interface UseDuplicateTaskOptions {
  entityId: string;
  onNavigate: (taskId: string) => void;
}

export const useDuplicateTask = ({
  entityId,
  onNavigate,
}: UseDuplicateTaskOptions) => {
  const { tasksApi } = useRestApiProvider();
  const [getTask] = tasksApi.useLazyGetTaskQuery();
  const [createTask, { isLoading }] = tasksApi.useCreateTaskMutation();

  const { toast, closeToast } = useToast();
  const { t } = useTranslations();

  const showTaskDuplicatedToast = useCallback(
    (taskId: string) => {
      const toastId = toast(
        t({
          id: "feedback.task-duplicated-tap-to-open",
          defaultMessage: "Task has been duplicated. Tap to open it",
        }),
        {
          SnackbarProps: {
            style: {
              cursor: "pointer",
              userSelect: "none",
            },
            onClick: () => {
              onNavigate(taskId);
              closeToast(toastId);
            },
          },
        }
      );
    },
    [closeToast, onNavigate, t, toast]
  );

  const duplicateTask = useCallback(
    async (taskId: string, source: TasksSource) => {
      const taskResponse = await getTask({ entityId, taskId });

      if (taskResponse.data) {
        const createResponse = await createTask({
          entityId,
          task: {
            due_at: taskResponse.data.due_at,
            priority: taskResponse.data.priority,
            checklist: taskResponse.data.checklist,
            reminder: taskResponse.data.reminder,
            desc: taskResponse.data.desc,
            assignees: taskResponse.data.assignees,
            board_id: taskResponse.data.board_id,
            label_id: taskResponse.data.label_id,
            status: taskResponse.data.status,
            cust_id: taskResponse.data.cust_id,
            custom_fields: taskResponse.data.custom_fields,
            has_chklist_chk: taskResponse.data.has_chklist_chk,
            is_self: taskResponse.data.is_self,
            recurrence: taskResponse.data.recurrence,
            name: `${taskResponse.data.name} (copy)`,
            tz: taskResponse.data.tz,
            attach_dir_id: taskId,
          },
          source,
        });

        if (createResponse && "data" in createResponse) {
          return createResponse.data;
        }
      }

      throw new Error("Failed to duplicate the task");
    },
    [entityId, createTask, getTask]
  );

  return { duplicateTask, showTaskDuplicatedToast, isLoading };
};
