import { AxiosResponse } from "axios";

export type WhatsappAPIError = AxiosResponse<
  | { errors: { field: string; message: string }[] }
  | { error: { error_user_msg: string } }
>;

export function isWhatsappApiError(error: unknown): error is WhatsappAPIError {
  if (
    error &&
    typeof error === "object" &&
    "data" in error &&
    typeof (error as AxiosResponse).data === "object"
  ) {
    const { data } = error as AxiosResponse;

    if (
      "errors" in data &&
      Array.isArray(data.errors) &&
      data.errors.every(
        (e: { field: string; message: string }) =>
          typeof e.field === "string" && typeof e.message === "string"
      )
    ) {
      return true;
    }

    if (
      "error" in data &&
      typeof data.error === "object" &&
      typeof data.error.error_user_msg === "string"
    ) {
      return true;
    }
  }

  return false;
}
