import { SUPPORTED_WHATS_APP_VARIABLES } from "@jugl-web/domain-resources/integrations/consts";
import { WhatsappBody } from "../types/WhatsappBody";

/**
 * Converts a raw body string to a WhatsappBody.
 * @param rawBody The raw body string to convert.
 * @returns The WhatsappBody.
 * @example
 * rawBodyToWhatsappBody("Hello, *{{Customer Name}}*!"); // { body: "Hello, *{{1}}*!", variables: ["Customer Name"] }
 */
export const rawBodyToWhatsappBody = (rawBody: string): WhatsappBody => {
  let body = rawBody;
  const variables: string[] = [];
  SUPPORTED_WHATS_APP_VARIABLES.forEach((variable) => {
    const rawVariable = `{{${variable}}}`;
    if (body.includes(rawVariable)) {
      body = body.replaceAll(`{{${variable}}}`, `{{${variables.length + 1}}}`);
      variables.push(variable);
    }
  });
  return {
    body,
    variables,
  };
};
