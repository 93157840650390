import { ReactNode } from "react";
import { Tooltip, PlainButton } from "@jugl-web/ui-components";
import { useTheme } from "@web-src/themes/CustomThemeProvider";

export const IconButton = ({
  tooltipText,
  content,
  isActive,
  className,
  onClick,
}: {
  tooltipText: ReactNode;
  content?: ReactNode;
  isActive?: boolean;
  className?: string;
  onClick?: () => void;
}) => {
  const { tcx } = useTheme();

  return (
    <Tooltip
      renderTrigger={({ ref, props }) => (
        <PlainButton
          ref={ref}
          {...props}
          onClick={onClick}
          className={tcx(
            "easy-out rounded-lg transition-colors duration-300",
            {
              light: ["hover:bg-primary-700", { "bg-primary-700": isActive }],
              dark: ["hover:bg-[#333333]", { "bg-[#333333]": isActive }],
              blue: ["hover:bg-primary-700", { "bg-primary-700": isActive }],
            },
            className
          )}
        >
          {content}
        </PlainButton>
      )}
    >
      <span className="font-secondary text-sm">{tooltipText}</span>
    </Tooltip>
  );
};
