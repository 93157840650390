import { WhatsappBody } from "../types/WhatsappBody";

/**
 * Converts a WhatsappBody to a raw body string.
 * @param whatsappBody The WhatsappBody to convert.
 * @returns The raw body string.
 * @example
 * whatsappBodyToRawBody({
 *   body: "Hello, *{{1}}*!",
 *   variables: ["Customer Name"],
 * }); // "Hello, *{{Customer Name}}*!"
 */
export const whatsappBodyToRawBody = (whatsappBody: WhatsappBody): string => {
  let rawBody = whatsappBody.body;
  whatsappBody.variables.forEach((variable, idx) => {
    rawBody = rawBody.replaceAll(`{{${idx + 1}}}`, `{{${variable}}}`);
  });
  return rawBody;
};
