import { TaskDefaultStatus } from "../tasks";

export enum WhatsappTemplateCategory {
  marketing = "MARKETING",
  utility = "UTILITY",
}

export enum WhatsappTemplateStatus {
  approved = "APPROVED",
  pending = "PENDING",
  rejected = "REJECTED",
  deleted = "DELETED",
}

export interface WhatsappTemplateValues {
  name: string;
  action: TaskDefaultStatus;
  header: string;
  bodyPlain: string;
  bodyHtml: string;
  footer: string;
}

export type WhatsappTemplatePayload = Pick<
  WhatsappTemplateValues,
  "name" | "action"
> & {
  template: {
    components: {
      text: string;
      type: string;
      format?: string;
      example?: {
        body_text: string[][];
      };
    }[];
  };
};

export enum WhatsappAgentLogStatus {
  sent = "sent",
  failed = "failed",
  payment_error = "payment_error",
}
