import parsePhoneNumber from "libphonenumber-js";

export const humanizeCustomerPhoneNumber = (
  customerPhoneNumber: string
): string => {
  let phoneNumberString = customerPhoneNumber;
  if (
    customerPhoneNumber.includes(",") &&
    customerPhoneNumber.split(",").length === 3
  ) {
    const [, code, phone] = customerPhoneNumber.split(",");
    phoneNumberString = `${code}${phone}`;
  }
  const phoneNumber = parsePhoneNumber(phoneNumberString);
  if (phoneNumber) {
    return phoneNumber.formatInternational();
  }
  return phoneNumberString;
};
