import { FC, ReactNode } from "react";
import { useCustomer, UseCustomerOptions } from "../../hooks/useCustomer";

interface CustomerRendererProps extends UseCustomerOptions {
  children: (props: ReturnType<typeof useCustomer>) => ReactNode;
}

export const CustomerRenderer: FC<CustomerRendererProps> = ({
  children,
  ...props
}) => children(useCustomer(props));
