import {
  InternalTaskCustomField,
  PreviewTask,
  TaskDefaultStatus,
  TaskPriority,
} from "@jugl-web/rest-api/tasks";
import { isoToLocalDate } from "@jugl-web/utils";
import isPast from "date-fns/isPast";
import isWithinInterval from "date-fns/isWithinInterval";
import { useCallback } from "react";
import { useTaskFields } from "./useTaskFields";
import { useTaskStatuses } from "./useTaskStatuses";
import { useTaskBoards } from "./useTaskBoards";

interface UseTaskSelectorsOptions {
  entityId: string;
}

export const useTaskSelectors = ({ entityId }: UseTaskSelectorsOptions) => {
  const { hasAccess: hasBoardAccess } = useTaskBoards({ entityId });
  const { getLabelById } = useTaskFields({ entityId });
  const { getStatusDetailsById } = useTaskStatuses({ entityId });

  const createSelectTasksByDueDateInterval = useCallback(
    (tasks: PreviewTask[]) => (from: Date, to: Date) =>
      tasks.filter((task) => {
        if (task.due_at === null) {
          return false;
        }

        return isWithinInterval(isoToLocalDate(task.due_at), {
          start: from,
          end: to,
        });
      }),
    []
  );

  const createSelectOverdueTasks = useCallback(
    (tasks: PreviewTask[]) =>
      (options: { excludeInterval?: Interval } = {}) =>
        tasks.filter((task) => {
          if (task.status === TaskDefaultStatus.completed) {
            return false;
          }

          if (task.due_at === null) {
            return false;
          }

          const dueAtDate = isoToLocalDate(task.due_at);

          const isOutOfRange =
            options.excludeInterval &&
            isWithinInterval(dueAtDate, options.excludeInterval);

          if (isOutOfRange) {
            return false;
          }

          return isPast(dueAtDate);
        }),
    []
  );

  const createSelectNoDueDateTasks = useCallback(
    (tasks: PreviewTask[]) => () =>
      tasks.filter((task) => task.due_at === null),
    []
  );

  const createSelectTasksByLabelId = useCallback(
    (tasks: PreviewTask[]) => (labelId: string | null) =>
      tasks.filter((task) => {
        if (labelId) {
          return task.label_id === labelId;
        }

        // To get all tasks without label, we return tasks without label or tasks
        // with label that doesn't exist anymore
        return task.label_id === null || !getLabelById(task.label_id);
      }),
    [getLabelById]
  );

  const createSelectTasksByStatus = useCallback(
    (tasks: PreviewTask[]) => (status: TaskDefaultStatus | string) =>
      tasks.filter((task) => {
        // `getStatusDetailsById` returns `not-started` default status as a fallback
        // when task points to non-existing custom status
        const { id: actualStatusIdOrFallbackStatusId } = getStatusDetailsById(
          task.status
        );

        return status === actualStatusIdOrFallbackStatusId;
      }),
    [getStatusDetailsById]
  );

  const createSelectTasksByReporteeId = useCallback(
    (tasks: PreviewTask[]) => (userId: string) =>
      tasks.filter(
        (task) =>
          task.assignees.includes(userId) ||
          (task.created_by === userId && task.assignees.length === 0)
      ),
    []
  );

  const createSelectTasksByAssigneeId = useCallback(
    (tasks: PreviewTask[]) => (userId: string | null) => {
      if (userId === null) {
        return tasks.filter((task) => task.assignees.length === 0);
      }

      return tasks.filter((task) => task.assignees.includes(userId));
    },
    []
  );

  const createSelectTasksByBoardId = useCallback(
    (tasks: PreviewTask[]) => (boardId: string | null) =>
      tasks.filter((task) => task.board_id === boardId),
    []
  );

  const createSelectTasksFromRestrictedBoards = useCallback(
    (tasks: PreviewTask[]) => () =>
      tasks.filter((task) => task.board_id && !hasBoardAccess(task.board_id)),
    [hasBoardAccess]
  );

  const createSelectTasksByCustomerId = useCallback(
    (tasks: PreviewTask[]) => (customerId: string | null) =>
      tasks.filter((task) => task.cust_id === customerId),
    []
  );

  const createSelectTasksByPriority = useCallback(
    (tasks: PreviewTask[]) => (taskPriority: TaskPriority) =>
      tasks.filter((task) => task.priority === taskPriority),
    []
  );

  const createSelectTasksByCustomDropdownFieldValue = useCallback(
    (tasks: PreviewTask[]) =>
      (
        id: string,
        value: string | null,
        customField: InternalTaskCustomField
      ) =>
        tasks.filter((task) => {
          if (!value) {
            return (
              !task.custom_fields?.[id] ||
              // Return also tasks which have selected options
              // that don't exist in custom field anymore
              (task.custom_fields?.[id] &&
                !customField.values?.find(
                  (dropdownOption) =>
                    dropdownOption.id === task.custom_fields?.[id]
                ))
            );
          }
          return task.custom_fields?.[id] === value;
        }),
    []
  );

  return {
    createSelectTasksByDueDateInterval,
    createSelectOverdueTasks,
    createSelectNoDueDateTasks,
    createSelectTasksByLabelId,
    createSelectTasksByStatus,
    createSelectTasksByReporteeId,
    createSelectTasksByAssigneeId,
    createSelectTasksByBoardId,
    createSelectTasksFromRestrictedBoards,
    createSelectTasksByCustomerId,
    createSelectTasksByPriority,
    createSelectTasksByCustomDropdownFieldValue,
  };
};
