import { useRestApiProvider } from "@jugl-web/rest-api";
import { Switch } from "@jugl-web/ui-components/cross-platform/Switch";
import {
  useEntityProvider,
  useEntitySelectedProvider,
} from "@web-src/modules/entities/providers/EntityProvider";
import React from "react";

export const TemplateStatusTile: React.FC<{
  status: boolean;
  templateId: string;
  onChange: (status: boolean) => void;
}> = ({ status, templateId, onChange }) => {
  const { entityId } = useEntitySelectedProvider();
  const { integrationsApi } = useRestApiProvider();
  const [changeWhatsappTemplateStatus, { isLoading }] =
    integrationsApi.useChangeWhatsappTemplateStatusMutation();
  const { subscriptionRequiredAction } = useEntityProvider();

  const handleChangeWhatsappTemplateStatus = async (isEnabled: boolean) => {
    const response = await changeWhatsappTemplateStatus({
      entityId,
      isEnabled,
      templateId,
    });
    if (response && "data" in response) {
      onChange(isEnabled);
    }
  };

  return (
    <div className="flex h-full w-full items-center justify-center">
      <Switch
        isChecked={status}
        onChange={subscriptionRequiredAction(
          handleChangeWhatsappTemplateStatus
        )}
        isDisabled={isLoading}
      />
    </div>
  );
};
