import { useCallback } from "react";
import readXlsxFile, { Row } from "read-excel-file";
import Papa from "papaparse";

export const useParseFile = () => {
  const removeColumnsWithoutName = (array: Row[]) => {
    const columnsToRemove: number[] = [];

    array[0].forEach((value, colIndex) => {
      if (!value) {
        columnsToRemove.push(colIndex);
      }
    });

    columnsToRemove.reverse().forEach((colIndex) => {
      array.forEach((row) => {
        row.splice(colIndex, 1);
      });
    });

    return array;
  };

  const filterRowsWithoutValues = (row: Row) =>
    row.length > 0 && row.some((el) => !!el);

  const parseFile = useCallback(
    (file: File) =>
      new Promise<Row[]>((resolve, reject) => {
        if (file.type === "text/csv") {
          Papa.parse(file, {
            header: false,
            skipEmptyLines: true,
            complete: ({ data: result }) => {
              const filteredRows = removeColumnsWithoutName(
                result as Row[]
              ).filter(filterRowsWithoutValues);

              if (filteredRows.length > 1) {
                resolve(filteredRows);
              } else {
                reject(new Error("File is empty"));
              }
            },
            error: (error) => {
              reject(new Error(`Error parsing CSV file: ${error.message}`));
            },
          });
        } else {
          readXlsxFile(file)
            .then((result) => {
              const filteredRows = removeColumnsWithoutName(result).filter(
                filterRowsWithoutValues
              );

              if (filteredRows.length > 1) {
                resolve(filteredRows);
              } else {
                reject(new Error("File is empty"));
              }
            })
            .catch((error) => {
              reject(new Error(`Error reading Excel file: ${error.message}`));
            });
        }
      }),
    []
  );

  return { parseFile };
};
