import useEntity from "@web-src/features/app/hooks/useEntity";
import { QueryStatus } from "@reduxjs/toolkit/dist/query";
import { useSelector } from "react-redux";
import { selectAuthDataState } from "@web-src/features/auth/authSlice";
import { useUserGeneralProfile } from "@jugl-web/domain-resources/users/hooks/useUserGeneralProfile";

export const useMe = () => {
  const { entity } = useEntity();
  const authData = useSelector(selectAuthDataState);
  const meJid = authData?.data?.jid;
  const { profile, status } = useUserGeneralProfile({
    // TODO: make sure we don't use it outside entity context
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    entityId: entity?.id,
    userId: meJid,
  });

  return {
    id: meJid,
    me: profile?._user,
    isLoading: status === QueryStatus.pending,
    profile,
  };
};
