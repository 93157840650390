import { ReactNode } from "react";
import { InteractiveContainer, Tooltip } from "@jugl-web/ui-components";
import { useTheme } from "@web-src/themes/CustomThemeProvider";

export const SidebarLinkButton = ({
  text,
  icon,
  isActive,
  tooltip,
  isSidebarExpanded,
  className,
  onClick,
}: {
  text: ReactNode;
  icon: ReactNode;
  isActive?: boolean;
  tooltip?: ReactNode;
  isSidebarExpanded?: boolean;
  className?: string;
  onClick?: () => void;
}) => {
  const { tcx } = useTheme();
  return (
    <Tooltip
      isDisabled={isSidebarExpanded || !tooltip}
      placement="right"
      renderTrigger={({ props, ref }) => (
        <InteractiveContainer {...props} onClick={onClick} ref={ref}>
          <div
            className={tcx(
              "font-secondary text-primary-50 mx-2.5 flex h-[41px] items-center gap-2 rounded-lg px-2.5 text-sm",
              {
                light: [
                  "text-dark hover:bg-[#ECF5FC]",
                  {
                    "bg-primary-50 text-[#133C84]": isActive,
                  },
                ],
                dark: [
                  "hover:bg-[#3E3F40]",
                  {
                    "bg-[#4D4D4D]": isActive,
                  },
                ],
                blue: [
                  "hover:bg-[#2F7DDA]",
                  {
                    "bg-primary-800": isActive,
                  },
                ],
              },
              isActive ? "font-semibold" : "",
              isSidebarExpanded
                ? "w-[190px]"
                : "w-[60px] flex-col justify-center text-center",
              className
            )}
          >
            <div className="flex h-full items-center justify-center">
              {icon}
            </div>
            {isSidebarExpanded && (
              <div
                title={text?.toString()}
                className="flex h-full w-full items-center pr-2"
              >
                <span className="w-3/4 truncate">{text}</span>
              </div>
            )}
          </div>
        </InteractiveContainer>
      )}
    >
      {tooltip}
    </Tooltip>
  );
};
