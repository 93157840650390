import { PageNavParams } from "@web-src/modules/navigation/types";
import { ModulePagesConfig } from "@web-src/modules/navigation/types/ModulePagesConfigs";
import ShortLinkRedirectPage from "./ShortLinkRedirectPage";
import { DownloadAttachmentPage } from "./DownloadAttachmentPage";

export type CommonPagesNavigationParams = {
  commonShortLinkRedirect: PageNavParams<{ shortLinkId: string }>;
  commonDownloadAttachmentPage: PageNavParams<
    { attachmentId: string },
    { n: string }
  >;
};

export const commonPagesNavigationConfig: ModulePagesConfig<CommonPagesNavigationParams> =
  {
    commonShortLinkRedirect: {
      element: <ShortLinkRedirectPage />,
      path: "/L/:shortLinkId",
      isPrivate: true,
      isEntityOptional: true,
    },
    commonDownloadAttachmentPage: {
      element: <DownloadAttachmentPage />,
      path: "/A/:shortLinkId",
      isPrivate: true,
      isEntityOptional: true,
    },
  };
