import { PlainButton } from "@jugl-web/ui-components/cross-platform/PlainButton";
import { cx } from "@jugl-web/utils";
import { ButtonHTMLAttributes, ReactNode, forwardRef } from "react";

export interface ControlBarButtonProps
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, "disabled"> {
  label?: ReactNode;
  startSlot?: ReactNode;
  endSlot?: ReactNode;
  isActive?: boolean;
  isDisabled?: boolean;
}

export const ControlBarButton = forwardRef<
  HTMLButtonElement,
  ControlBarButtonProps
>(
  (
    { label, startSlot, endSlot, isActive, isDisabled, className, ...props },
    ref
  ) => (
    <PlainButton
      ref={ref}
      className={cx(
        "jugl__focusable-outline flex h-[40px] min-w-[40px] shrink-0 items-center justify-center gap-2 rounded-lg px-2 text-[#828282] transition-colors hover:bg-white",
        isActive && "bg-white",
        className
      )}
      disabled={isDisabled}
      {...props}
    >
      {startSlot}
      {label && (
        <span className="text-dark-800 font-secondary truncate font-normal leading-[21px]">
          {label}
        </span>
      )}
      {endSlot}
    </PlainButton>
  )
);
