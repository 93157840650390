import { ModuleNotificationsDialog } from "@jugl-web/domain-resources/module-notifications/components/ModuleNotificationsDialog";
import { useModuleNotificationsUnreadIndicator } from "@jugl-web/domain-resources/module-notifications/hooks/useModuleNotificationsUnreadIndicator";
import { useTaskViewsContext } from "@jugl-web/domain-resources/tasks";
import { useTaskFiltering } from "@jugl-web/domain-resources/tasks/components/TaskFilteringProvider";
import { useTaskSorting } from "@jugl-web/domain-resources/tasks/components/TaskSortingProvider";
import {
  Button,
  Menu,
  PlainButton,
} from "@jugl-web/ui-components/cross-platform";
import { SpotlightTooltip } from "@jugl-web/ui-components/cross-platform/SpotlightTooltip";
import { ControlBarButton } from "@jugl-web/ui-components/web/ControlBarButton";
import { ExpandableSearchBar } from "@jugl-web/ui-components/web/ExpandableSearchBar";
import {
  assignRefs,
  cx,
  useSearchInput,
  useTranslations,
} from "@jugl-web/utils";
import { TasksPageLayout } from "@web-src/components/TasksPageLayout";
import { selectUserId } from "@web-src/features/auth/authSlice";
import { useTasksPageContext } from "@web-src/features/tasks/TasksPageContext";
import { Tab } from "@web-src/modules/common/components/TabsLayout/components/Tab";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useFCM } from "@web-src/modules/notifications/providers/FCMProvider";
import { useOnboarding } from "@web-src/modules/preferences/providers";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { FutureTaskDialog } from "../FutureTaskDialog";
import { NewTaskDialog } from "../NewTaskDialog/NewTaskDialog";
import { TaskCalendarViewsPopover } from "../TaskCalendarViewsPopover";
import { TaskFiltersDialog } from "../TaskFiltersDialog";
import { TaskFiltersPopover } from "../TaskFiltersPopover";
import { TaskKanbanViewsPopover } from "../TaskKanbanViewsPopover";
import { TaskRefreshButton } from "../TaskRefreshButton";
import { ReactComponent as AddIcon } from "./assets/add.svg";
import { ReactComponent as BellIcon } from "./assets/bell.svg";
import { ReactComponent as ChevronDownIcon } from "./assets/chevron-down.svg";
import { ReactComponent as FilterIcon } from "./assets/filter.svg";
import { ReactComponent as ResetFiltersIcon } from "./assets/reset-filters.svg";
import { ReactComponent as SortIcon } from "./assets/sort.svg";
import { NewTaskPopover } from "./components/NewTaskPopover";

export const TasksControlBar = () => {
  const [isTasksFiltersDialogOpen, setIsTasksFiltersDialogOpen] =
    useState(false);

  const [isNotificationsDialogOpen, setIsNotificationsDialogOpen] =
    useState(false);

  const {
    tasksSource,
    tasksSourceInfo,
    taskListLayout,
    taskCalendarView,
    setTaskListLayout,
    navigateToTaskDetailsPage,
  } = useTasksPageContext();

  const { selectedView } = useTaskViewsContext();

  const { hasActiveFilter, searchQuery, resetFiltering, changeSearchQuery } =
    useTaskFiltering();

  const { sortingOptions, sortingButtonLabelByType, changeSorting } =
    useTaskSorting();

  const {
    isOnboardingActive,
    openedSpotlight,
    openSpotlight,
    completeOnboardingStep,
  } = useOnboarding();

  const { entity } = useEntitySelectedProvider();
  const { logEvent } = useFCM();

  const userBoardId =
    tasksSource.type === "boardTasks" && tasksSourceInfo.isUserBoardTasks
      ? tasksSource.boardId
      : undefined;

  const {
    isUnread: hasUnreadNotifications,
    markAsRead: markNotificationsAsRead,
  } = useModuleNotificationsUnreadIndicator({
    entityId: entity.id,
    module: "task",
    boardId: userBoardId,
  });

  const meId = useSelector(selectUserId);
  const hasRequiredParams = entity && meId;

  const { t } = useTranslations();

  const { inputProps } = useSearchInput({ defaultValue: searchQuery });

  const calendarViewLabel = useMemo(() => {
    switch (taskCalendarView) {
      case "week":
        return t({
          id: "tasks-page.week",
          defaultMessage: "Week",
        });
      case "month":
        return t({
          id: "tasks-page.month",
          defaultMessage: "Month",
        });
      case "year":
        return t({
          id: "tasks-page.year",
          defaultMessage: "Year",
        });
      default:
        return t({
          id: "tasks-page.week",
          defaultMessage: "Week",
        });
    }
  }, [t, taskCalendarView]);

  return (
    <TasksPageLayout.ControlBar>
      <div className="flex h-[56px] items-center gap-4 self-end">
        <div className="flex items-center gap-1 self-end">
          <Tab
            title="Kanban"
            isSelected={taskListLayout === "kanban"}
            onClick={() => setTaskListLayout("kanban")}
          />
          <Tab
            title="Table"
            isSelected={taskListLayout === "table"}
            onClick={() => setTaskListLayout("table")}
          />
          <Tab
            title="Calendar"
            isSelected={taskListLayout === "calendar"}
            onClick={() => setTaskListLayout("calendar")}
          />
        </div>
        <div className="bg-grey-300 h-7 w-px" />
        {!tasksSourceInfo.isTeamTasks && (
          <>
            {taskListLayout === "calendar" && (
              <TaskCalendarViewsPopover
                renderTrigger={({ Trigger, triggerRef, isOpen }) => (
                  <Trigger
                    ref={triggerRef}
                    as={ControlBarButton}
                    label={t(
                      {
                        id: "tasks-page-view-by-view-name",
                        defaultMessage: "View by: {viewName}",
                      },
                      { viewName: calendarViewLabel }
                    )}
                    endSlot={
                      <ChevronDownIcon
                        className={cx(
                          "transition-transform",
                          isOpen && "rotate-180"
                        )}
                      />
                    }
                    isActive={isOpen}
                    className="hover:bg-grey-100 px-2.5"
                  />
                )}
              />
            )}
            {taskListLayout !== "calendar" && (
              <TaskKanbanViewsPopover
                renderTrigger={({ Trigger, triggerRef, isOpen }) => (
                  <Trigger
                    ref={triggerRef}
                    as={ControlBarButton}
                    label={t(
                      {
                        id: "tasks-page-view-by-view-name",
                        defaultMessage: "View by: {viewName}",
                      },
                      { viewName: selectedView.viewByTitle }
                    )}
                    endSlot={
                      <ChevronDownIcon
                        className={cx(
                          "transition-transform",
                          isOpen && "rotate-180"
                        )}
                      />
                    }
                    isActive={isOpen}
                    className="hover:bg-grey-100 px-2.5"
                  />
                )}
              />
            )}
          </>
        )}
        {/* SORTING */}
        <Menu
          placement="bottom-start"
          onSelect={(item, event, close) => {
            event.stopPropagation();
            close();
            changeSorting(item.value || "lastUpdated");
          }}
          sections={[sortingOptions]}
          renderTrigger={({ Trigger, triggerRef, isOpen }) => (
            <Trigger
              ref={triggerRef}
              as={ControlBarButton}
              label={sortingButtonLabelByType.concise}
              startSlot={<SortIcon />}
              isActive={isOpen}
            />
          )}
        />
        {/* NOTIFICATIONS */}
        {!tasksSourceInfo.isTeamTasks && (
          <ControlBarButton
            startSlot={
              <span className="relative flex items-center justify-center gap-2">
                <BellIcon />
                {hasUnreadNotifications && (
                  <div
                    className={cx(
                      "bg-gradients-danger absolute -right-0.5 -top-0.5 h-2.5 w-2.5 rounded-full border-[1.5px] border-solid border-white"
                    )}
                  />
                )}
              </span>
            }
            className="hover:bg-grey-100 px-2.5"
            onClick={() => {
              setIsNotificationsDialogOpen(true);
              markNotificationsAsRead();
            }}
          />
        )}
        {/* FILTERS */}
        <TaskFiltersPopover
          entityId={entity.id}
          renderTrigger={({ Trigger, triggerRef, isOpen }) => (
            <Trigger
              ref={triggerRef}
              as={ControlBarButton}
              className="max-w-[250px] truncate"
              startSlot={
                <span className="relative flex items-center justify-center gap-2">
                  <FilterIcon className="fill-grey" />
                  {hasActiveFilter && (
                    <div
                      className={cx(
                        "bg-gradients-success absolute -right-0.5 -top-0.5 h-2.5 w-2.5 rounded-full border-[1.5px] border-solid border-white"
                      )}
                    />
                  )}
                </span>
              }
              endSlot={
                hasActiveFilter ? (
                  <PlainButton
                    className="hover:bg-grey-300 rounded-full bg-[#EEF2F5] transition-colors"
                    onClick={(event) => {
                      event.stopPropagation();
                      resetFiltering();
                    }}
                  >
                    <ResetFiltersIcon />
                  </PlainButton>
                ) : undefined
              }
              isActive={isOpen || hasActiveFilter}
            />
          )}
          onOpenFiltersDialog={() => setIsTasksFiltersDialogOpen(true)}
        />
        {/* SEARCH */}
        <ExpandableSearchBar
          color="white"
          size="lg"
          onSearch={changeSearchQuery}
          defaultValue={searchQuery}
          {...inputProps}
        />
      </div>
      <div className="flex items-center gap-4">
        <TaskRefreshButton entityId={entity.id} source={tasksSource} />
        <NewTaskPopover
          entityId={entity.id}
          hasBackdrop
          placement="bottom-end"
          renderTrigger={({ Trigger, triggerRef }) => (
            <SpotlightTooltip
              isOpen={openedSpotlight === "task"}
              renderTrigger={({ props, ref }) => (
                <Trigger
                  as={Button}
                  ref={assignRefs([triggerRef, ref])}
                  className="h-[40px] whitespace-nowrap px-8"
                  iconEnd={<AddIcon />}
                  onClick={() => {
                    logEvent("action_task_create");
                    openSpotlight(undefined);
                  }}
                  {...props}
                >
                  {t({ id: "tasks-page.new-task", defaultMessage: "New task" })}
                </Trigger>
              )}
              placement="bottom"
              tip={t({
                id: "tasks-page.create-task-onboarding-hint",
                defaultMessage: "Tap here to create your first task ✨",
              })}
              showButton={false}
              onDismiss={() => openSpotlight(undefined)}
            />
          )}
          onBeforeCreateTask={() => logEvent("action_task_create_send")}
          onAfterCreateTask={() => {
            if (isOnboardingActive) {
              completeOnboardingStep("task");
            }
          }}
        />
      </div>
      {hasRequiredParams && (
        <>
          <ModuleNotificationsDialog
            isOpen={isNotificationsDialogOpen}
            entityId={entity.id}
            module="task"
            boardId={userBoardId}
            onNotificationClick={(log) => {
              navigateToTaskDetailsPage(log.task.id);
              setIsNotificationsDialogOpen(false);
              logEvent("action_task_notification");
            }}
            onClose={() => setIsNotificationsDialogOpen(false)}
          />
          <TaskFiltersDialog
            entityId={entity.id}
            isOpen={isTasksFiltersDialogOpen}
            onClose={() => setIsTasksFiltersDialogOpen(false)}
          />
          <NewTaskDialog entityId={entity.id} meId={meId} />
          <FutureTaskDialog entityId={entity.id} meId={meId} />
        </>
      )}
    </TasksPageLayout.ControlBar>
  );
};
