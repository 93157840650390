import { useRestApiProvider } from "@jugl-web/rest-api";
import { useCallback } from "react";
import writeXlsxFile, { SheetData } from "write-excel-file";
import { ExportedTaskModel } from "@jugl-web/rest-api/tasks/models/ExportedTask";
import {
  ExportTasksParams,
  ExportTaskVariants,
} from "@jugl-web/rest-api/tasks/types";
import { useTranslations } from "@jugl-web/utils";
import { useTaskFields } from "../useTaskFields";
import { formatUserName } from "./utils/utils";
import { useExportedTableRowGenerator } from "./utils/useExportedTableRowGenerator";
import { useTaskBoards } from "../useTaskBoards";

export const useExportTasks = ({
  entityId,
  meId,
}: {
  entityId: string;
  meId: string;
}) => {
  const { tasksApi, usersApi } = useRestApiProvider();

  const [getUserGeneralProfile] = usersApi.useLazyGetUserGeneralProfileQuery();

  const [exportTasks, { isLoading: isExportTasksError }] =
    tasksApi.useLazyExportTasksQuery();
  const { isError } = useTaskFields({ entityId });
  const { getBoardByName } = useTaskBoards({ entityId });

  const { t } = useTranslations();
  const {
    generateNameRows,
    generateHeaderRow,
    generateTaskRow,
    generateTaskChecklistsHeaderRow,
    generateTaskChecklistRow,
  } = useExportedTableRowGenerator({ entityId });

  const groupTasks = useCallback(
    (
      tasks: ExportedTaskModel[]
    ): { name: string; data: ExportedTaskModel[] }[] => {
      const groupedTasks: { [key: string]: ExportedTaskModel[] } = {};
      tasks.forEach((task) => {
        const hasBoardAccess = task.board && getBoardByName(task.board?.name);

        const boardName = !task.board?.name
          ? "Without Board"
          : hasBoardAccess
          ? task.board.name
          : t({
              id: "tasks-export.board-locked",
              defaultMessage: "Board 🔒",
            });
        if (!groupedTasks[boardName]) {
          groupedTasks[boardName] = [];
        }
        groupedTasks[boardName].push(task);
      });

      let result = Object.keys(groupedTasks).map((boardName) => ({
        name: boardName,
        data: groupedTasks[boardName],
      }));

      const withoutBoard = result.find(
        (group) => group.name === "Without Board"
      );
      result = result.filter((group) => group.name !== "Without Board");

      if (withoutBoard) {
        result.push({
          ...withoutBoard,
          name: t({
            id: "tasks-page.without-board",
            defaultMessage: "Without Board",
          }),
        });
      }

      return result;
    },
    [getBoardByName, t]
  );

  const groupTeamTasks = useCallback(
    async (
      tasks: ExportedTaskModel[],
      params?: ExportTasksParams
    ): Promise<{ name: string; data: ExportedTaskModel[] }[]> => {
      const groupedTasks: { [key: string]: ExportedTaskModel[] } = {};

      tasks.forEach((task) => {
        if (task.assignees.length === 0) {
          if (!groupedTasks["Without User"]) {
            groupedTasks["Without User"] = [];
          }
          groupedTasks["Without User"].push(task);
        } else {
          task.assignees.forEach(async (assignee) => {
            const userName = formatUserName(assignee);

            if (!groupedTasks[`${userName},${assignee.user_id}`]) {
              groupedTasks[`${userName},${assignee.user_id}`] = [];
            }

            groupedTasks[`${userName},${assignee.user_id}`].push(task);
          });
        }
      });

      let result = await Promise.all(
        Object.keys(groupedTasks).map(async (userNameWithId) => {
          const userProfile = await getUserGeneralProfile(
            { entityId, params: { user_id: userNameWithId.split(",")[1] } },
            true
          );
          const isSelectedUser = params?.user_id
            ? params.user_id?.includes(userNameWithId.split(",")[1])
            : true;
          const isReporteeOrMe =
            userProfile.data?.managers.includes(meId) ||
            userProfile.data?.id === meId;
          if (isReporteeOrMe && isSelectedUser) {
            return {
              name: userNameWithId.split(",")[0],
              data: groupedTasks[userNameWithId],
              userId: userNameWithId.split(",")[1],
            };
          }
          return null;
        })
      );

      result = result
        .filter((item) => item !== null)
        .sort((a, b) => {
          if (a === null || b === null) {
            return 0;
          }
          if (a.userId === meId && b.userId !== meId) {
            return -1;
          }
          if (a.userId !== meId && b.userId === meId) {
            return 1;
          }
          return a.name.localeCompare(b.name);
        });

      const withoutUser = result.find(
        (group) => group?.name === "Without User"
      );

      result = result.filter((group) => group?.name !== "Without User");

      if (withoutUser) {
        result.push({
          ...withoutUser,
          name: t({
            id: "tasks-page.without-user",
            defaultMessage: "Without User",
          }),
        });
      }
      return result.filter((group) => group !== null) as {
        name: string;
        data: ExportedTaskModel[];
      }[];
    },
    [entityId, getUserGeneralProfile, meId, t]
  );

  const generateExcel = useCallback(
    async (type: "tasks" | "team" | "board", params?: ExportTasksParams) => {
      const translateToApiType: { [key: string]: ExportTaskVariants } = {
        tasks: "my",
        team: "team",
        board: "team",
      };

      const tasks = await exportTasks({
        entityId,
        params: { ...params, view: translateToApiType[type] },
      });

      const processedData: SheetData = [];

      if ((tasks && "errors" in tasks) || isExportTasksError || isError) {
        throw new Error("Unable to export tasks");
      }

      if (tasks && "data" in tasks && tasks.data && tasks.data.length === 0) {
        throw new Error("No tasks to export");
      }

      if (tasks && "data" in tasks && tasks.data) {
        const groupedTasks =
          type === "tasks" || type === "board"
            ? groupTasks(tasks.data)
            : await groupTeamTasks(tasks.data, params);
        groupedTasks.forEach((el) => {
          processedData.push(...generateNameRows(el.name));
          processedData.push(generateHeaderRow(type === "tasks"));
          el.data.forEach((task) => {
            processedData.push(generateTaskRow(task, type === "tasks"));
            if (task.checklist?.length) {
              processedData.push(generateTaskChecklistsHeaderRow());
              task.checklist.forEach((checklistItem) =>
                processedData.push(generateTaskChecklistRow(checklistItem))
              );
            }
          });
        });
      }

      if (processedData.length === 0) {
        throw new Error("No tasks to export");
      }

      await writeXlsxFile(processedData, {
        columns: [...Array(generateHeaderRow(type === "tasks").length)].map(
          () => ({
            width: 30,
          })
        ),
        fileName: "Jugl tasks.xlsx",
      });
    },
    [
      entityId,
      isError,
      isExportTasksError,
      exportTasks,
      groupTasks,
      groupTeamTasks,
      generateNameRows,
      generateHeaderRow,
      generateTaskRow,
      generateTaskChecklistsHeaderRow,
      generateTaskChecklistRow,
    ]
  );

  return { generateExcel };
};
