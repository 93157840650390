import {
  Environment,
  devAgoraAppId,
  devApiUrl,
  devFirebaseConfig,
  devFirebaseTokenId,
  devFirebaseVapidKey,
  devWsDomain,
  devSupersetDomain,
  mapTilerKey,
  devSupersetDashboardIds,
  devFacebookConfigId,
} from ".";

export const environment: Environment = {
  production: false,
  firebaseConfig: devFirebaseConfig,
  firebaseVapidKey: devFirebaseVapidKey,
  sentryDsn:
    "https://248a477985200422c4e892971cecf0c0@o1211156.ingest.us.sentry.io/4506969709412352",
  sentryWhitelistHosts: [
    "web-dev.jugl.com",
    /jugl-dev-org-web-rc-\d+-\d+-\d+.netlify.app/g,
  ],
  apiUrl: devApiUrl,
  wsDomain: devWsDomain,
  firebaseTokenId: devFirebaseTokenId,
  mapTilerKey,
  geocodingKey: "AIzaSyBRxeHe9et0iQSg8hjqABHLneRB16KpKpw",
  agoraAppId: devAgoraAppId,
  intercomAppId: "d8ychfjy",
  supersetDomain: devSupersetDomain,
  supersetDashboardIds: devSupersetDashboardIds,
  supersetChartIdToType: { 207: "tasks", 210: "people", 211: "travelLog" },
  isReCaptchaEnabled: true,
  reCaptchaKey: "6LdMnvopAAAAAID5SLUbjhOfVLc2BDzMlvJPr3nn",
  guestUrl: "https://guest-dev.jugl.com",
  facebookConfigId: devFacebookConfigId,
};
