import {
  PaginatedRequestParams,
  PaginatedResponse,
  RtkEmptySplitApi,
} from "../../types";
import { CustomerApiTags } from "./tags";
import { Customer, NewCustomer } from "./types";
import { getCustomerIdTag, getCustomerListTag } from "./utils";

export const addCustomerApi = (emptySplitApi: RtkEmptySplitApi) => {
  const apiWithTags = emptySplitApi.enhanceEndpoints({
    addTagTypes: [CustomerApiTags.customer],
  });
  const customerApi = apiWithTags.injectEndpoints({
    overrideExisting: false,
    endpoints: (build) => ({
      customer: build.query<
        { data: Customer },
        { entityId: string; customerId: string }
      >({
        query: ({ entityId, customerId }) => ({
          url: `/api/auth/entity/${entityId}/customers/${customerId}`,
          silentError: true,
        }),
        providesTags: (_result, _error, { customerId }) => [
          getCustomerIdTag(customerId),
        ],
      }),
      customersList: build.query<
        PaginatedResponse<Customer>,
        {
          entityId: string;
          params: PaginatedRequestParams<{
            search_term?: string;
          }>;
        }
      >({
        query: ({ entityId, params }) => ({
          url: `/api/auth/entity/${entityId}/customers`,
          params,
        }),
        onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
          try {
            const response = await queryFulfilled;

            response.data.data.forEach((customer) => {
              dispatch(
                customerApi.util.upsertQueryData(
                  "customer",
                  { entityId: args.entityId, customerId: customer.id },
                  { data: customer }
                )
              );
            });
          } catch {
            // Do nothing
          }
        },
        providesTags: (_result, _error, { entityId }) => [
          getCustomerListTag(entityId),
        ],
      }),
      addCustomer: build.mutation<
        { data: Customer },
        {
          entityId: string;
          fields: NewCustomer;
          logoFile?: File;
        }
      >({
        query: ({ entityId, fields, logoFile }) => {
          const formData = new FormData();
          formData.set("fields", JSON.stringify(fields));
          if (logoFile) formData.set("logo_file", logoFile);

          return {
            url: `/api/auth/entity/${entityId}/customers`,
            data: formData,
            method: "POST",
            headers: { "Content-Type": "multipart/form-data" },
          };
        },
        invalidatesTags: (_result, _error, { entityId }) => [
          getCustomerListTag(entityId),
        ],
      }),
      updateCustomer: build.mutation<
        { data: Customer },
        {
          entityId: string;
          fields: NewCustomer;
          logoFile?: File;
          customerId: string;
        }
      >({
        query: ({ entityId, customerId, fields, logoFile }) => {
          const binData = new FormData();
          binData.append("fields", JSON.stringify(fields));
          if (logoFile) binData.set("logo_file", logoFile);

          return {
            url: `/api/auth/entity/${entityId}/customers/${customerId}`,
            data: binData,
            method: "PATCH",
          };
        },
        invalidatesTags: (_result, _error, { entityId, customerId }) => [
          getCustomerIdTag(customerId),
          getCustomerListTag(entityId),
        ],
      }),
      deleteCustomer: build.mutation<
        void,
        {
          entityId: string;
          customerId: string;
        }
      >({
        query: ({ entityId, customerId }) => ({
          url: `/api/auth/entity/${entityId}/customers/${customerId}`,
          method: "DELETE",
        }),
        invalidatesTags: (_result, _error, { entityId }) => [
          getCustomerListTag(entityId),
        ],
      }),
    }),
  });

  return customerApi;
};

export type CustomerApi = ReturnType<typeof addCustomerApi>;
