import { LocationEvents, SummaryEvent } from "@jugl-web/rest-api";
import ct from "countries-and-timezones";
import { differenceInSeconds, format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { addZ } from "./addZ";

export const workdayDurationCalculator = (
  events: SummaryEvent[],
  date: string,
  userTimezone?: string
) => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const timezoneInfo = ct.getTimezone(userTimezone || timeZone);
  let dayEvents = events
    .filter(
      (element) =>
        element.start_time &&
        format(
          utcToZonedTime(addZ(element.start_time), userTimezone || timeZone),
          "yyyy-MM-dd"
        ).includes(date)
    )
    .sort((a, b) => {
      const aStartTime = utcToZonedTime(
        addZ(a.start_time),
        userTimezone || timeZone
      );
      const bStartTime = utcToZonedTime(
        addZ(b.start_time),
        userTimezone || timeZone
      );
      return aStartTime.getTime() - bStartTime.getTime();
    });

  // User has clock in only on this day
  const startEvent = dayEvents?.[0];
  if (startEvent?.type === LocationEvents.clock_in && dayEvents?.length === 1) {
    const seconds = differenceInSeconds(
      utcToZonedTime(new Date().toISOString(), userTimezone || timeZone),
      utcToZonedTime(addZ(startEvent.start_time), userTimezone || timeZone)
    );
    return seconds;
  }

  // User clocked in on previous day and clocked out on this day
  let totalValue = 0;
  if (dayEvents && dayEvents?.[0]?.type === LocationEvents.clock_out) {
    totalValue += differenceInSeconds(
      utcToZonedTime(addZ(dayEvents?.[0].start_time), userTimezone || timeZone),
      utcToZonedTime(
        new Date(`${date} 00:00:00 ${timezoneInfo?.dstOffsetStr}`),
        userTimezone || timeZone
      )
    );
    dayEvents = dayEvents.slice(1);
  }

  if (dayEvents && dayEvents.length > 1) {
    dayEvents?.forEach((element, index) => {
      const isBreakType =
        element.type === LocationEvents.clock_out ||
        element.type === LocationEvents.paused ||
        element.type === LocationEvents.offline;
      const isPreviousBreakType =
        dayEvents[index - 1]?.type === LocationEvents.clock_out ||
        dayEvents[index - 1]?.type === LocationEvents.paused ||
        dayEvents[index - 1]?.type === LocationEvents.offline;
      const dur = differenceInSeconds(
        utcToZonedTime(addZ(element.start_time), userTimezone || timeZone),
        utcToZonedTime(
          addZ(dayEvents[index - 1]?.start_time),
          userTimezone || timeZone
        )
      );
      const isLastOne = index + 1 === dayEvents.length;
      if (index === 0) {
        return;
      }
      if (isBreakType) {
        if (!isPreviousBreakType) {
          totalValue += dur;
        }
      }
      if (isLastOne && !isBreakType) {
        const currentTime = utcToZonedTime(
          new Date(),
          userTimezone || timeZone
        );
        const endTime =
          date === format(currentTime, "yyyy-MM-dd")
            ? new Date()
            : new Date(`${date}T23:59:59${timezoneInfo?.dstOffsetStr}`);

        const seconds = differenceInSeconds(
          utcToZonedTime(endTime, userTimezone || timeZone),
          utcToZonedTime(addZ(element.start_time), userTimezone || timeZone)
        );
        totalValue += seconds;
      }
      if (!isBreakType && !isPreviousBreakType) {
        totalValue += dur;
      }
    });
  }
  return Number(totalValue.toFixed(0));
};
