import React from "react";
import { ReactComponent as WhatsappIcon } from "./assets/whatsapp.svg";

export const TemplateNameTile: React.FC<{ name: string; text: string }> = ({
  name,
  text,
}) => (
  <div className="flex items-center gap-4 px-4 py-5">
    <WhatsappIcon className="shrink-0" />
    <div className="flex flex-col gap-0.5">
      <span className="text-dark-800 font-secondary font-medium leading-[140%] tracking-[0.16px]">
        {name}
      </span>
      <span className="text-grey font-secondary leading-[140%] tracking-[0.14px]">
        {text}
      </span>
    </div>
  </div>
);
