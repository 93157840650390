import {
  Alert,
  Button,
  DataLoadingWrapper,
  EmptyListContent,
  Menu,
  PlainButton,
  TableGrid,
  Tooltip,
} from "@jugl-web/ui-components";
import { useToast, useTranslations } from "@jugl-web/utils";
import React, { useCallback, useRef, useState } from "react";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import Lottie from "react-lottie";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { useEffectOnce } from "react-use";
import {
  useEntityProvider,
  useEntitySelectedProvider,
} from "@web-src/modules/entities/providers/EntityProvider";
import { WhatsappTemplateModel } from "@jugl-web/rest-api/integrations/models/WhatsappTemplate";
import { useTaskStatuses } from "@jugl-web/domain-resources/tasks/hooks/useTaskStatuses";
import { WhatsappTemplateStatus } from "@jugl-web/rest-api/integrations/types";
import whatsappAnimation from "./assets/whatsapp-animation.json";
import { ReactComponent as PlusIcon } from "./assets/plus.svg";
import { ReactComponent as InfoIcon } from "./assets/info.svg";
import { WhatsappTabsLayout } from "../../components/WhatsappTabsLayout";
import { TemplateNameTile } from "./components/TemplateNameTile";
import { ReactComponent as MoreIcon } from "./assets/more.svg";
import { ReactComponent as EditIcon } from "./assets/edit.svg";
import { ReactComponent as DeleteIcon } from "./assets/delete.svg";
import { TemplateApprovalTile } from "./components/TemplateApprovalTile";
import alertAnimation from "./assets/alert-animation.json";
import { TemplateStatusTile } from "./components/TemplateStatusTile";

type PageState = {
  pageNumber: number;
  requestId: number;
  isError?: boolean;
  hasMore?: boolean;
};

export const WhatsappTemplatesPage: React.FC = () => {
  const { t } = useTranslations();
  const { toast } = useToast({ variant: "web" });
  const { navigateToPage } = useNavigation();
  const [templateIdToDelete, setTemplateIdToDelete] = useState<string>();
  const currentPageState = useRef<PageState | null>(null);
  const { entityId } = useEntitySelectedProvider();
  const [whatsappTemplates, setWhatsappTemplates] = useState<
    WhatsappTemplateModel[]
  >([]);
  const { integrationsApi } = useRestApiProvider();
  const { getStatusDetailsById } = useTaskStatuses({
    entityId,
  });

  const { subscriptionRequiredAction } = useEntityProvider();

  const [getWhatsappTemplates, { isFetching, isLoading, isError }] =
    integrationsApi.useLazyGetWhatsappTemplatesQuery();

  const [
    deleteWhatsappTemplate,
    { isLoading: isDeleteWhatsappTemplateLoading },
  ] = integrationsApi.useDeleteWhatsappTemplateMutation();

  const loadNextPage = useCallback(async () => {
    let nextPageNumber = 1;
    if (currentPageState.current) {
      if (currentPageState.current.isError) {
        nextPageNumber = currentPageState.current.pageNumber;
      } else {
        nextPageNumber = currentPageState.current.pageNumber + 1;
      }
    }

    const nextPageState: PageState = {
      requestId: Math.random(),
      pageNumber: nextPageNumber,
    };

    currentPageState.current = nextPageState;

    const result = await getWhatsappTemplates({
      entityId,
      page: nextPageNumber,
      page_size: 10,
    });

    if (currentPageState.current?.requestId !== nextPageState.requestId) {
      return;
    }

    if ("error" in result && result.error) {
      nextPageState.isError = true;
      currentPageState.current = nextPageState;
      return;
    }

    if ("data" in result && result.data && result.data.data) {
      const responseData = result.data.data;
      setWhatsappTemplates((prev) =>
        nextPageNumber === 1 ? responseData : [...prev, ...responseData]
      );

      nextPageState.hasMore = result.data.total_pages > nextPageNumber;
      currentPageState.current = nextPageState;
    }
  }, [entityId, getWhatsappTemplates]);

  useEffectOnce(() => {
    loadNextPage();
  });

  const handleTemplateDelete = useCallback(async () => {
    if (!templateIdToDelete) return;
    const response = await deleteWhatsappTemplate({
      entityId,
      templateId: templateIdToDelete,
    });

    if (response && "data" in response) {
      setTemplateIdToDelete(undefined);
      toast(
        t({
          id: "whatsapp-templates-page.whatsapp-update-was-deleted",
          defaultMessage: "WhatsApp Update was deleted",
        })
      );
      setWhatsappTemplates([]);
      currentPageState.current = null;
      loadNextPage();
    }
  }, [
    deleteWhatsappTemplate,
    entityId,
    templateIdToDelete,
    t,
    toast,
    loadNextPage,
  ]);

  return (
    <WhatsappTabsLayout
      selectedTab="task-updates"
      rightContent={
        <Button
          iconEnd={<PlusIcon />}
          className="h-10"
          onClick={subscriptionRequiredAction(() =>
            navigateToPage("integrationsWhatsappTemplateCreate")
          )}
        >
          {t({
            id: "whatsapp-templates-page.create-update",
            defaultMessage: "Create update",
          })}
        </Button>
      }
    >
      <DataLoadingWrapper
        onRetry={loadNextPage}
        isError={isError}
        isLoading={
          isLoading ||
          (isFetching && currentPageState.current?.pageNumber === 1)
        }
        isFetching={isFetching}
      >
        {whatsappTemplates.length ? (
          <TableGrid
            unstyled
            data={whatsappTemplates}
            className="m-5 overflow-hidden rounded-xl border border-solid border-[#EEF2F5]"
            headerCellClassName="px-4 py-3 text-xs text-dark-700 font-normal leading-[140%] border-0 border-r border-solid border-[#EEF2F5]"
            cellClassName="px-4 text-dark-800 font-normal flex items-center text-sm py-3 border-0 border-t border-r border-solid border-[#EEF2F5] break-all leading-[140%] tracking-[0.14px] hover:bg-grey-200"
            rowHoverClassName="bg-grey-100"
            onReachEnd={() =>
              currentPageState.current?.hasMore && !isLoading && !isFetching
                ? loadNextPage()
                : undefined
            }
            columns={[
              {
                title: t({
                  id: "whatsapp-templates-page.name-and-content",
                  defaultMessage: "Name & content",
                }),
                grow: true,
                content: ({ name, body }) => (
                  <TemplateNameTile text={body} name={name} />
                ),
              },
              {
                title: t({
                  id: "whatsapp-templates-page.action-trigger",
                  defaultMessage: "Action Trigger",
                }),
                grow: true,
                content: ({ action }) => (
                  <span className="text-dark font-secondary text-sm leading-[140%] tracking-[0.14px]">
                    {t(
                      {
                        id: "whatsapp-templates-page.trigger-status-set",
                        defaultMessage: "Status set to {action}",
                      },
                      {
                        action: (
                          <span className="underline">
                            {getStatusDetailsById(action).label}
                          </span>
                        ),
                      }
                    )}
                  </span>
                ),
              },
              {
                title: (
                  <Tooltip
                    placement="bottom"
                    renderTrigger={({ props, ref }) => (
                      <div
                        ref={ref}
                        {...props}
                        className="flex items-center gap-2.5"
                      >
                        <span>
                          {t({
                            id: "whatsapp-templates-page.status",
                            defaultMessage: "Status",
                          })}
                        </span>
                        <InfoIcon />
                      </div>
                    )}
                  >
                    <div className="flex flex-col gap-2.5">
                      <span>
                        {t({
                          id: "whatsapp-templates-page.template-active-mode-description",
                          defaultMessage:
                            "🟢 If Update is in Active Mode, WhatsApp Messages will be Sent to Clients when the Trigger Action will happen.",
                        })}
                      </span>
                      <span>
                        {t({
                          id: "whatsapp-templates-page.template-inactive-mode-description",
                          defaultMessage:
                            "⚪ If Update is in Inactive Mode, WhatsApp Messages will not be Sent to Clients when the Trigger Action will happen.",
                        })}
                      </span>
                    </div>
                  </Tooltip>
                ),
                grow: true,
                content: ({ enabled, id }) => (
                  <TemplateStatusTile
                    status={enabled}
                    templateId={id}
                    onChange={(status: boolean) =>
                      setWhatsappTemplates((prev) =>
                        prev.map((template) =>
                          template.id === id
                            ? {
                                ...template,
                                enabled: status,
                              }
                            : template
                        )
                      )
                    }
                  />
                ),
              },
              {
                title: t({
                  id: "whatsapp-templates-page.whatsapp-approval",
                  defaultMessage: "WhatsApp Approval",
                }),
                grow: true,
                className: "p-[3px]",
                content: ({ status }) => (
                  <TemplateApprovalTile status={status} />
                ),
              },
              {
                title: t({
                  id: "whatsapp-templates-page.settings",
                  defaultMessage: "Settings",
                }),
                className: "flex justify-center w-full border-r-0",
                headerClassName: "border-r-0",
                content: ({ id, status }) => (
                  <Menu
                    placement="bottom-end"
                    autoClose
                    renderTrigger={({ Trigger, triggerRef }) => (
                      <Trigger
                        as={PlainButton}
                        ref={triggerRef}
                        className="mt-1"
                        disabled={status === WhatsappTemplateStatus.deleted}
                      >
                        <MoreIcon />
                      </Trigger>
                    )}
                    className="py-2"
                    sections={[
                      [
                        {
                          id: "edit-update",
                          label: t({
                            id: "whatsapp-templates-page.edit-update",
                            defaultMessage: "Edit Update",
                          }),
                          icon: <EditIcon />,
                          onSelect: subscriptionRequiredAction(() =>
                            navigateToPage("integrationsWhatsappTemplateEdit", {
                              templateId: id,
                            })
                          ),
                        },
                        {
                          id: "delete-update",
                          label: t({
                            id: "whatsapp-templates-page.delete-update",
                            defaultMessage: "Delete Update",
                          }),
                          icon: <DeleteIcon />,
                          onSelect: subscriptionRequiredAction(() =>
                            setTemplateIdToDelete(id)
                          ),
                        },
                      ],
                    ]}
                  />
                ),
              },
            ]}
          />
        ) : (
          <EmptyListContent
            type="custom"
            className="w-[450px]"
            customImg={
              <Lottie
                options={{ animationData: whatsappAnimation }}
                height={200}
                width={200}
              />
            }
            customSubtitle={t({
              id: "whatsapp-templates-page.create-messages-templates-description",
              defaultMessage:
                "Create messages templates that will be sent to your Clients through WhatsApp as Order Updates",
            })}
            customTitle={t({
              id: "whatsapp-templates-page.whatsapp-integration-order-updates",
              defaultMessage: "WhatsApp Integration for Order updates ✨",
            })}
            customButton={{
              text: t({
                id: "whatsapp-templates-page.create-update",
                defaultMessage: "Create Update",
              }),
              onClick: subscriptionRequiredAction(() =>
                navigateToPage("integrationsWhatsappTemplateCreate")
              ),
              className: "h-10",
              fullWidth: false,
              iconEnd: <PlusIcon />,
            }}
          />
        )}
      </DataLoadingWrapper>
      <Alert
        isOpen={!!templateIdToDelete}
        onRequestClose={() => setTemplateIdToDelete(undefined)}
        img={
          <Lottie
            options={{ animationData: alertAnimation }}
            height={70}
            width={70}
          />
        }
        title={t({
          id: "common.confirm-action",
          defaultMessage: "Confirm Action",
        })}
        content={t({
          id: "whatsapp-templates-page.delete-update-confirmation-message",
          defaultMessage:
            "If you delete this Update, you will no longer be able to restore it",
        })}
        buttons={[
          {
            text: t({
              id: "common.cancel",
              defaultMessage: "Cancel",
            }),
            role: "close",
          },
          {
            text: t({
              id: "common.delete",
              defaultMessage: "Delete",
            }),
            color: "tertiary",
            isDisabled: isDeleteWhatsappTemplateLoading,
            onClick: handleTemplateDelete,
          },
        ]}
      />
    </WhatsappTabsLayout>
  );
};
