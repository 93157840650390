import { WhatsappTemplateDto } from "./WhatsappTemplateDto";
import { WhatsappTemplateModel } from "./WhatsappTemplateModel";

export const transformWhatsappTemplateDtoToModel = (
  dto: WhatsappTemplateDto
): WhatsappTemplateModel => {
  const headerObj = dto.tmpl.components.find((el) => el.type === "HEADER");
  const bodyObj = dto.tmpl.components.find((el) => el.type === "BODY");
  const footerObj = dto.tmpl.components.find((el) => el.type === "Footer");

  return {
    id: dto.id,
    name: dto.tmpl.name,
    action: dto.action,
    header: headerObj?.text || "",
    body: bodyObj?.text || "",
    footer: footerObj?.text || "",
    enabled: dto.enabled,
    status: dto.status,
    variables:
      bodyObj?.type === "BODY" ? bodyObj.example.body_text[0] || [] : [],
  };
};
