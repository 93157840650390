import React from "react";
import { Menu, PageLoaderFull, PlainButton } from "@jugl-web/ui-components";
import { useTranslations } from "@jugl-web/utils";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { ReactComponent as WhatsappIcon } from "./assets/whatsapp.svg";
import { ReactComponent as MenuIcon } from "./assets/menu.svg";
import { ReactComponent as EditIcon } from "./assets/edit.svg";
import { ReactComponent as LeaveIcon } from "./assets/leave.svg";
import { ReactComponent as UserIcon } from "./assets/user.svg";

export const ConnectedAccountTile: React.FC = () => {
  const { t } = useTranslations();
  const { integrationsApi } = useRestApiProvider();
  const [disconnectWhatsappAgent, { isLoading: isDisconnectLoading }] =
    integrationsApi.useDisconnectWhatsappAgentAccountMutation();
  const { entityId } = useEntitySelectedProvider();
  const { data: whatsappAgent } = integrationsApi.useWhatsappAgentAccountQuery({
    entityId,
  });

  if (!whatsappAgent) {
    return null;
  }

  return (
    <>
      <PageLoaderFull isActive={isDisconnectLoading} isTransparent />
      <div className="bg-grey-100 relative flex w-max items-center gap-6 rounded-2xl p-8">
        <div className="bg-gradients-g1 flex h-20 w-20 items-center justify-center overflow-hidden rounded-[20px]">
          {whatsappAgent.profile[0]?.profile_picture_url ? (
            <img
              src={whatsappAgent.profile[0]?.profile_picture_url}
              className="h-full w-full object-cover object-center"
              alt={whatsappAgent.name}
            />
          ) : (
            <UserIcon />
          )}
        </div>
        <div className="flex w-[400px] flex-col gap-1">
          <span className="text-dark font-secondary text-[22px] font-medium leading-[150%]">
            {whatsappAgent.name}
          </span>
          <span className="text-grey font-secondary text-sm leading-[140%] tracking-[0.14px]">
            Account ID: {whatsappAgent.waba_id} •{" "}
            {whatsappAgent.phone_number.display_phone_number}
          </span>
          <div className="bg-secondary/5 flex w-max items-center gap-2 rounded-lg p-2">
            <span className="text-secondary-600 font-secondary text-sm font-medium leading-[140%] tracking-[0.14px]">
              {t({
                id: "whatsapp-account-page.connected-whatsapp-account",
                defaultMessage: "Connected WhatsApp Account",
              })}
            </span>
            <WhatsappIcon />
          </div>
        </div>
        <Menu
          placement="bottom-end"
          sections={[
            [
              {
                id: "disconnect-account",
                label: t({
                  id: "whatsapp-account-page.disconnect-account",
                  defaultMessage: "Disconnect Account",
                }),
                icon: <LeaveIcon />,
                onSelect: () => disconnectWhatsappAgent({ entityId }),
              },
              {
                id: "manage-account",
                label: t({
                  id: "whatsapp-account-page.manage-account",
                  defaultMessage: "Manage Account",
                }),
                icon: <EditIcon />,
                onSelect: () =>
                  window.open(
                    `https://business.facebook.com/home/accounts?business_id=${whatsappAgent.waba_id}`,
                    "_blank"
                  ),
              },
            ],
          ]}
          renderTrigger={({ Trigger, triggerRef }) => (
            <Trigger
              as={PlainButton}
              ref={triggerRef}
              className="absolute right-3 top-3"
            >
              <MenuIcon />
            </Trigger>
          )}
        />
      </div>
    </>
  );
};
