import { CustomerApiTags } from "./tags";

export const getCustomerIdTag = (customerId: string) => ({
  type: CustomerApiTags.customer,
  id: `CUSTOMER-${customerId}`,
});

export const getCustomerListTag = (entityId: string) => ({
  type: CustomerApiTags.customer,
  id: `ENTITY--${entityId}`,
});
