import {
  downloadBlobAsFile,
  useDownloadFile,
} from "@jugl-web/domain-resources/drive";
import { useCallback, useEffect, useState } from "react";
import { useFilePreview } from "@jugl-web/domain-resources/files/providers/FilePreviewProvider";
import { DataLoadingWrapper } from "@jugl-web/ui-components";
import { useAppDispatch } from "@web-src/store";
import { hideSideBar } from "@web-src/features/app/appSlice";
import { PreviewAttachmentDetails } from "@jugl-web/domain-resources/files/types";
import { useSearchParams, useParams } from "react-router-dom";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { useTranslations, useToast } from "@jugl-web/utils";
import { useEffectOnce } from "react-use";

export const DownloadAttachmentPage = () => {
  const [searchParams] = useSearchParams();
  const attachmentName = searchParams.get("n");
  const { downloadFile } = useDownloadFile();
  const { toast } = useToast({ variant: "web" });
  const { previewFile } = useFilePreview();
  const { t } = useTranslations();
  const { linksApi, tasksApi } = useRestApiProvider();
  const { shortLinkId } = useParams();
  const [isPreviewLoading, setIsPreviewLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const dispatch = useAppDispatch();

  const [getShortLinkData] = linksApi.useLazyGetShortLinkDataQuery();

  const [loadTaskDetails] = tasksApi.useLazyGetTaskQuery();

  const handleDownloadAttachment = useCallback(
    async (attachmentDetails: PreviewAttachmentDetails, entityId: string) => {
      if (!entityId) {
        return;
      }
      toast(
        t({
          id: "common.downloading",
          defaultMessage: "Downloading",
        }),
        { variant: "info" }
      );
      const file = await downloadFile({
        entityId,
        fileName: attachmentDetails.name,
        mimeType: attachmentDetails.contentType,
        fileSize: attachmentDetails.size,
        id: attachmentDetails.id,
      });

      if ("data" in file) {
        downloadBlobAsFile(file.data, attachmentDetails.name);
      } else {
        setIsError(true);
      }
    },
    [downloadFile, t, toast]
  );

  useEffect(() => {
    dispatch(hideSideBar());
  }, [dispatch]);

  useEffectOnce(() => {
    const handleAttachmentPreview = async () => {
      if (!shortLinkId) {
        setIsError(true);
        return;
      }

      const shortLinkResponse = await getShortLinkData({ shortLinkId });

      if (!shortLinkResponse.data || "error" in shortLinkResponse) {
        setIsError(true);
        return;
      }

      const { entityId, id } = shortLinkResponse.data;
      const taskData = await loadTaskDetails({ entityId, taskId: id });
      if (!taskData.data || !taskData.data.attachments) {
        setIsError(true);
        return;
      }

      const attachment = taskData.data.attachments.find(
        (item) => item.name === attachmentName
      );
      if (!attachment) {
        setIsError(true);
        return;
      }

      const attachmentDetails: PreviewAttachmentDetails = {
        contentType: attachment.content_type,
        id: attachment.id,
        name: attachment.name,
        size: attachment.size,
        streamUrl: attachment.stream_url,
      };

      previewFile({
        name: `${attachmentDetails.name}`,
        url: attachmentDetails.streamUrl,
        mimeType: attachmentDetails.contentType,
        isCloseDisabled: true,
        onDownload: () => handleDownloadAttachment(attachmentDetails, entityId),
      });
      setIsPreviewLoading(false);
    };
    handleAttachmentPreview();
  });

  return (
    <div className="fixed inset-0 bg-white">
      <DataLoadingWrapper
        className="h-screen w-screen bg-white"
        isLoading={isPreviewLoading && !isError}
        isError={isError}
      />
    </div>
  );
};
