import { Switch as HeadlessUISwitch } from "@headlessui/react";
import { cx } from "@jugl-web/utils";
import { ElementType, FC } from "react";

export type SwitchSize = "md";

interface SwitchDimensions {
  containerWidth: number;
  containerHeight: number;
  containerHorizontalPadding: number;
  handleWidth: number;
  handleHeight: number;
}

const sizeToDimensions: Record<SwitchSize, SwitchDimensions> = {
  md: {
    containerWidth: 28,
    containerHeight: 16,
    containerHorizontalPadding: 2,
    handleWidth: 12,
    handleHeight: 12,
  },
};

export interface SwitchProps {
  isChecked: boolean;
  size?: SwitchSize;
  as?: ElementType;
  id?: string;
  className?: string;
  isDisabled?: boolean;
  onChange?: (isChecked: boolean) => void;
}

export const Switch: FC<SwitchProps> = ({
  isChecked,
  size = "md",
  as,
  id,
  className,
  isDisabled,
  onChange,
}) => {
  const {
    containerWidth,
    containerHeight,
    containerHorizontalPadding,
    handleWidth,
    handleHeight,
  } = sizeToDimensions[size];

  return (
    <HeadlessUISwitch
      as={as}
      id={id}
      disabled={isDisabled}
      checked={isChecked}
      onChange={onChange}
      className={cx(
        "jugl__focusable-outline inline-flex cursor-pointer items-center rounded-full border-none p-0 transition-colors",
        isChecked
          ? "bg-gradients-success hover:bg-secondary-600"
          : "bg-grey-400 hover:bg-grey-500",
        isDisabled && "cursor-default",
        className
      )}
      style={{
        width: containerWidth,
        height: containerHeight,
      }}
    >
      <div
        className="rounded-full bg-white transition"
        style={{
          width: handleWidth,
          height: handleHeight,
          transform: isChecked
            ? `translateX(${
                containerWidth - handleWidth - containerHorizontalPadding
              }px)`
            : `translateX(${containerHorizontalPadding}px)`,
        }}
      />
    </HeadlessUISwitch>
  );
};
