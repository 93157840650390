import { cx } from "@jugl-web/utils";
import { AutoLinkPlugin } from "@lexical/react/LexicalAutoLinkPlugin";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { forwardRef, useMemo, useRef } from "react";
import { MaxLengthPlugin } from "./plugins/MaxLengthPlugin";
import { MentionsPlugin } from "./plugins/MentionsPlugin";
import { RestrictedPastePlugin } from "./plugins/RestrictedPastePlugin";
import { RichTextareaControllerPlugin } from "./plugins/RichTextareaControllerPlugin";
import { RichTextareaHandle, RichTextareaProps } from "./types";
import { getLexicalConfig, MATCHERS } from "./utils";

export const RichTextarea = forwardRef<RichTextareaHandle, RichTextareaProps>(
  (
    {
      namespace,
      mentions,
      mentionListType = "container-pinned",
      isDisabled = false,
      placeholder,
      classes,
      startSlot,
      endSlot,
      containerSlot,
      maxTextLength,
      onFocusStateChange,
      onRawTextChange,
      onEachUpdate,
    },
    ref
  ) => {
    const containerRef = useRef<HTMLDivElement | null>(null);

    const contentEditableId = `${namespace}-content-editable`;

    const config = useMemo(
      () => getLexicalConfig(namespace, isDisabled),
      [namespace, isDisabled]
    );

    const isMentionsPluginEnabled = !!mentions;

    return (
      <LexicalComposer initialConfig={config}>
        <>{startSlot}</>
        <div className={cx("relative", classes?.container)}>
          <div ref={containerRef} />
          <RichTextPlugin
            contentEditable={
              <ContentEditable
                id={contentEditableId}
                className={cx("p-2", classes?.contentEditable)}
              />
            }
            placeholder={
              placeholder ? (
                <div
                  className={cx(
                    "pointer-events-none absolute left-2 top-2 select-none overflow-hidden",
                    classes?.placeholder
                  )}
                >
                  {placeholder}
                </div>
              ) : null
            }
            ErrorBoundary={LexicalErrorBoundary}
          />
          {containerSlot}
        </div>
        <>{endSlot}</>
        <HistoryPlugin />
        <RestrictedPastePlugin editorNamespace={namespace} />
        {isMentionsPluginEnabled && (
          <MentionsPlugin
            mentions={mentions}
            listType={mentionListType}
            containerRef={containerRef}
          />
        )}
        <RichTextareaControllerPlugin
          handle={ref}
          contentEditableId={contentEditableId}
          onFocusStateChange={onFocusStateChange}
          onRawTextChange={onRawTextChange}
          onEachUpdate={onEachUpdate}
        />
        {maxTextLength ? <MaxLengthPlugin maxTextLength={maxTextLength} /> : ""}
        <ListPlugin />
        <LinkPlugin />
        <AutoLinkPlugin matchers={MATCHERS} />
      </LexicalComposer>
    );
  }
);
