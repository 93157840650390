import { useMarkModuleAsRead } from "@jugl-web/domain-resources/entities/hooks/useMarkModuleAsRead";
import { TaskViewsProvider } from "@jugl-web/domain-resources/tasks";
import { TaskFilteringProvider } from "@jugl-web/domain-resources/tasks/components/TaskFilteringProvider";
import { TaskSortingProvider } from "@jugl-web/domain-resources/tasks/components/TaskSortingProvider";
import { useTasksQuery } from "@jugl-web/domain-resources/tasks/hooks/useTasksQuery";
import { HeaderBreadcrumbsItem } from "@jugl-web/ui-components/web/HeaderBreadcrumbs";
import { isAPIError } from "@jugl-web/utils";
import { TaskListErrorScreen } from "@web-src/components/TaskListErrorScreen";
import { TasksPageLayout } from "@web-src/components/TasksPageLayout";
import { InteractiveUserBoardBreadcrumbItem } from "@web-src/features/tasks/InteractiveUserBoardBreadcrumbItem";
import { TasksContent } from "@web-src/features/tasks/TasksContent";
import { TasksControlBar } from "@web-src/features/tasks/TasksControlBar";
import {
  TasksPageContextProvider,
  useTasksPageContext,
} from "@web-src/features/tasks/TasksPageContext";
import { useTaskBreadcrumbs } from "@web-src/hooks/useTaskBreadcrumbs";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { useMemo } from "react";

const InnerTasks = () => {
  const { tasksSource, tasksSourceInfo } = useTasksPageContext();
  const { entityId } = useEntitySelectedProvider();

  const { error, refetch } = useTasksQuery({ entityId, source: tasksSource });

  const { getTaskListBreadcrumbs } = useTaskBreadcrumbs({ entityId });

  const { navigateToPage } = useNavigation();

  const breadcrumbs = useMemo<HeaderBreadcrumbsItem[]>(() => {
    if (tasksSource.type === "boardTasks" && tasksSourceInfo.isUserBoardTasks) {
      const customBreadcrumbItem: HeaderBreadcrumbsItem = {
        customRenderer: () => (
          <InteractiveUserBoardBreadcrumbItem
            entityId={entityId}
            boardId={tasksSource.boardId}
          />
        ),
      };

      return [customBreadcrumbItem];
    }

    return getTaskListBreadcrumbs(tasksSource);
  }, [
    entityId,
    getTaskListBreadcrumbs,
    tasksSource,
    tasksSourceInfo.isUserBoardTasks,
  ]);

  const userBoardId =
    tasksSource.type === "boardTasks" && tasksSourceInfo.isUserBoardTasks
      ? tasksSource.boardId
      : undefined;

  useMarkModuleAsRead({
    entityId,
    module: "task",
    boardId: userBoardId,
    skip:
      tasksSourceInfo.isCustomerTasks || tasksSourceInfo.isTeamTasks || !!error,
  });

  if (error) {
    const errorType = (() => {
      if (isAPIError(error)) {
        if (error.status === 403) {
          return "forbidden";
        }

        if (error.status === 400) {
          return "bad-request";
        }
      }

      return "unknown";
    })();

    return (
      <TaskListErrorScreen
        errorType={errorType}
        onNavigateToMyTasks={() =>
          navigateToPage("tasksTasks", { boardId: "my" })
        }
        onRefetch={refetch}
      />
    );
  }

  return (
    <TaskFilteringProvider entityId={entityId} source={tasksSource}>
      <TaskSortingProvider entityId={entityId} source={tasksSource}>
        <TaskViewsProvider entityId={entityId} source={tasksSource}>
          <TasksPageLayout breadcrumbs={breadcrumbs}>
            <TasksControlBar />
            <TasksContent />
          </TasksPageLayout>
        </TaskViewsProvider>
      </TaskSortingProvider>
    </TaskFilteringProvider>
  );
};

export const Tasks = () => (
  <TasksPageContextProvider>
    <InnerTasks />
  </TasksPageContextProvider>
);
