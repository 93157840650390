import { Button, Dialog, InteractiveContainer } from "@jugl-web/ui-components";
import React from "react";
import { useTranslations } from "@jugl-web/utils";
import automationUpdatesImage from "./assets/automation-updates.png";
import { ReactComponent as CrossIcon } from "./assets/cross.svg";
import { ReactComponent as WhatsappIcon } from "./assets/whatsapp.svg";

export const AutomationUpdatesDialog: React.FC<{
  isOpen: boolean;
  onRequestClose: () => void;
}> = ({ isOpen, onRequestClose }) => {
  const { t } = useTranslations();

  return (
    <Dialog isOpen={isOpen} onClose={onRequestClose} className="flex flex-col">
      <div className="flex min-h-[60px] items-center justify-between border-x-0 border-b border-t-0 border-solid border-b-gray-100 px-6 py-[14px]">
        <div className="flex items-center gap-3">
          <WhatsappIcon />
          <span className="text-sm font-semibold text-[#383838]">
            {t({
              id: "whatsapp-page.updates-automation",
              defaultMessage: "Updates Automation",
            })}
          </span>
        </div>
        <InteractiveContainer
          className="ml-auto h-8 w-8"
          onClick={onRequestClose}
        >
          <CrossIcon />
        </InteractiveContainer>
      </div>
      <div className="flex flex-col items-center overflow-y-auto px-20 pt-8 pb-14 text-center">
        <img src={automationUpdatesImage} alt="" />
        <span className="text-dark font-secondary mt-4 text-[22px] font-semibold leading-[150%]">
          {t({
            id: "whatsapp-page.customize-updates-with-variables",
            defaultMessage: "Customize updates with Variables!",
          })}
        </span>
        <span className="text-dark-800 mt-2 leading-[150%]">
          {t({
            id: "whatsapp-page.automation-updates-description",
            defaultMessage:
              "Add variables to personalize your WhatsApp updates for each Customer by automatically entering specific information such as Order Name, Customer Name, Order Status or other relevant information 😍",
          })}
        </span>
        <Button onClick={onRequestClose} className="mt-10 w-[200px]">
          {t({
            id: "common.start",
            defaultMessage: "Start",
          })}
        </Button>
      </div>
    </Dialog>
  );
};
