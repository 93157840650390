import { WhatsappAgentLogModel } from "@jugl-web/rest-api/integrations/models";
import { WhatsappAgentLogStatus } from "@jugl-web/rest-api/integrations/types";
import { cx, useTranslations } from "@jugl-web/utils";
import React, { useMemo } from "react";

export const AccountLogStatusPill: React.FC<{
  status: WhatsappAgentLogModel["status"];
}> = ({ status }) => {
  const { t } = useTranslations();
  const { backgroundClassName, labelClassName, label } = useMemo(() => {
    switch (status) {
      case WhatsappAgentLogStatus.sent:
        return {
          label: t({
            id: "whatsapp-account-page.sent",
            defaultMessage: "Sent",
          }),
          backgroundClassName: "bg-secondary-50",
          labelClassName: "text-secondary-700",
        };
      case WhatsappAgentLogStatus.payment_error:
        return {
          label: t({
            id: "whatsapp-account-page.payment-error",
            defaultMessage: "Payment error",
          }),
          backgroundClassName: "bg-tertiary-50",
          labelClassName: "text-tertiary-700",
        };
      default:
        return {
          label: t({
            id: "whatsapp-account-page.failed",
            defaultMessage: "Failed",
          }),
          backgroundClassName: "bg-tertiary-50",
          labelClassName: "text-tertiary-700",
        };
    }
  }, [status, t]);

  return (
    <div className={cx("w-max rounded-lg py-1 px-3", backgroundClassName)}>
      <span className={cx("leading-[150%]", labelClassName)}>{label}</span>
    </div>
  );
};
