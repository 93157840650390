import { Transition } from "@headlessui/react";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { assert, useTranslations } from "@jugl-web/utils";
import animationData from "@jugl-web/utils/animations/loader-light.json";
import { CSSProperties, forwardRef, useCallback } from "react";
import Lottie from "react-lottie";
import { ReactComponent as CallsUpdatesIcon } from "./assets/calls-updates.svg";
import { ReactComponent as ChatsUpdatesIcon } from "./assets/chats-updates.svg";
import { ReactComponent as CustomersUpdatesIcon } from "./assets/customers-updates.svg";
import { ReactComponent as DriveUpdatesIcon } from "./assets/drive-updates.svg";
import { ReactComponent as PeopleUpdatesIcon } from "./assets/people-updates.svg";
import { ReactComponent as TasksUpdatesIcon } from "./assets/tasks-updates.svg";
import { ReactComponent as TooltipArrowIcon } from "./assets/tooltip-arrow.svg";

export interface EntityUpdatesTooltipProps {
  isVisible: boolean;
  entityId: string;
  style?: CSSProperties;
}

export const EntityUpdatesTooltip = forwardRef<
  HTMLDivElement,
  EntityUpdatesTooltipProps
>(({ isVisible, entityId, style }, ref) => {
  const { entitiesApi } = useRestApiProvider();

  const {
    data: moduleUnreadIndicators,
    isLoading,
    isError,
  } = entitiesApi.useGetModuleUnreadIndicatorsQuery(
    { entityId },
    { skip: !isVisible, refetchOnMountOrArgChange: true }
  );

  const hasNoUpdates = useCallback(() => {
    assert(!!moduleUnreadIndicators);

    return Object.values(moduleUnreadIndicators).every((count) => count === 0);
  }, [moduleUnreadIndicators]);

  const { t } = useTranslations();

  return (
    <Transition
      as="div"
      ref={ref}
      className="bg-dark/80 z-20 ml-4 min-w-max rounded-lg py-3 px-4"
      show={isVisible}
      enter="transition"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      style={style}
    >
      <TooltipArrowIcon className="absolute left-0 top-1/2 -translate-y-1/2 -translate-x-full" />
      {isLoading ? (
        <div className="flex items-center gap-2.5">
          <Lottie options={{ animationData }} height={24} width={24} />
          <span className="text-sm leading-3 text-white">
            {t({
              id: "org-select-page.updates-indicators-loading",
              defaultMessage: "Loading updates...",
            })}
          </span>
        </div>
      ) : isError || !moduleUnreadIndicators ? (
        <span className="text-sm leading-3 text-white">
          {t({
            id: "org-select-page.updates-indicators-fetching-error",
            defaultMessage: "Couldn't get latest updates",
          })}
        </span>
      ) : hasNoUpdates() ? (
        <div className="flex items-center gap-2.5">
          <span className="text-sm leading-3 text-white">
            {t({
              id: "org-select-page.no-updates",
              defaultMessage: "No updates 👌",
            })}
          </span>
        </div>
      ) : (
        <div className="flex flex-col gap-2.5">
          {moduleUnreadIndicators.task.my > 0 && (
            <div className="flex items-center gap-3">
              <TasksUpdatesIcon />
              <span className="text-sm font-normal leading-3 text-white">
                {t(
                  {
                    id: "org-select-page.tasks-updates-indicator",
                    defaultMessage:
                      "{count} Tasks {count, plural, one {update} other {updates}}",
                  },
                  { count: moduleUnreadIndicators.task.my }
                )}
              </span>
            </div>
          )}
          {moduleUnreadIndicators.drive > 0 && (
            <div className="flex items-center gap-3">
              <DriveUpdatesIcon />
              <span className="text-sm font-normal leading-3 text-white">
                {t(
                  {
                    id: "org-select-page.drive-updates-indicator",
                    defaultMessage:
                      "{count} Drive {count, plural, one {update} other {updates}}",
                  },
                  { count: moduleUnreadIndicators.drive }
                )}
              </span>
            </div>
          )}
          {moduleUnreadIndicators.chat > 0 && (
            <div className="flex items-center gap-3">
              <ChatsUpdatesIcon />
              <span className="text-sm font-normal leading-3 text-white">
                {t(
                  {
                    id: "org-select-page.chats-updates-indicator",
                    defaultMessage:
                      "{count} unread {count, plural, one {message} other {messages}}",
                  },
                  { count: moduleUnreadIndicators.chat }
                )}
              </span>
            </div>
          )}
          {moduleUnreadIndicators.call > 0 && (
            <div className="flex items-center gap-3">
              <CallsUpdatesIcon />
              <span className="text-sm font-normal leading-3 text-white">
                {t(
                  {
                    id: "org-select-page.calls-updates-indicator",
                    defaultMessage:
                      "{count} missed {count, plural, one {call} other {calls}}",
                  },
                  { count: moduleUnreadIndicators.call }
                )}
              </span>
            </div>
          )}
          {moduleUnreadIndicators.people > 0 && (
            <div className="flex items-center gap-3">
              <PeopleUpdatesIcon />
              <span className="text-sm font-normal leading-3 text-white">
                {t(
                  {
                    id: "org-select-page.people-updates-indicator",
                    defaultMessage:
                      "{count} People {count, plural, one {update} other {updates}}",
                  },
                  { count: moduleUnreadIndicators.people }
                )}
              </span>
            </div>
          )}
          {moduleUnreadIndicators.customer > 0 && (
            <div className="flex items-center gap-3">
              <CustomersUpdatesIcon />
              <span className="text-sm font-normal leading-3 text-white">
                {t(
                  {
                    id: "org-select-page.customers-updates-indicator",
                    defaultMessage:
                      "{count} Clients {count, plural, one {update} other {updates}}",
                  },
                  { count: moduleUnreadIndicators.customer }
                )}
              </span>
            </div>
          )}
        </div>
      )}
    </Transition>
  );
});
