import { useToast, useTranslations } from "@jugl-web/utils";
import React, { useEffect, useState } from "react";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import Lottie from "react-lottie";
import { DataLoadingWrapper, EmptyListContent } from "@jugl-web/ui-components";
import { environment } from "@web-src/environments/environment";
import { ConnectedAccountTile } from "./components/ConnectedAccountTile";
import { WhatsappTabsLayout } from "../../components/WhatsappTabsLayout";
import whatsappAnimation from "./assets/whatsapp-animation.json";
import { AccountLogsTable } from "./components/AccountLogsTable";
import { DisconnectedAccountTile } from "./components/DisconnectedAccountTile";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const FB: any;

export const WhatsappAccountPage: React.FC = () => {
  const { integrationsApi } = useRestApiProvider();
  const [createWhatsappAgentAccount, { isLoading: isCreatingAccount }] =
    integrationsApi.useConnectWhatsappAgentAccountMutation();
  const { entityId } = useEntitySelectedProvider();
  const {
    data: whatsappAgent,
    isLoading,
    isFetching,
  } = integrationsApi.useWhatsappAgentAccountQuery({
    entityId,
  });
  const [loginInfo, setLoginInfo] = useState<{
    wabaId?: string;
    phoneNumberId?: string;
    code?: string;
  }>({});
  const [isLoginInProgress, setIsLoginInProgress] = useState(false);
  const { toast } = useToast({ variant: "web" });
  const { t } = useTranslations();

  useEffect(() => {
    if (loginInfo.code && loginInfo.phoneNumberId && loginInfo.wabaId) {
      createWhatsappAgentAccount({
        entityId,
        data: {
          code: loginInfo.code,
          phone_number_id: loginInfo.phoneNumberId,
          waba_id: loginInfo.wabaId,
        },
      });
      setLoginInfo({});
    }
  }, [loginInfo, createWhatsappAgentAccount, entityId]);

  const handleConnectWhatsappAccount = () => {
    const originalPathname = window.location.pathname;
    setIsLoginInProgress(true);
    window.history.replaceState({}, "", "/");
    FB.login(
      ({
        authResponse,
        status,
      }: {
        authResponse?: { code: string };
        status: "connected";
      }) => {
        setIsLoginInProgress(false);
        if (status === "connected" && authResponse?.code) {
          setLoginInfo((prev) => ({ ...prev, code: authResponse.code }));
        }
      },
      {
        config_id: environment.facebookConfigId,
        response_type: "code",
        override_default_response_type: true,
        display: "popup",
        extras: {
          setup: {},
          featureType: "",
          sessionInfoVersion: "2",
        },
      }
    );
    window.history.replaceState({}, "", originalPathname);
  };

  useEffect(() => {
    const eventCB = (event: MessageEvent) => {
      if (
        event.origin !== "https://www.facebook.com" &&
        event.origin !== "https://web.facebook.com"
      ) {
        return;
      }
      try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const data = JSON.parse(event.data) as any;
        if (data.type === "WA_EMBEDDED_SIGNUP") {
          // if user finishes the Embedded Signup flow
          if (data.event === "FINISH") {
            const { phone_number_id: phoneNumberId, waba_id: wabaId } =
              data.data;
            setLoginInfo((prev) => ({ ...prev, phoneNumberId, wabaId }));
            // if user cancels the Embedded Signup flow
          } else if (data.event === "CANCEL") {
            setIsLoginInProgress(false);
          } else if (data.event === "ERROR") {
            toast(
              t({
                id: "common.something-went-wrong",
                defaultMessage: "Something went wrong",
              }),
              { variant: "error" }
            );
            setIsLoginInProgress(false);
          }
        }
      } catch {
        // do nothing
      }
    };
    window.addEventListener("message", eventCB);
    return () => {
      window.removeEventListener("message", eventCB);
    };
  }, [t, toast]);

  return (
    <WhatsappTabsLayout selectedTab="account" areTabsHidden={!whatsappAgent}>
      <DataLoadingWrapper isLoading={isLoading || isFetching} />
      {whatsappAgent ? (
        <>
          <div className="px-10 py-12">
            {whatsappAgent.phone_number.status === "CONNECTED" ? (
              <ConnectedAccountTile />
            ) : (
              <DisconnectedAccountTile />
            )}
          </div>
          <AccountLogsTable />
        </>
      ) : (
        <EmptyListContent
          type="custom"
          className="w-[450px]"
          customImg={
            <Lottie
              options={{ animationData: whatsappAnimation }}
              height={200}
              width={200}
            />
          }
          customSubtitle={t({
            id: "whatsapp-account-page.create-messages-templates-description",
            defaultMessage:
              "Create messages templates that will be sent to your Clients through WhatsApp as Order Updates. Connect your business WhatsApp account to start the Automation 🚀",
          })}
          customTitle={t({
            id: "whatsapp-account-page.whatsapp-integration-order-updates",
            defaultMessage: "WhatsApp Integration for Order updates ✨",
          })}
          customButton={{
            text: t({
              id: "whatsapp-account-page.connect-whatsapp-account",
              defaultMessage: "Connect WhatsApp Account",
            }),
            onClick: handleConnectWhatsappAccount,
            isDisabled: isLoginInProgress || isCreatingAccount,
            className: "h-10",
            fullWidth: false,
          }}
        />
      )}
    </WhatsappTabsLayout>
  );
};
