import { useTheme } from "@web-src/themes/CustomThemeProvider";

export const Divider = () => {
  const { tcx } = useTheme();
  return (
    <div
      className={tcx("bg-primary-600 h-px w-full", {
        light: "bg-[#F2F2F2]",
        dark: "bg-[#4D4D4D]",
        blue: "bg-primary-600",
      })}
    />
  );
};
