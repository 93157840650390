import {
  useTaskListError,
  UseTaskListErrorOptions,
} from "@jugl-web/domain-resources/tasks/hooks/useTaskListError";
import { Button } from "@jugl-web/ui-components/cross-platform/Button";
import { FC } from "react";
import Lottie from "react-lottie";
import somethingWentWrongAnimation from "./assets/something-went-wrong-animation.json";

export const TaskListErrorScreen: FC<UseTaskListErrorOptions> = (props) => {
  const { title, message, buttonLabel, onButtonClick } =
    useTaskListError(props);

  return (
    <div className="flex h-full flex-col items-center justify-center">
      <Lottie
        options={{ animationData: somethingWentWrongAnimation }}
        height={180}
        width={300}
      />
      <h2 className="font-secondary text-dark mt-10 mb-[18px] text-[32px] font-medium leading-8 -tracking-[0.32px]">
        {title}
      </h2>
      <p className="text-dark-800 mt-0 mb-12 leading-4 -tracking-[0.16px]">
        {message}
      </p>
      <Button
        variant="contained"
        color="primary"
        className="h-10 w-[300px]"
        onClick={onButtonClick}
      >
        {buttonLabel}
      </Button>
    </div>
  );
};
