import { PlainButton } from "@jugl-web/ui-components";
import { cx } from "@jugl-web/utils";
import { forwardRef, ReactNode } from "react";

interface ToolbarButtonProps {
  children: ReactNode;
  isActive?: boolean;
  className?: string;
  onClick?: () => void;
}

export const ToolbarButton = forwardRef<HTMLButtonElement, ToolbarButtonProps>(
  ({ children, isActive, className, onClick }, ref) => (
    <PlainButton
      ref={ref}
      className={cx(
        "jugl__focusable-outline font-secondary text-dark flex h-8 w-8 shrink-0 items-center justify-center rounded text-sm font-medium transition-colors",
        isActive ? "bg-primary-50" : "hover:bg-grey-100",
        className
      )}
      onClick={onClick}
    >
      {children}
    </PlainButton>
  )
);
