import { Button } from "@jugl-web/ui-components/cross-platform/Button";
import {
  MobileDrawer,
  MobileDrawerProps,
} from "@jugl-web/ui-components/mobile/MobileDrawer";
import { useTranslations } from "@jugl-web/utils";
import { FC } from "react";
import Lottie from "react-lottie";
import dangerAnimation from "./assets/danger-animation.json";

interface WarningMobileDrawerProps
  extends Pick<MobileDrawerProps, "isOpen" | "onClose"> {
  title: string;
  message: string;
}

export const WarningMobileDrawer: FC<WarningMobileDrawerProps> = ({
  isOpen,
  title,
  message,
  onClose,
}) => {
  const { t } = useTranslations();

  return (
    <MobileDrawer
      header={{ title: "", hasCloseButton: false }}
      isOpen={isOpen}
      onClose={onClose}
    >
      <MobileDrawer.Content className="flex flex-col items-center px-9 pb-6 text-center">
        <Lottie
          options={{ animationData: dangerAnimation }}
          height={70}
          width={70}
        />
        <h2 className="font-secondary text-dark mt-2 mb-3 text-base font-medium leading-4">
          {title}
        </h2>
        <p className="text-dark-700 mt-0 mb-8 text-sm leading-[21px]">
          {message}
        </p>
        <Button
          variant="contained"
          color="primary"
          className="mb-8 h-10 max-w-[300px]"
          fullWidth
          onClick={onClose}
        >
          {t({
            id: "common.okay",
            defaultMessage: "Okay",
          })}
        </Button>
      </MobileDrawer.Content>
    </MobileDrawer>
  );
};
