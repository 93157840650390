import { useTaskBoards } from "@jugl-web/domain-resources/tasks/hooks/useTaskBoards";
import { EntityParticipantRole } from "@jugl-web/rest-api";
import { SubscriptionPlanModuleId } from "@jugl-web/rest-api/entities/models/common-types/SubscriptionPlanModuleId";
import { BoardAvatar } from "@jugl-web/ui-components";
import { useTranslations } from "@jugl-web/utils";
import {
  getStorageItemWithFallback,
  saveItemToStorage,
  SIDEBAR_EXPANDED_KEY,
} from "@jugl-web/utils/storage";
import { useUnreadIndicators } from "@web-src/components/UnreadIndicatorsProvider";
import { isAppInDevMode } from "@web-src/modules/common/utils/isAppInDevMode";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { useFCM } from "@web-src/modules/notifications/providers/FCMProvider";
import { useCallback, useMemo, useState } from "react";
import { useTheme } from "@web-src/themes/CustomThemeProvider";
import { TabRoute } from "../../../types";
import { ReactComponent as IntegrationsIcon } from "../assets/integrations.svg";
import { SidebarItem } from "../types";

import { ReactComponent as CallsIconLight } from "../assets/calls-light.svg";
import { ReactComponent as CallsIconDark } from "../assets/calls-dark.svg";
import { ReactComponent as CallsIconBlue } from "../assets/calls-blue.svg";

import { ReactComponent as ChatsIconLight } from "../assets/chats-light.svg";
import { ReactComponent as ChatsIconDark } from "../assets/chats-dark.svg";
import { ReactComponent as ChatsIconBlue } from "../assets/chats-blue.svg";
import { ReactComponent as PeopleIconLight } from "../assets/people-light.svg";
import { ReactComponent as PeopleIconDark } from "../assets/people-dark.svg";
import { ReactComponent as PeopleIconBlue } from "../assets/people-blue.svg";

import { ReactComponent as ClientsIconLight } from "../assets/clients-light.svg";
import { ReactComponent as ClientsIconDark } from "../assets/clients-dark.svg";
import { ReactComponent as ClientsIconBlue } from "../assets/clients-blue.svg";

import { ReactComponent as DriveIconLight } from "../assets/drive-light.svg";
import { ReactComponent as DriveIconDark } from "../assets/drive-dark.svg";
import { ReactComponent as DriveIconBlue } from "../assets/drive-blue.svg";

import { ReactComponent as OrderFormsIconLight } from "../assets/order-forms-light.svg";
import { ReactComponent as OrderFormsIconDark } from "../assets/order-forms-dark.svg";
import { ReactComponent as OrderFormsIconBlue } from "../assets/order-forms-blue.svg";

import { ReactComponent as ReportsIconLight } from "../assets/reports-light.svg";
import { ReactComponent as ReportsIconDark } from "../assets/reports-dark.svg";
import { ReactComponent as ReportsIconBlue } from "../assets/reports-blue.svg";

import { ReactComponent as MoreIconLight } from "../assets/more-light.svg";
import { ReactComponent as MoreIconDark } from "../assets/more-dark.svg";
import { ReactComponent as MoreIconBlue } from "../assets/more-blue.svg";

import { ReactComponent as ScheduleIconLight } from "../assets/schedule-light.svg";
import { ReactComponent as ScheduleIconDark } from "../assets/schedule-dark.svg";
import { ReactComponent as ScheduleIconBlue } from "../assets/schedule-blue.svg";

import { ReactComponent as WorkspaceIconLight } from "../assets/workspace-light.svg";
import { ReactComponent as WorkspaceIconDark } from "../assets/workspace-dark.svg";
import { ReactComponent as WorkspaceIconBlue } from "../assets/workspace-blue.svg";

export const useHomeSidebar = () => {
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(() =>
    getStorageItemWithFallback(SIDEBAR_EXPANDED_KEY, true)
  );

  const { entity } = useEntitySelectedProvider();
  const { customTheme } = useTheme();

  const { boards } = useTaskBoards({ entityId: entity.id });
  const { moduleUnreadIndicators } = useUnreadIndicators();

  const { logEvent } = useFCM();
  const { generateUrl } = useNavigation();
  const { t } = useTranslations();

  // TODO: some better approach will be
  const activeTab = window.location.pathname.split("/")[2] || "";
  const activePath = window.location.pathname.split("/").slice(2).join("/");

  const updateIsSidebarExpanded = useCallback((isExpanded: boolean) => {
    setIsSidebarExpanded(isExpanded);
    saveItemToStorage(SIDEBAR_EXPANDED_KEY, isExpanded);
  }, []);

  const sidebarItems = useMemo<SidebarItem[]>(() => {
    const chatItem: SidebarItem = {
      type: "link",
      SvgIcon: {
        light: ChatsIconLight,
        dark: ChatsIconDark,
        blue: ChatsIconBlue,
      }[customTheme],
      title: t({
        id: "home-sidebar-component.chats",
        defaultMessage: "Chats",
      }),
      active: activeTab === "chats",
      unreadCount: moduleUnreadIndicators?.chat,
      href: `/${entity.id}/chats`,
      onClick: () => {
        logEvent("navigation_chat");
      },
    };
    const peopleItem: SidebarItem = {
      type: "link",
      SvgIcon: {
        light: PeopleIconLight,
        dark: PeopleIconDark,
        blue: PeopleIconBlue,
      }[customTheme],
      title: t({
        id: "home-sidebar-component.people",
        defaultMessage: "People",
      }),
      active: activeTab === "people",
      unreadCount: moduleUnreadIndicators?.people,
      href: `/${entity?.id}/people`,
    };
    const driveItem: SidebarItem = {
      type: "link",
      SvgIcon: {
        light: DriveIconLight,
        dark: DriveIconDark,
        blue: DriveIconBlue,
      }[customTheme],
      title: t({
        id: "home-sidebar-component.drive",
        defaultMessage: "Drive",
      }),
      active: activeTab === "drive",
      unreadCount: moduleUnreadIndicators?.drive,
      href: `/${entity.id}/drive`,
    };
    const callsItem: SidebarItem = {
      type: "link",
      SvgIcon: {
        light: CallsIconLight,
        dark: CallsIconDark,
        blue: CallsIconBlue,
      }[customTheme],
      title: t({
        id: "home-sidebar-component.calls",
        defaultMessage: "Calls",
      }),
      active: activeTab === "calls",
      unreadCount: moduleUnreadIndicators?.call,
      href: `/${entity.id}/calls`,
    };
    const travelLogItem: SidebarItem = {
      type: "link",
      SvgIcon: {
        light: ScheduleIconLight,
        dark: ScheduleIconDark,
        blue: ScheduleIconBlue,
      }[customTheme],
      title: t({
        id: "home-sidebar-component.travel-log",
        defaultMessage: "Travel Log",
      }),
      active: activeTab === "travel-log",
      requiredRole: [EntityParticipantRole.admin],
      href: `/${entity.id}/travel-log`,
      onClick: () => {
        logEvent("navigation_travel_log");
      },
    };
    const clientsItem: SidebarItem = {
      type: "link",
      SvgIcon: {
        light: ClientsIconLight,
        dark: ClientsIconDark,
        blue: ClientsIconBlue,
      }[customTheme],
      title: t({
        id: "home-sidebar-component.Customers",
        defaultMessage: "Customers",
      }),
      active: activeTab === "customers",
      unreadCount: moduleUnreadIndicators?.customer,
      requiredRole: [EntityParticipantRole.admin],
      href: `/${entity.id}/customers`,
      subscriptionModuleRequired: SubscriptionPlanModuleId.clients,
    };
    const integrationsItem: SidebarItem = {
      type: "link",
      SvgIcon: IntegrationsIcon,
      title: t({
        id: "home-sidebar-component.integrations",
        defaultMessage: "Integrations",
      }),
      active: activeTab === "integrations",
      requiredRole: [EntityParticipantRole.admin],
      href: generateUrl("integrationsWhatsappAccount"),
    };
    const orderFormsItem: SidebarItem = {
      type: "link",
      SvgIcon: {
        light: OrderFormsIconLight,
        dark: OrderFormsIconDark,
        blue: OrderFormsIconBlue,
      }[customTheme],
      title: t({
        id: "home-sidebar-component.order-forms",
        defaultMessage: "Order Forms",
      }),
      active: activeTab === "orders",
      requiredRole: [EntityParticipantRole.admin],
      href: `/${entity.id}/orders/forms`,
    };
    const workspaceItem: SidebarItem = {
      type: "link",
      SvgIcon: {
        light: WorkspaceIconLight,
        dark: WorkspaceIconDark,
        blue: WorkspaceIconBlue,
      }[customTheme],
      title: t({
        id: "home-sidebar-component.workspace",
        defaultMessage: "Workspace",
      }),
      active: activeTab.includes("workspace"),
      requiredRole: [EntityParticipantRole.admin],
      href: `/${entity.id}/workspace/settings`,
    };
    const reportsItem: SidebarItem = {
      type: "accordion",
      SvgIcon: {
        light: ReportsIconLight,
        dark: ReportsIconDark,
        blue: ReportsIconBlue,
      }[customTheme],
      title: t({
        id: "home-sidebar-component.reports",
        defaultMessage: "Reports",
      }),
      items: [
        {
          type: "link",
          title: t({
            id: "home-sidebar-component.tasks",
            defaultMessage: "Tasks",
          }),
          active: activePath === "reports/tasks",
          href: `/${entity.id}/reports/tasks`,
          subscriptionModuleRequired: SubscriptionPlanModuleId.reports,
        },
        {
          type: "link",
          title: t({
            id: "home-sidebar-component.time-clock",
            defaultMessage: "Time Clock",
          }),
          active: activePath === "reports/timeclock",
          href: `/${entity.id}/reports/timeclock`,
          subscriptionModuleRequired: SubscriptionPlanModuleId.reports,
        },
        {
          type: "link",
          title: t({
            id: "home-sidebar-component.scheduled-reports",
            defaultMessage: "Scheduled Reports",
          }),
          active:
            activeTab === TabRoute.scheduledReports ||
            activeTab === TabRoute.scheduledReportsForm,
          requiredRole: [EntityParticipantRole.admin],
          href: `/${entity.id}/scheduled-reports`,
          subscriptionModuleRequired: SubscriptionPlanModuleId.reports,
        },
      ],
    };
    const moreItem: SidebarItem = {
      type: "accordion",
      SvgIcon: {
        light: MoreIconLight,
        dark: MoreIconDark,
        blue: MoreIconBlue,
      }[customTheme],
      title: t({
        id: "home-sidebar-component.more",
        defaultMessage: "More",
      }),
      items: [clientsItem, travelLogItem, reportsItem, workspaceItem],
    };

    const debugItem: SidebarItem = {
      type: "accordion",
      customIcon: <>⚙️</>,
      title: "Debug",
      items: [
        {
          type: "link",
          title: "User settings",
          active: activePath === "debug/user-settings",
          href: `/${entity.id}/debug/user-settings`,
        },
        {
          type: "link",
          title: "Subscription",
          active: activePath === "debug/subscription",
          href: `/${entity.id}/debug/subscription`,
        },
        {
          type: "link",
          title: "Mobile app",
          active: activePath === "debug/mobile-app",
          href: `/${entity.id}/debug/mobile-app`,
        },
      ],
    };

    const devModeItems = isAppInDevMode() ? [debugItem] : [];

    if (entity.role === EntityParticipantRole.employee) {
      return [chatItem, peopleItem, callsItem, driveItem, ...devModeItems];
    }

    return [
      chatItem,
      peopleItem,
      orderFormsItem,
      driveItem,
      integrationsItem,
      callsItem,
      moreItem,
      ...devModeItems,
    ];
  }, [
    customTheme,
    t,
    activeTab,
    moduleUnreadIndicators?.chat,
    moduleUnreadIndicators?.people,
    moduleUnreadIndicators?.drive,
    moduleUnreadIndicators?.call,
    moduleUnreadIndicators?.customer,
    entity.id,
    activePath,
    entity.role,
    generateUrl,
    logEvent,
  ]);

  const taskBoardSidebarItems = useMemo<SidebarItem[]>(
    () => [
      {
        type: "link",
        href: generateUrl("tasksTasks", { boardId: "my" }),
        title: t({
          id: "home-sidebar-component.my-tasks",
          defaultMessage: "My Tasks",
        }),
        active: activeTab === TabRoute.tasks && activePath === "tasks/board/my",
        customIcon: <BoardAvatar size="md" name="M" color="#AB59E4" />,
        unreadCount: moduleUnreadIndicators?.task.my,
      },
      {
        type: "link",
        href: generateUrl("tasksTasks", { boardId: "team" }),
        title: t({
          id: "home-sidebar-component.team-tasks",
          defaultMessage: "Team Tasks",
        }),
        active:
          activeTab === TabRoute.tasks && activePath === "tasks/board/team",
        customIcon: <BoardAvatar size="md" name="T" color="#F36021" />,
      },
      ...boards.map((board) => ({
        type: "link" as const,
        href: generateUrl("tasksTasks", { boardId: board.id }),
        title: board.name,
        active:
          activeTab === TabRoute.tasks &&
          activePath === `tasks/board/${board.id}`,
        customIcon: (
          <BoardAvatar size="md" name={board.name} color={board.color} />
        ),
        unreadCount: moduleUnreadIndicators?.task[board.id],
      })),
    ],
    [
      t,
      activeTab,
      activePath,
      moduleUnreadIndicators?.task,
      boards,
      generateUrl,
    ]
  );

  return {
    sidebarItems,
    taskBoardSidebarItems,
    isSidebarExpanded,
    setIsSidebarExpanded,
    updateIsSidebarExpanded,
  };
};
