import {
  AuthPagesNavigationParams,
  authPagesNavigationConfig,
} from "../auth/pages/navigation-config";
import {
  CommonPagesNavigationParams,
  commonPagesNavigationConfig,
} from "../common/pages/navigation-config";
import {
  ConferencePagesNavigationParams,
  conferencePagesNavigationConfig,
} from "../conference/pages/navigation-config";
import {
  CustomersPagesNavigationParams,
  customersPagesNavigationConfig,
} from "../customers/pages/navigation-config";
import {
  DrivePagesNavigationParams,
  drivePagesNavigationConfig,
} from "../drive/pages/navigation-config";
import {
  ReportsPagesNavigationParams,
  reportsPagesNavigationConfig,
} from "../reports/pages/navigation-config";
import {
  TravelLogPagesNavigationParams,
  travelLogPagesNavigationConfig,
} from "../travel-log/pages/navigation-config";
import {
  SettingsPagesNavigationParams,
  settingsPagesNavigationConfig,
} from "../settings/pages/navigation-config";
import {
  WorkspacePagesNavigationParams,
  workspacePagesNavigationConfig,
} from "../workspace/pages/navigation-config";
import {
  EntityPagesNavigationParams,
  entityPagesNavigationConfig,
} from "../entities/pages/navigation-config";
import {
  PeoplePagesNavigationParams,
  peoplePagesNavigationConfig,
} from "../people/pages/navigation-config";
import {
  TasksPagesNavigationParams,
  tasksPagesNavigationConfig,
} from "../tasks/pages/navigation-config";
import {
  ChatsPagesNavigationParams,
  chatsPagesNavigationConfig,
} from "../chats/pages/navigation-config";
import {
  CallsPagesNavigationParams,
  callsPagesNavigationConfig,
} from "../calls/pages/navigation-config";
import {
  ordersPagesNavigationConfig,
  OrdersPagesNavigationParams,
} from "../orders/pages/navigation-config";
import {
  IntegrationsPagesNavigationParams,
  integrationsPagesNavigationConfig,
} from "../integrations/pages/navigation-config";

export type RootPagesNavigationParams = PeoplePagesNavigationParams &
  AuthPagesNavigationParams &
  DrivePagesNavigationParams &
  CommonPagesNavigationParams &
  ConferencePagesNavigationParams &
  CustomersPagesNavigationParams &
  ReportsPagesNavigationParams &
  TravelLogPagesNavigationParams &
  SettingsPagesNavigationParams &
  WorkspacePagesNavigationParams &
  EntityPagesNavigationParams &
  TasksPagesNavigationParams &
  ChatsPagesNavigationParams &
  CallsPagesNavigationParams &
  OrdersPagesNavigationParams &
  IntegrationsPagesNavigationParams;

export const rootNavigationPageConfig = {
  ...peoplePagesNavigationConfig,
  ...authPagesNavigationConfig,
  ...drivePagesNavigationConfig,
  ...commonPagesNavigationConfig,
  ...conferencePagesNavigationConfig,
  ...customersPagesNavigationConfig,
  ...reportsPagesNavigationConfig,
  ...travelLogPagesNavigationConfig,
  ...settingsPagesNavigationConfig,
  ...workspacePagesNavigationConfig,
  ...entityPagesNavigationConfig,
  ...tasksPagesNavigationConfig,
  ...chatsPagesNavigationConfig,
  ...callsPagesNavigationConfig,
  ...ordersPagesNavigationConfig,
  ...integrationsPagesNavigationConfig,
};
