import { useTranslations } from "@jugl-web/utils";

export const useOrdinalWords = () => {
  const { t } = useTranslations();

  const ordinalWords = [
    t({ id: "ordinal.zeroth", defaultMessage: "zeroth" }),
    t({ id: "ordinal.first", defaultMessage: "first" }),
    t({ id: "ordinal.second", defaultMessage: "second" }),
    t({ id: "ordinal.third", defaultMessage: "third" }),
    t({ id: "ordinal.fourth", defaultMessage: "fourth" }),
    t({ id: "ordinal.fifth", defaultMessage: "fifth" }),
    t({ id: "ordinal.sixth", defaultMessage: "sixth" }),
    t({ id: "ordinal.seventh", defaultMessage: "seventh" }),
    t({ id: "ordinal.eighth", defaultMessage: "eighth" }),
    t({ id: "ordinal.ninth", defaultMessage: "ninth" }),
    t({ id: "ordinal.tenth", defaultMessage: "tenth" }),
    t({ id: "ordinal.eleventh", defaultMessage: "eleventh" }),
    t({ id: "ordinal.twelfth", defaultMessage: "twelfth" }),
    t({ id: "ordinal.thirteenth", defaultMessage: "thirteenth" }),
    t({ id: "ordinal.fourteenth", defaultMessage: "fourteenth" }),
    t({ id: "ordinal.fifteenth", defaultMessage: "fifteenth" }),
    t({ id: "ordinal.sixteenth", defaultMessage: "sixteenth" }),
    t({ id: "ordinal.seventeenth", defaultMessage: "seventeenth" }),
    t({ id: "ordinal.eighteenth", defaultMessage: "eighteenth" }),
    t({ id: "ordinal.nineteenth", defaultMessage: "nineteenth" }),
    t({ id: "ordinal.twentieth", defaultMessage: "twentieth" }),
    t({ id: "ordinal.twenty-first", defaultMessage: "twenty-first" }),
    t({ id: "ordinal.twenty-second", defaultMessage: "twenty-second" }),
    t({ id: "ordinal.twenty-third", defaultMessage: "twenty-third" }),
    t({ id: "ordinal.twenty-fourth", defaultMessage: "twenty-fourth" }),
    t({ id: "ordinal.twenty-fifth", defaultMessage: "twenty-fifth" }),
    t({ id: "ordinal.twenty-sixth", defaultMessage: "twenty-sixth" }),
    t({ id: "ordinal.twenty-seventh", defaultMessage: "twenty-seventh" }),
    t({ id: "ordinal.twenty-eighth", defaultMessage: "twenty-eighth" }),
    t({ id: "ordinal.twenty-ninth", defaultMessage: "twenty-ninth" }),
    t({ id: "ordinal.thirtieth", defaultMessage: "thirtieth" }),
    t({ id: "ordinal.thirty-first", defaultMessage: "thirty-first" }),
  ];

  const getOrdinalWord = (n: number): string => {
    if (n < 0 || n >= ordinalWords.length) {
      throw new Error("Number out of range");
    }
    return ordinalWords[n];
  };

  return { getOrdinalWord };
};
