import { DetailedTask } from "@jugl-web/rest-api/tasks";
import { Tooltip } from "@jugl-web/ui-components/cross-platform/Tooltip";
import { TaskHoverableButton } from "@jugl-web/ui-components/cross-platform/tasks/TaskHoverableButton";
import { WarningMobileDrawer } from "@jugl-web/ui-components/mobile/WarningMobileDrawer";
import { cx, useAppVariant, useTranslations } from "@jugl-web/utils";
import { FC, useMemo, useState } from "react";
import { useTaskRoles } from "../../hooks/useTaskRoles";
import { ReactComponent as LockIcon } from "./assets/lock.svg";
import { ReactComponent as WarnIcon } from "./assets/warn.svg";

interface TaskInfoAccessButtonProps {
  task: DetailedTask;
  entityId: string;
  meId: string;
  className?: string;
}

type TaskInfoAccessLevel =
  | "view-only" // I am neither task owner/assignee nor collaborator
  | "assignee-only" // I am task owner/assignee but not collaborator
  | "collaborator-only" // I am collaborator but not owner/assignee
  | "full"; // I am task owner/assignee and collaborator

export const TaskInfoAccessButton: FC<TaskInfoAccessButtonProps> = ({
  task,
  entityId,
  meId,
  className,
}) => {
  const [isMobileDialogOpen, setIsMobileDialogOpen] = useState(false);

  const { isTaskCreator, isTaskAssignee, isBoardMember } = useTaskRoles({
    task,
    entityId,
    meId,
  });

  const { t } = useTranslations();
  const { isMobile } = useAppVariant();

  const accessLevel = useMemo<TaskInfoAccessLevel>(() => {
    if (!isTaskCreator && !isTaskAssignee && !isBoardMember) {
      return "view-only";
    }

    if (task.board_id && !isBoardMember && (isTaskAssignee || isTaskCreator)) {
      return "assignee-only";
    }

    if (task.board_id && isBoardMember && !isTaskAssignee && !isTaskCreator) {
      return "collaborator-only";
    }

    return "full";
  }, [isBoardMember, isTaskAssignee, isTaskCreator, task.board_id]);

  const title =
    accessLevel === "view-only"
      ? t({
          id: "tasks-page.view-only-access",
          defaultMessage: "View Only",
        })
      : t({
          id: "tasks-page.partial-access",
          defaultMessage: "Partial Access",
        });

  const message = useMemo(() => {
    switch (accessLevel) {
      case "view-only":
        return t({
          id: "tasks-page.view-only-access-description",
          defaultMessage:
            "Only Task Assignees and Board Members can edit task info 🙌",
        });

      case "assignee-only":
        return t({
          id: "tasks-page.assignee-only-access-description",
          defaultMessage:
            "This task belongs to a Board you are not a Member of. Some task info cannot be edited by you 🔒",
        });

      case "collaborator-only":
        return t({
          id: "tasks-page.collaborator-only-access-description",
          defaultMessage:
            "As Collaborator on this task, you have partial access to manage it. Only Task Assignees have permission to fully manage task details 🙌",
        });

      default:
        return "";
    }
  }, [accessLevel, t]);

  if (accessLevel === "full") {
    return null;
  }

  return (
    <>
      <Tooltip
        isDisabled={isMobile}
        delay="none"
        className="bg-dark w-[252px] text-center"
        renderTrigger={({ ref, props }) => (
          <TaskHoverableButton
            ref={ref}
            className={cx("cursor-help px-2", className)}
            onClick={() => {
              if (isMobile) {
                setIsMobileDialogOpen(true);
              }
            }}
            {...props}
          >
            <span>{title}</span>
            {accessLevel === "view-only" ? <WarnIcon /> : <LockIcon />}
          </TaskHoverableButton>
        )}
      >
        {message}
      </Tooltip>
      <WarningMobileDrawer
        isOpen={isMobileDialogOpen}
        onClose={() => setIsMobileDialogOpen(false)}
        title={title}
        message={message}
      />
    </>
  );
};
