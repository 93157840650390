import {
  Avatar,
  InteractiveContainer,
  Menu,
} from "@jugl-web/ui-components/cross-platform";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { useTranslations } from "@jugl-web/utils";
import { useIntercom } from "@web-src/components/IntercomProvider";
import { EntitiesListProvider } from "@web-src/features/entities/providers/EntitiesListProvider/EntitiesListProvider";
import { useTheme } from "@web-src/themes/CustomThemeProvider";
import { ReactComponent as LogoIcon } from "./assets/logo.svg";
import { useMe } from "../../hooks/useMe";

import { ReactComponent as SettingsIcon } from "./assets/settings.svg";
import { IconButton } from "./components/IconButton";
import { ReactComponent as HelpIcon } from "./assets/gethelp.svg";
import { ReactComponent as HelpCenterIcon } from "./assets/help-center.svg";
import { ReactComponent as SupportChatIcon } from "./assets/support-chat.svg";
import { SelectEntityPopover } from "./components/SelectEntityPopover/SelectEntityPopover";
import { EntitySubscriptionInfoItem } from "./components/EntitySubscriptionInfoItem";
import { ThemePicker } from "./components/ThemePicker";

export const MainNavBar = () => {
  const { me } = useMe();
  const { navigateToPage } = useNavigation();
  const { t } = useTranslations();
  const { toggle: toggleIntercomWidget } = useIntercom();
  const locationRoute = window.location.pathname.split("/")[2] || "";
  const locationTab = window.location.pathname.split("/")[3] || "";
  const { tcx } = useTheme();

  return (
    <EntitiesListProvider>
      <div
        className={tcx("h-[48px]", {
          light: "bg-primary-600",
          dark: "bg-[#4D4D4D]",
          blue: "bg-primary-800",
        })}
      >
        <div className="flex h-[48px] w-full items-center justify-between pr-6">
          <div className="flex h-full items-center justify-start">
            <SelectEntityPopover />
          </div>
          <span className="flex gap-4">
            <EntitySubscriptionInfoItem />
            <ThemePicker />
            <Menu
              className="w-[180px]"
              placement="bottom-end"
              renderTrigger={({ Trigger, triggerRef }) => (
                <Trigger ref={triggerRef} as={InteractiveContainer}>
                  <IconButton
                    tooltipText={t({
                      id: "main-navbar-component.get-help-tooltip",
                      defaultMessage: "Get Help",
                    })}
                    content={<HelpIcon />}
                    className="h-8 w-8"
                  />
                </Trigger>
              )}
              sections={[
                [
                  {
                    id: "help-center",
                    label: t({
                      id: "main-navbar-component.help-center",
                      defaultMessage: "Help Center",
                    }),
                    onSelect: (_, close) => {
                      window.open("https://help.jugl.com", "_blank");
                      close();
                    },
                    icon: <HelpCenterIcon />,
                  },

                  {
                    id: "support-chat",
                    label: t({
                      id: "main-navbar-component.support-chat",
                      defaultMessage: "Support Chat",
                    }),
                    icon: <SupportChatIcon />,
                    onSelect: (_, close) => {
                      toggleIntercomWidget();
                      close();
                    },
                  },
                ],
              ]}
            />

            <IconButton
              tooltipText={t({
                id: "common.settings",
                defaultMessage: "Settings",
              })}
              onClick={() => navigateToPage("settingsSettings", undefined)}
              content={<SettingsIcon />}
              isActive={locationRoute === "settings" && !locationTab}
              className="h-8 w-8"
            />
            <IconButton
              tooltipText={t({
                id: "main-navbar-component.my-account",
                defaultMessage: "My Account",
              })}
              onClick={() => navigateToPage("settingsMainSettings")}
              className="flex h-8 items-center px-2"
              content={
                <div
                  className={tcx(
                    "flex items-center gap-1.5 truncate text-white"
                  )}
                >
                  <div className="font-secondary max-w-[200px] grow truncate text-sm font-medium">
                    <span className="align-center flex h-8 items-center justify-start">
                      {me?.profile?.first_name}
                    </span>
                  </div>
                  <div className="relative">
                    <Avatar
                      className="border border-solid border-white"
                      labelClassName="text-[11px]"
                      size="sm"
                      imageUrl={me?.profile?.img}
                      username={me?.display_name || ""}
                    />
                    <span className="bg-gradients-success absolute bottom-0 right-0 h-2 w-2 rounded-full border border-solid border-white" />
                  </div>
                </div>
              }
              isActive={
                locationRoute === "settings" &&
                (locationTab === "main-settings" ||
                  locationTab === "edit-profile")
              }
            />
            <div className="flex items-center pt-[5px]">
              <LogoIcon />
            </div>
          </span>
        </div>
      </div>
    </EntitiesListProvider>
  );
};
