import { cx, useTranslations } from "@jugl-web/utils";
import { Alert } from "@jugl-web/ui-components";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useState, Fragment, useMemo } from "react";
import { useForm } from "react-hook-form";
import Lottie from "react-lottie";
import { useExportTasks } from "@jugl-web/domain-resources/tasks/hooks/useExportTasks";
import { ExportTaskVariants } from "@jugl-web/rest-api/tasks/types";
import { startOfMonth, endOfMonth } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import { useTasksSource } from "@web-src/hooks/useTasksSource";
import { useMe } from "@web-src/features/app/hooks/useMe";
import { useTimeZone } from "@web-src/modules/settings/providers/TimeZoneProvider";
import { ReactComponent as ExcelIcon } from "./assets/excel.svg";
import { DataRangeField } from "./components/DataRangeField/DataRangeField";
import { FilterByField } from "./components/FilterByField";
import { AdditionalField } from "./components/AdditionalField/AdditionalField";
import { BoardsField } from "./components/BoardsField/BoardsField";
import { Status } from "./components/StatusField";
import { LabelField } from "./components/LabelField";
import { TeamMembers } from "./components/TeamMembersField/TeamMembersField";
import { TASKS_EXPORT_DIALOG_SECTIONS } from "./consts";
import loadingAnimation from "./assets/loadingAnimation.json";
import { InternalExportFormParams } from "./types";

type ExportTasksDialogProps = {
  isOpen: boolean;
  onClose: () => void;
};

const Divider = () => <div className="bg-grey-200 h-[1px] w-full" />;
export const ExportTasksDialog: React.FC<ExportTasksDialogProps> = ({
  isOpen,
  onClose,
}) => {
  const { t } = useTranslations();
  const { entity } = useEntitySelectedProvider();
  const { me } = useMe();
  const { generateExcel } = useExportTasks({
    entityId: entity.id,
    meId: me?.id || "",
  });
  const { tasksSource } = useTasksSource();
  const { timeZone } = useTimeZone();

  const variant: ExportTaskVariants = useMemo(() => {
    if (
      tasksSource.type === "boardTasks" &&
      (tasksSource.boardId === "my" || tasksSource.boardId === "team")
    ) {
      return tasksSource.boardId as ExportTaskVariants;
    }
    return "board";
  }, [tasksSource]);

  const defaultFromTo = useMemo(
    () =>
      `${zonedTimeToUtc(
        startOfMonth(new Date()),
        timeZone.name
      ).toISOString()},${zonedTimeToUtc(
        endOfMonth(new Date()),
        timeZone.name
      ).toISOString()}`,
    [timeZone.name]
  );

  const defaultValues: InternalExportFormParams = useMemo(
    () => ({
      view: variant,
      date_type: "due_at",
      no_due: true,
      fromTo: defaultFromTo,
      status: undefined,
      label: undefined,
      user_id: undefined,
      board: undefined,
    }),
    [defaultFromTo, variant]
  );

  const {
    handleSubmit,
    control,
    watch,
    reset: resetFormState,
  } = useForm<InternalExportFormParams>({
    defaultValues,
  });

  const formParams = watch();

  const [exportStatus, setExportStatus] = useState<
    "idle" | "nothingToExport" | "saving" | "done"
  >("idle");

  const sectionComponents = {
    dataRange: DataRangeField,
    teamMembers: TeamMembers,
    boards: BoardsField,
    status: Status,
    label: LabelField,
    filterBy: FilterByField,
    additional: AdditionalField,
  };

  const onSubmit = async () => {
    if (exportStatus === "saving" || !formParams) return;
    setExportStatus("saving");

    const fromTo = formParams.fromTo ? formParams.fromTo.split(",") : undefined;

    const dataRange = fromTo
      ? {
          from: fromTo[0],
          to: fromTo[1],
        }
      : undefined;

    const sanitizedFormParams: InternalExportFormParams = Object.entries(
      formParams
    ).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: typeof value === "string" ? value || undefined : value,
      }),
      {}
    );

    const params = {
      ...sanitizedFormParams,
      ...dataRange,
      fromTo: undefined,
      view: variant,
      status:
        formParams.date_type === "completed_on"
          ? undefined
          : sanitizedFormParams.status,
      ...(variant === "board" && tasksSource.type === "boardTasks"
        ? { board: tasksSource.boardId }
        : undefined),
    };

    const generateExcelType =
      variant === "my" ? "tasks" : variant === "board" ? "board" : "team";
    try {
      await generateExcel(generateExcelType, params);
    } catch (error: unknown) {
      if (error instanceof Error && error.message === "No tasks to export") {
        setExportStatus("nothingToExport");
      } else {
        setExportStatus("idle");
      }
      return;
    }

    setExportStatus("done");
  };

  const handleClose = async () => {
    await new Promise<void>((resolve) => {
      onClose();
      setTimeout(resolve, 500);
    });
    resetFormState(defaultValues);
    setExportStatus("idle");
  };

  return (
    <Alert
      className="z-50 w-[760px]"
      contentContainerClassName="px-0 py-10"
      isOpen={isOpen}
      onRequestClose={handleClose}
      isCloseButtonVisible
      disableBackdropClose
      header={
        <div className="flex items-center gap-3">
          <ExcelIcon />
          {t({
            id: "tasks-export-dialog.export-tasks-to-excel",
            defaultMessage: "Export Tasks To Excel",
          })}
        </div>
      }
      buttonsContainerClassName="flex justify-center [&>button]:flex-none"
      buttons={
        exportStatus !== "saving"
          ? [
              {
                className: cx("w-[300px]", {
                  hidden: exportStatus !== "idle",
                }),
                text: t({
                  id: "tasks-export-dialog.export-tasks",
                  defaultMessage: "Export Tasks",
                }),
                color: "primary",
                onClick: handleSubmit(onSubmit),
              },
              {
                className: cx("w-[300px]", {
                  hidden: exportStatus === "idle",
                }),
                text: t({
                  id: "common.okay",
                  defaultMessage: "Okay",
                }),
                color: "primary",
                onClick:
                  exportStatus === "done"
                    ? handleClose
                    : () => setExportStatus("idle"),
              },
            ]
          : undefined
      }
      content={
        <>
          <div
            className={cx("flex w-full flex-col items-center justify-center", {
              hidden: exportStatus !== "saving",
            })}
          >
            <Lottie
              options={{ animationData: loadingAnimation }}
              height={151}
              width={150}
            />
            <div className="font-secondary text-dark text-center text-xl font-medium">
              {t(
                {
                  id: "tasks-export-dialog.saving-file",
                  defaultMessage: "Saving {filename}",
                },
                {
                  filename: `Jugl tasks.xlsx`,
                }
              )}
            </div>
            <div className="text-dark-700 mt-3 text-center text-base">
              {t({
                id: "tasks-export-dialog.please-wait",
                defaultMessage:
                  "Please stay here for a while, we are exporting your file",
              })}
            </div>
            <div className="bg-dark-200 relative mt-[24px] h-[4px] w-[307px] overflow-hidden">
              <div className="bg-primary animate-move-across absolute top-0 left-0 h-full w-[20px]" />
            </div>
          </div>

          <div
            className={cx("flex w-full flex-col items-center justify-center", {
              hidden: exportStatus !== "done",
            })}
          >
            <div className="font-secondary text-dark text-center text-xl font-medium">
              {t({
                id: "tasks-export-dialog.done",
                defaultMessage: "Done! ✅",
              })}
            </div>
            <div className="text-dark-700 mt-3 text-center text-base">
              {t({
                id: "tasks-export-dialog.success-dialog-description",
                defaultMessage: "File was successfully downloaded",
              })}
            </div>
          </div>

          <div
            className={cx("flex w-full flex-col items-center justify-center", {
              hidden: exportStatus !== "nothingToExport",
            })}
          >
            <div className="font-secondary text-dark text-center text-xl font-medium">
              {t({
                id: "tasks-export-dialog.file-is-empty",
                defaultMessage: "File is empty ❌",
              })}
            </div>
            <div className="text-dark-700 mt-3 text-center text-base">
              {t({
                id: "tasks-export-dialog.empty-file-description",
                defaultMessage:
                  "Based on the selected filters, no tasks were found. Please apply other filters and try again",
              })}
            </div>
          </div>

          <div
            className={cx({
              hidden: exportStatus !== "idle",
            })}
          >
            {TASKS_EXPORT_DIALOG_SECTIONS[variant].map((section) => {
              const Component = sectionComponents[section];
              if (
                section === "status" &&
                formParams.date_type === "completed_on"
              ) {
                return null;
              }
              return (
                <Fragment key={section}>
                  <Component
                    entityId={entity.id}
                    control={control}
                    formParams={formParams}
                  />
                  <Divider />
                </Fragment>
              );
            })}
          </div>
        </>
      }
    />
  );
};
