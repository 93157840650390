import React from "react";
import { Button } from "@jugl-web/ui-components";
import { useTranslations } from "@jugl-web/utils";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { ReactComponent as WhatsappIcon } from "./assets/whatsapp.svg";

export const DisconnectedAccountTile: React.FC = () => {
  const { t } = useTranslations();
  const { integrationsApi } = useRestApiProvider();
  const { entityId } = useEntitySelectedProvider();

  const { data: whatsappAgent } = integrationsApi.useWhatsappAgentAccountQuery({
    entityId,
  });

  const [registerWhatsappAccount, { isLoading }] =
    integrationsApi.useRegisterWhatsappAgentAccountMutation();

  return (
    <div className="bg-grey-100 flex w-max items-center gap-6 rounded-2xl p-8">
      <div className="bg-gradients-danger flex h-20 w-20 items-center justify-center rounded-[20px]">
        <WhatsappIcon />
      </div>
      <div className="flex w-[400px] flex-col gap-1">
        <span className="text-dark font-secondary text-[22px] font-medium leading-[150%]">
          {t({
            id: "whatsapp-account-page.account-was-disconnected",
            defaultMessage: "Account was disconnected",
          })}
        </span>
        <span className="text-grey font-secondary text-sm leading-[140%] tracking-[0.14px]">
          {t(
            {
              id: "whatsapp-account-page.disconnected-account-description",
              defaultMessage:
                "Account with ID {accountId} was disconnected. Connect again to proceed with Auto Updates for Your Clients 🔔",
            },
            { accountId: whatsappAgent?.waba_id }
          )}
        </span>
      </div>
      <Button
        className="ml-2 h-10 w-[140px]"
        isDisabled={isLoading}
        onClick={() => registerWhatsappAccount({ entityId })}
      >
        {t({
          id: "common.reconnect",
          defaultMessage: "Reconnect",
        })}
      </Button>
    </div>
  );
};
