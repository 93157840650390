import { taskNameValidator } from "@jugl-web/domain-resources/tasks";
import { useCreateTask } from "@jugl-web/domain-resources/tasks/hooks/useCreateTask";
import {
  INITIAL_FORM_STATE,
  TaskFormState,
} from "@jugl-web/domain-resources/tasks/hooks/useTaskFormState";
import { BoardTasksSource } from "@jugl-web/rest-api/tasks";
import { cx, useTranslations } from "@jugl-web/utils";
import { useTasksPageContext } from "@web-src/features/tasks/TasksPageContext";
import { useOnboarding } from "@web-src/modules/preferences/providers";
import { FC, useRef, useState } from "react";
import { ReactComponent as PlusIcon } from "../../assets/plus.svg";
import { TABLE_DIMENSIONS } from "../../consts";
import { TaskTableLayout } from "../TaskTableLayout";
import { TextCellEditor, TextCellEditorHandle } from "../TextCellEditor";

interface NewTaskRowProps {
  entityId: string;
  initialState?: Partial<TaskFormState>;
}

export const NewTaskRow: FC<NewTaskRowProps> = ({ entityId, initialState }) => {
  const [isEditing, setIsEditing] = useState(false);

  const { tasksSource, tasksSourceInfo, navigateToTaskDetailsPage } =
    useTasksPageContext();

  const { isOnboardingActive, completeOnboardingStep } = useOnboarding();

  const { createTask, showTaskCreationToast, isLoading } = useCreateTask({
    entityId,
    onNavigate: (taskId) => navigateToTaskDetailsPage(taskId),
  });

  const $textCellEditorRef = useRef<TextCellEditorHandle | null>(null);

  const { t } = useTranslations();

  const handleSubmit = async (richText: string) => {
    const formState: TaskFormState = {
      ...INITIAL_FORM_STATE,
      ...initialState,
      title: richText,
      boardId: tasksSourceInfo.isUserBoardTasks
        ? (tasksSource as BoardTasksSource).boardId
        : null,
    };

    try {
      const createdTask = await createTask(formState, tasksSource);

      showTaskCreationToast(createdTask.id);

      if (isOnboardingActive) {
        completeOnboardingStep("task");
      }

      setIsEditing(false);
    } catch {
      // Do nothing
    }
  };

  if (!isEditing) {
    return (
      <TaskTableLayout.Row
        isHoverable
        height={TABLE_DIMENSIONS.TABLE_TASK_NEW_ITEM_ROW_HEIGHT}
        onClick={() => setIsEditing(true)}
      >
        <TaskTableLayout.OutermostEmptyCell />
        <TaskTableLayout.Cell shouldGrow>
          <div className="flex items-center gap-2.5">
            <PlusIcon />
            <TaskTableLayout.CellLabel>Add Task</TaskTableLayout.CellLabel>
          </div>
        </TaskTableLayout.Cell>
      </TaskTableLayout.Row>
    );
  }

  return (
    <TaskTableLayout.Row
      style={{
        minHeight: TABLE_DIMENSIONS.TABLE_TASK_ROW_HEIGHT,
        paddingBottom: TABLE_DIMENSIONS.TABLE_CELL_GAP,
      }}
    >
      <TaskTableLayout.OutermostEmptyCell />
      <TaskTableLayout.Cell
        isFocused
        width={TABLE_DIMENSIONS.TABLE_TASK_TITLE_COLUMN_WIDTH}
        className={cx(
          "justify-between",
          isLoading ? "cursor-not-allowed" : "cursor-text"
        )}
        onClick={() => $textCellEditorRef.current?.focus()}
      >
        <TextCellEditor
          ref={$textCellEditorRef}
          placeholder={t({
            id: "form-controls.task-title.placeholder-extended",
            defaultMessage: "Enter task title",
          })}
          isDisabled={isLoading}
          validator={taskNameValidator}
          onSubmit={handleSubmit}
          onCancel={() => setIsEditing(false)}
        />
      </TaskTableLayout.Cell>
      <TaskTableLayout.Cell shouldGrow />
    </TaskTableLayout.Row>
  );
};
