import { useRestApiProvider } from "@jugl-web/rest-api";
import { DetailedTask, TasksSource } from "@jugl-web/rest-api/tasks";
import { useToast, useTranslations } from "@jugl-web/utils";
import { useCallback } from "react";
import { taskChecklistItemAdapters } from "../components/TaskChecklist";
import { TaskFormState } from "./useTaskFormState";

/**
 * getTasks RTK query cache update logic:
 *
 * We're in:
 *  My Tasks -> we always update the location-based tasks source
 *  Team Tasks -> we always update the location-based tasks source
 *  Board Tasks -> we update the location-based tasks source only if the formState.boardId matches the current boardId
 *  Customer Tasks -> we always update the location-based tasks source
 */
const shouldUpdateGetTasksCache = (
  formState: TaskFormState,
  source: TasksSource
) => {
  if (
    source.type === "boardTasks" &&
    source.boardId !== "my" &&
    source.boardId !== "team"
  ) {
    return source.boardId === formState.boardId;
  }

  return true;
};

export type CreateTaskFn = (
  formState: TaskFormState,
  /**
   * Needed to determine which RTK query cache entry should be updated
   */
  source: TasksSource
) => Promise<DetailedTask>;

interface UseCreateTaskOptions {
  entityId: string;
  onNavigate: (taskId: string) => void;
}

export const useCreateTask = ({
  entityId,
  onNavigate,
}: UseCreateTaskOptions) => {
  const { tasksApi } = useRestApiProvider();
  const [createTaskMutation, { isLoading }] = tasksApi.useCreateTaskMutation();

  const { toast, closeToast } = useToast();
  const { t } = useTranslations();

  const showTaskCreationToast = useCallback(
    (taskId: string, customMessage?: string) => {
      const toastId = toast(
        customMessage ||
          t({
            id: "feedback.task-created-tap-to-open",
            defaultMessage: "Task has been created. Tap to open it",
          }),
        {
          SnackbarProps: {
            style: {
              cursor: "pointer",
              userSelect: "none",
            },
            onClick: () => {
              onNavigate(taskId);
              closeToast(toastId);
            },
          },
        }
      );
    },
    [closeToast, onNavigate, t, toast]
  );

  const createTask = useCallback<CreateTaskFn>(
    (formState, source) => {
      // Supply source-based defaults in case it was not provided via the form
      const sourceBasedCustomerId =
        source.type === "customerTasks" ? source.customerId : null;

      return new Promise<DetailedTask>((resolve, reject) => {
        createTaskMutation({
          entityId,
          task: {
            name: formState.title,
            due_at: formState.dueDate
              ? formState.dueDate.date.toISOString()
              : null,
            priority: formState.priority,
            checklist: formState.checklistItems.map(
              taskChecklistItemAdapters.toBackendModel
            ),
            reminder: formState.dueDate?.reminderExecutionTime
              ? {
                  next_execution:
                    formState.dueDate.reminderExecutionTime.toISOString(),
                }
              : null,
            desc: formState.description,
            label_id: formState.labelId,
            status: formState.statusId,
            tz: formState.dueDate?.timezone || null,
            custom_fields: formState.customFields,
            has_chklist_chk: formState.completeChecklistInOrder,
            is_self: formState.isPrivate,
            recurrence: formState.recurrence,
            ...(formState.isPrivate
              ? {
                  board_id: null,
                  assignees: [],
                  cust_id: null,
                }
              : {
                  board_id: formState.boardId,
                  assignees: formState.assigneeIds,
                  cust_id: formState.customerId || sourceBasedCustomerId,
                }),
          },
          source: shouldUpdateGetTasksCache(formState, source)
            ? source
            : undefined,
        }).then((response) => {
          if ("data" in response) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        });
      });
    },
    [createTaskMutation, entityId]
  );

  return {
    createTask,
    showTaskCreationToast,
    isLoading,
  };
};
