import { ExportedTaskUser } from "@jugl-web/rest-api/tasks/models/common-types/ExportedTaskUser";
import { isoToLocalDate } from "@jugl-web/utils";
import format from "date-fns/format";

export const formatUserName = (user: Omit<ExportedTaskUser, "user_id">) =>
  [user.first_name, user.last_name].filter(Boolean).join(" ").trim();

export const formatUsersList = (list: ExportedTaskUser[]) =>
  list.map(formatUserName).join(", ");

export const formatDate = (
  date: string,
  dateLocale?: Locale,
  customFormat?: string
) =>
  format(isoToLocalDate(date), customFormat || "dd MMM, yyyy, h:mm a", {
    locale: dateLocale,
  });

export const parseMention = (input: string): string => {
  const mentionRegex = /@<[^>]+>\[([^\]]+)\]/g;
  return input.replace(mentionRegex, "@$1");
};

export const getOrdinal = (n: number): string => {
  const s = ["th", "st", "nd", "rd"];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};
