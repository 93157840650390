import { useCustomers } from "@jugl-web/domain-resources/customers/hooks/useCustomers";
import { useTaskViewsContext } from "@jugl-web/domain-resources/tasks";
import { useTaskFiltering } from "@jugl-web/domain-resources/tasks/components/TaskFilteringProvider";
import { useTaskSorting } from "@jugl-web/domain-resources/tasks/components/TaskSortingProvider";
import { useTaskListPreferences } from "@jugl-web/domain-resources/tasks/hooks/useTaskListPreferences";
import { useTasksQuery } from "@jugl-web/domain-resources/tasks/hooks/useTasksQuery";
import { useTasksViewColumns } from "@jugl-web/domain-resources/tasks/hooks/useTasksViewColumns";
import {
  TaskViewType,
  useTasksViews,
} from "@jugl-web/domain-resources/tasks/hooks/useTasksViews";
import { useHeadlessUsersList } from "@jugl-web/domain-resources/users/hooks/useHeadlessUsersList";
import { cx, useTranslations } from "@jugl-web/utils";
import { selectUserId } from "@web-src/features/auth/authSlice";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { FC, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { useSelector } from "react-redux";
import { MoreTasksLoading } from "../MoreTasksLoading";
import { useTasksPageContext } from "../TasksPageContext";
import { TaskTable } from "../TaskTable";
import { TaskTableColumnsProvider } from "../TaskTable/components/TaskTableColumnsProvider";
import { TaskViewEmptyState } from "../TaskViewEmptyState";
import { TaskViewLoading } from "../TaskViewLoading";

export const TaskTableView: FC = () => {
  const { tasksSource, tasksSourceInfo } = useTasksPageContext();

  const { searchQuery, filters, hasActiveFilter } = useTaskFiltering();
  const { sorting } = useTaskSorting();

  const { entityId } = useEntitySelectedProvider();
  const meId = useSelector(selectUserId);

  const { taskListPreferences, updateTaskListPreference } =
    useTaskListPreferences({ entityId, source: tasksSource });

  const { getViewById } = useTasksViews({ entityId, source: tasksSource });

  const { selectedView } = useTaskViewsContext();

  const { ref: moreTasksLoadingRef, inView: isMoreTasksIndicatorInView } =
    useInView();

  const isCustomerView = selectedView.type === TaskViewType.customer;
  const isAssigneeView = selectedView.type === TaskViewType.assignee;
  const isUserBasedView = isAssigneeView || tasksSourceInfo.isTeamTasks;

  const {
    users,
    isLoading: areUsersLoading,
    loadMore: loadMoreUsers,
    reachedEnd: usersReachedEnd,
  } = useHeadlessUsersList({
    entityId,
    sortBy: "name",
    skipInitialLoading: !isUserBasedView,
    boardId:
      tasksSource.type === "boardTasks" && tasksSourceInfo.isUserBoardTasks
        ? tasksSource.boardId
        : undefined,
    onlyReportees: tasksSourceInfo.isTeamTasks,
  });

  const {
    customers,
    isLoading: areCustomersLoading,
    loadMore: loadMoreCustomers,
    reachedEnd: customersReachedEnd,
  } = useCustomers({
    entityId,
    skipInitialLoading: !isCustomerView,
  });

  const { isLoading } = useTasksQuery({ entityId, source: tasksSource });

  const viewColumns = useTasksViewColumns({
    entityId,
    source: tasksSource,
    meId: meId || "",
    users,
    customers,
    searchQuery,
    filters,
    view: tasksSourceInfo.isTeamTasks
      ? getViewById(TaskViewType.reportee)
      : selectedView,
    sorting,
  });

  const isTableExpanded = (columnId: string) =>
    !!taskListPreferences.expandedTables[columnId];

  const handleTableExpandToggle = (columnId: string) => {
    updateTaskListPreference("expandedTables", {
      ...taskListPreferences.expandedTables,
      [columnId]: !isTableExpanded(columnId),
    });
  };

  const { t } = useTranslations();

  const hasNoTable = viewColumns.length === 0;
  const hasAppliedAnyFiltering = searchQuery.length > 0 || hasActiveFilter;

  const shouldShowMoreTasksLoading =
    (isUserBasedView && !usersReachedEnd) ||
    (isCustomerView && !customersReachedEnd);

  // Load more users (assignees or reportees)
  useEffect(() => {
    if (
      isAssigneeView &&
      isMoreTasksIndicatorInView &&
      !areUsersLoading &&
      !usersReachedEnd
    ) {
      loadMoreUsers();
    }
  }, [
    areUsersLoading,
    isMoreTasksIndicatorInView,
    isAssigneeView,
    loadMoreUsers,
    usersReachedEnd,
  ]);

  // Load more customers
  useEffect(() => {
    if (
      isCustomerView &&
      isMoreTasksIndicatorInView &&
      !areCustomersLoading &&
      !customersReachedEnd
    ) {
      loadMoreCustomers();
    }
  }, [
    areCustomersLoading,
    customersReachedEnd,
    isCustomerView,
    isMoreTasksIndicatorInView,
    loadMoreCustomers,
  ]);

  if (isLoading) {
    return (
      <TaskViewLoading
        label={t({
          id: "tasks-page.loading-tasks",
          defaultMessage: "Loading Tasks...",
        })}
        className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
      />
    );
  }

  if (hasNoTable) {
    return (
      <TaskViewEmptyState
        subtitle={
          hasAppliedAnyFiltering
            ? t({
                id: "tasks-page.no-tasks-with-selected-filters",
                defaultMessage: "No tasks with selected filters",
              })
            : t({
                id: "tasks-page.no-tasks-in-selected-view",
                defaultMessage: "No tasks in selected view",
              })
        }
        className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
      />
    );
  }

  return (
    <>
      <div
        className={cx(
          "animate-fade-in flex w-full min-w-max flex-grow flex-col gap-8 px-8 pt-4 pb-6"
        )}
      >
        <TaskTableColumnsProvider entityId={entityId} source={tasksSource}>
          {viewColumns.map((column) => (
            <TaskTable
              key={column.id}
              entityId={entityId}
              meId={meId || ""}
              viewColumn={column}
              isExpanded={isTableExpanded(column.id)}
              onToggleExpand={() => handleTableExpandToggle(column.id)}
            />
          ))}
        </TaskTableColumnsProvider>
      </div>
      {shouldShowMoreTasksLoading && (
        <MoreTasksLoading ref={moreTasksLoadingRef} className="mb-6" />
      )}
    </>
  );
};
