import { useTranslations } from "@jugl-web/utils";
import { useCallback } from "react";
import { Row } from "write-excel-file";
import { ExportedTaskModel } from "@jugl-web/rest-api/tasks/models/ExportedTask";
import { ExportedTaskChecklist } from "@jugl-web/rest-api/tasks/models/common-types/ExportedTaskChecklist";
import { useExportedTableCellGenerator } from "./useExportedTableCellGenerator";
import { useTaskFields } from "../../useTaskFields";

export const useExportedTableRowGenerator = ({
  entityId,
}: {
  entityId: string;
}) => {
  const { t } = useTranslations();
  const { customFields } = useTaskFields({ entityId });
  const {
    generateAssigneesCell,
    generateAttachmentsCell,
    generateBoardCell,
    generateCustomFieldCell,
    generateCustomerCell,
    generateTimeSpentCell,
    generateRecurrenceSettingsCell,
    generateDueDateCell,
    generateHeaderCell,
    generateLabelCell,
    generateNameCell,
    generateOrderIDCell,
    generatePriorityCell,
    generateStatusCell,
    generateTitleCell,
    generateClientEmailCell,
    generateClientPhoneNumberCell,
    generateChecklistNameCell,
    generateChecklistDueDateCell,
    generateChecklistAssigneeCell,
  } = useExportedTableCellGenerator({ entityId });

  const generateNameRows = useCallback(
    (title: string): Row[] => [[{}], [generateNameCell(title)], [{}]],
    [generateNameCell]
  );

  const generateHeaderRow = useCallback(
    (hideBoardCell?: boolean): Row =>
      [
        t({
          id: "tasks-page.task",
          defaultMessage: "Task",
        }),
        t({
          id: "tasks-page.order-id",
          defaultMessage: "Order ID",
        }),
        t({
          id: "tasks-page.due-date",
          defaultMessage: "Due Date",
        }),
        t({
          id: "tasks-page.assignee",
          defaultMessage: "Assignee",
        }),
        t({
          id: "tasks-page.label",
          defaultMessage: "Label",
        }),
        t({
          id: "tasks-page.priority",
          defaultMessage: "Priority",
        }),
        t({
          id: "tasks-page.status",
          defaultMessage: "Status",
        }),
        t({
          id: "tasks-page.client",
          defaultMessage: "Client",
        }),
        t({
          id: "tasks-export.time-spent-on-task",
          defaultMessage: "Time spent on Task",
        }),
        t({
          id: "tasks-export.recurring-setting",
          defaultMessage: "Recurring setting",
        }),
        ...(!hideBoardCell
          ? [
              t({
                id: "tasks-page.board",
                defaultMessage: "Board",
              }),
            ]
          : []),
        t({
          id: "tasks-page.attachments",
          defaultMessage: "Attachments",
        }),
        t({
          id: "tasks-page.client-email",
          defaultMessage: "Client Email",
        }),
        t({
          id: "tasks-page.client-phone-number",
          defaultMessage: "Client Phone Number",
        }),
        ...customFields.map(({ name }) => name),
      ].map(generateHeaderCell),
    [customFields, generateHeaderCell, t]
  );

  const generateTaskRow = useCallback(
    (task: ExportedTaskModel, hideBoardCell?: boolean): Row => [
      generateTitleCell(task),
      generateOrderIDCell(task),
      generateDueDateCell(task),
      generateAssigneesCell(task),
      generateLabelCell(task),
      generatePriorityCell(task),
      generateStatusCell(task),
      generateCustomerCell(task),
      generateTimeSpentCell(task),
      generateRecurrenceSettingsCell(task),
      ...(!hideBoardCell ? [generateBoardCell(task)] : []),
      generateAttachmentsCell(task),
      generateClientEmailCell(task),
      generateClientPhoneNumberCell(task),
      ...customFields.map((customField) =>
        generateCustomFieldCell(task, customField)
      ),
    ],
    [
      generateTitleCell,
      generateOrderIDCell,
      generateDueDateCell,
      generateAssigneesCell,
      generateLabelCell,
      generatePriorityCell,
      generateStatusCell,
      generateCustomerCell,
      generateTimeSpentCell,
      generateRecurrenceSettingsCell,
      generateBoardCell,
      generateAttachmentsCell,
      generateClientEmailCell,
      generateClientPhoneNumberCell,
      customFields,
      generateCustomFieldCell,
    ]
  );

  const generateTaskChecklistsHeaderRow = useCallback(
    (): Row => [
      {},
      ...[
        t({
          id: "tasks-page.checklists",
          defaultMessage: "Checklists",
        }),
        t({
          id: "tasks-page.due-date",
          defaultMessage: "Due Date",
        }),
        t({
          id: "tasks-page.assignee",
          defaultMessage: "Assignee",
        }),
      ].map(generateHeaderCell),
    ],
    [generateHeaderCell, t]
  );

  const generateTaskChecklistRow = useCallback(
    (checklistItem: ExportedTaskChecklist): Row => [
      {},
      generateChecklistNameCell(checklistItem),
      generateChecklistDueDateCell(checklistItem),
      generateChecklistAssigneeCell(checklistItem),
    ],
    [
      generateChecklistNameCell,
      generateChecklistDueDateCell,
      generateChecklistAssigneeCell,
    ]
  );

  return {
    generateNameRows,
    generateHeaderRow,
    generateTaskRow,
    generateTaskChecklistsHeaderRow,
    generateTaskChecklistRow,
  };
};
