import { useMarkModuleAsRead } from "@jugl-web/domain-resources/entities/hooks/useMarkModuleAsRead";
import {
  EmptyListContent,
  Menu,
  PlainButton,
  TableGrid,
} from "@jugl-web/ui-components/cross-platform";
import { SearchInput } from "@jugl-web/ui-components/cross-platform/SearchInput";
import { HeaderBreadcrumbs } from "@jugl-web/ui-components/web";
import { useTranslations } from "@jugl-web/utils";
import { useMe } from "@web-src/features/app/hooks/useMe";
import { useLazyCallsHistoryQuery } from "@web-src/features/chats/chatsApi";
import {
  PhoenixSocketContext,
  PhxResponse,
} from "@web-src/features/chats/providers/PheonixSocket";
import {
  CallListHistoryItem,
  ChatMessage,
  ChatMessagePayloadPushType,
} from "@web-src/features/chats/types";
import UserProfileAvatar from "@web-src/features/users/components/UserProfileAvatar";
import UserProfileName from "@web-src/features/users/components/UserProfileName";
import { useCallInvitation } from "@web-src/modules/conference/pages/ConferencePage/hooks/useCallInvitation";
import { CallsContext } from "@web-src/modules/conference/pages/ConferencePage/providers";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { dateTimeString } from "@web-src/utils/datetime";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useEffectOnce } from "react-use";
import { ReactComponent as OneToOneIcon } from "./assets/1-1-call.svg";
import { ReactComponent as CallsIcon } from "./assets/calls.svg";
import { ReactComponent as IncomingCallIcon } from "./assets/incoming-call.svg";
import { ReactComponent as MissedCallIcon } from "./assets/missed-call.svg";
import { ReactComponent as MoreIcon } from "./assets/more.svg";
import { ReactComponent as OutgoingCallIcon } from "./assets/outgoing-call.svg";
import { ReactComponent as VideoCall } from "./assets/video-call.svg";
import { NewCallPicker } from "./components/NewCallPicker";

type CallHistoryItem = {
  callerId: string;
  callType: React.ReactNode;
  duration: string;
  formattedDate: string;
  isVideoCall: boolean;
};

export const CallsHistory: React.FC = () => {
  const [historyData, setHistoryData] = useState<CallListHistoryItem[]>([]);
  const [isLastPage, setIsLastPage] = useState<boolean>(false);
  const [loadingInProgress, setLoadingInProgress] = useState<boolean>(false);
  const { t } = useTranslations();
  const { entity } = useEntitySelectedProvider();
  const today = useMemo(() => new Date().toISOString(), []);
  const { me } = useMe();
  const [loadCallsHistory, { isLoading }] = useLazyCallsHistoryQuery();
  const { incomingCall, activeCallProps } = useContext(CallsContext);
  const { incomingMessages$, channel } = useContext(PhoenixSocketContext);

  const { call } = useCallInvitation();

  const handleLoadMoreHistoryItems = async () => {
    if (loadingInProgress) return;
    setLoadingInProgress(true);
    const response = await loadCallsHistory({
      params: {
        entity_id: entity.id,
        time: historyData.at(-1)?.timestamp || today,
        limit: 20,
      },
    });
    const callList = response.data?.call_list ?? [];
    if (!callList.length || callList.length !== 20) {
      setIsLastPage(true);
    }
    if (historyData.at(-1)?.msg_id !== callList.at(-1)?.msg_id) {
      setHistoryData((prev) => [...prev, ...callList]);
    }
    setLoadingInProgress(false);
  };

  const typeLabels = useMemo(
    () => ({
      canceled: (
        <div className="flex flex-row items-center gap-1">
          <MissedCallIcon />{" "}
          {t({ id: "common.canceled", defaultMessage: "Canceled" })}
        </div>
      ),
      incoming: (
        <div className="flex flex-row items-center gap-1">
          <IncomingCallIcon />{" "}
          {t({
            id: "calls-page.incoming-call",
            defaultMessage: "Incoming call",
          })}
        </div>
      ),
      outgoing: (
        <div className="flex flex-row items-center gap-1">
          <OutgoingCallIcon />{" "}
          {t({
            id: "calls-page.outgoing-call",
            defaultMessage: "Outgoing call",
          })}
        </div>
      ),
      missed: (
        <div className="flex flex-row items-center gap-1">
          <MissedCallIcon />{" "}
          {t({ id: "calls-page.missed-call", defaultMessage: "Missed call" })}
        </div>
      ),
    }),
    [t]
  );

  const handleStartCall = useCallback(
    (userId: string, type: "audio" | "video") => {
      call({ callType: type, userId });
    },
    [call]
  );

  useMarkModuleAsRead({ entityId: entity.id, module: "call" });

  useEffectOnce(() => {
    handleLoadMoreHistoryItems();
  });

  useEffect(() => {
    const putEventInState = (
      message: ChatMessage | PhxResponse<ChatMessage>
    ) => {
      const isResponse = "response" in message;
      const data = isResponse ? message.response : message;
      const isSameEntity = data.entity_id === entity?.id;
      if (!isSameEntity) return;
      if (!data) return;
      const {
        payload: {
          call_action: callAction,
          call_channel: callChannel,
          call_type: callType,
          sender_name: senderName,
        },
      } = data;
      if (!callAction || !callChannel || !callType || !senderName) return;
      if (callAction === "call_duration" || callAction === "call_declined") {
        const incommingCallListItem: CallListHistoryItem = {
          msg_id: data.msg_id,
          msg_receipt_id: data.msg_receipt_id,
          from: data.from,
          to: data.to,
          timestamp: data.timestamp,
          payload: {
            body: data.payload?.body,
            call_channel: callChannel,
            call_action: callAction,
            call_type: callType,
            duration: data.payload?.duration || "",
            type: "call",
            push_type: ChatMessagePayloadPushType.silent,
            version: data.payload.version || 1,
            sender_name: senderName,
            title: data.payload.title || "",
          },
          entity_id: data.entity_id,
        };
        setHistoryData((prev) => [incommingCallListItem, ...prev]);
      }
    };
    const incomingMessagesSubscription = incomingMessages$?.subscribe(
      (message) => putEventInState(message)
    );

    const phxCallSent = channel?.on("phx_call_sent", (message) =>
      putEventInState(message)
    );

    const phxCallResponse = channel?.on(
      "phx_call",
      (message: PhxResponse<ChatMessage>) => {
        putEventInState(message);
      }
    );
    return () => {
      channel?.off("phx_call", phxCallResponse);
      channel?.off("phx_call_sent", phxCallSent);
      incomingMessagesSubscription?.unsubscribe();
    };
  }, [channel, entity?.id, incomingMessages$, me?.id]);

  const getCallType = useCallback(
    (event: CallListHistoryItem, callerId: string) => {
      const isCallFromMe = callerId === me?.id;

      const hasDuration = event?.payload.call_action === "call_duration";
      if (isCallFromMe) {
        if (hasDuration || event?.payload.call_action === "call_declined") {
          return typeLabels.outgoing;
        }
        return typeLabels.missed;
      }
      if (!isCallFromMe) {
        if (hasDuration) {
          return typeLabels.incoming;
        }
        return typeLabels.missed;
      }
      return <>unknown</>;
    },
    [typeLabels, me?.id]
  );

  const convertToSeconds = useCallback((timeStr: string): number => {
    let totalSeconds = 0;

    const hoursMatch = timeStr.match(/(\d+)h/);
    const minutesMatch = timeStr.match(/(\d+)m/);
    const secondsMatch = timeStr.match(/(\d+)s/);

    if (hoursMatch) {
      totalSeconds += parseInt(hoursMatch[1], 10) * 3600;
    }
    if (minutesMatch) {
      totalSeconds += parseInt(minutesMatch[1], 10) * 60;
    }
    if (secondsMatch) {
      totalSeconds += parseInt(secondsMatch[1], 10);
    }

    return totalSeconds;
  }, []);

  const formatDuration = useCallback(
    (durationStr: string | undefined) => {
      if (!durationStr) return "0s";
      const totalSeconds = convertToSeconds(durationStr);

      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = totalSeconds % 60;

      let result = "";
      if (hours > 0) {
        result += `${hours}h `;
      }
      if (minutes > 0 || hours > 0) {
        result += `${minutes.toString().padStart(2, "0")}m `;
      }
      result += `${seconds.toString().padStart(2, "0")}s`;

      return result.trim();
    },
    [convertToSeconds]
  );
  const transformHistoryData = useMemo(() => {
    const groupedByChannelId = historyData.reduce((acc, item) => {
      if (
        (incomingCall &&
          item.payload.call_channel === activeCallProps?.channel) ||
        item.payload.call_channel === activeCallProps?.channel
      )
        return acc;
      if (!acc[item.payload.call_channel]) {
        acc[item.payload.call_channel] = [];
      }
      acc[item.payload.call_channel].push(item);
      return acc;
    }, {} as Record<string, CallListHistoryItem[]>);
    const channelIds = Object.keys(groupedByChannelId);
    const transformedHistoryData: CallHistoryItem[] = [];
    channelIds.forEach((channelId) => {
      if (groupedByChannelId[channelId].length) {
        const firstItem = groupedByChannelId[channelId].at(-1);
        if (!firstItem || !me?.id) return;
        const isCallFromMe =
          (firstItem.from === me.id &&
            firstItem.payload.call_type?.toLowerCase().includes("outgoing")) ||
          (firstItem.from !== me.id &&
            firstItem.payload.call_type?.toLowerCase().includes("incoming"));

        const interlocutorId =
          firstItem.to === me.id ? firstItem.from : firstItem.to;
        const callInitiatedByUserId = isCallFromMe ? me.id : interlocutorId;
        const isVideoCall = JSON.stringify(groupedByChannelId[channelId])
          .toLowerCase()
          .includes("video");

        const transformedItem: CallHistoryItem = {
          callerId: interlocutorId,
          callType: getCallType(firstItem, callInitiatedByUserId),
          duration: formatDuration(firstItem.payload.duration),
          formattedDate: dateTimeString(firstItem.timestamp),
          isVideoCall,
        };
        transformedHistoryData.push(transformedItem);
      }
    });
    return transformedHistoryData;
  }, [
    historyData,
    incomingCall,
    activeCallProps?.channel,
    me?.id,
    getCallType,
    formatDuration,
  ]);

  return (
    <div className="bg-grey-200 flex min-h-[100vh] flex-col">
      <HeaderBreadcrumbs
        icon={<CallsIcon />}
        items={[{ title: t({ id: "common.calls", defaultMessage: "Calls" }) }]}
      />
      <div className="mt-4 flex w-full justify-end px-8">
        {!true && <SearchInput variant="blank" className="bg-white" />}
        <NewCallPicker />
      </div>
      <div className="mt-9 w-full px-8">
        <TableGrid
          onReachEnd={() => !isLastPage && handleLoadMoreHistoryItems()}
          data={transformHistoryData || []}
          columns={[
            {
              title: t({ id: "common.name", defaultMessage: "Name" }),
              grow: true,
              content: ({ callerId }) => (
                <span className="flex flex-row items-center gap-4">
                  <UserProfileAvatar
                    userId={callerId}
                    wrapperClassName="h-8 w-8 rounded-full bg-grey-100 flex items-center place-content-center bg-gradient-to-b from-gray-300 to-dark-100 mt-0.5"
                    className="bg-grey-100 h-8 w-8 rounded-full"
                    lettersClassName="h-8 w-8 text-dark flex items-center justify-center text-base font-semibold uppercase leading-3"
                  />
                  <UserProfileName userId={callerId} />
                </span>
              ),
            },
            {
              title: t({ id: "common.type", defaultMessage: "Type" }),
              grow: true,
              content: ({ callType }) => <>{callType}</>,
              className: "flex items-center",
            },
            {
              title: t({ id: "common.duration", defaultMessage: "Duration" }),
              grow: true,
              content: ({ duration, isVideoCall }) => (
                <span className="flex items-center gap-1">
                  {isVideoCall ? <VideoCall /> : <OneToOneIcon />}
                  {duration}
                </span>
              ),
              className: "flex items-center",
            },
            {
              title: t({ id: "common.date", defaultMessage: "Date" }),
              grow: true,
              content: ({ formattedDate }) => <>{formattedDate}</>,
              className: "flex items-center",
            },
            {
              title: "",
              grow: false,
              className: "flex items-center",
              content: ({ callerId }) => (
                <Menu
                  placement="bottom-end"
                  renderTrigger={({ Trigger, triggerRef }) => (
                    <Trigger as={PlainButton} ref={triggerRef}>
                      <MoreIcon />
                    </Trigger>
                  )}
                  className="py-2"
                  sections={[
                    [
                      {
                        id: "start-call",
                        label: t({
                          id: "calls-page.start-call",
                          defaultMessage: "Start call",
                        }),
                        onSelect: (_, close) => {
                          handleStartCall(callerId, "audio");
                          close();
                        },
                      },
                    ],
                  ]}
                />
              ),
            },
          ]}
        />
        {!transformHistoryData.length && !isLoading && (
          <EmptyListContent
            type="noResults"
            className="mt-52"
            customTitle={t({
              id: "calls-page.empty-list",
              defaultMessage: "Call History is empty",
            })}
            customSubtitle={t({
              id: "calls-page.empty-list-subtitle",
              defaultMessage:
                "You don't have any received or outcoming calls yet",
            })}
          />
        )}
      </div>
    </div>
  );
};
