import { useTaskBoards } from "@jugl-web/domain-resources/tasks/hooks/useTaskBoards";
import { useTasksQuery } from "@jugl-web/domain-resources/tasks/hooks/useTasksQuery";
import { TasksSource } from "@jugl-web/rest-api/tasks";
import { Tooltip } from "@jugl-web/ui-components";
import { ControlBarButton } from "@jugl-web/ui-components/web/ControlBarButton";
import { cx, useRerender, useTranslations } from "@jugl-web/utils";
import { useFormattedDate } from "@jugl-web/utils/hooks/useFormattedDate";
import { FC, useEffect } from "react";
import { ReactComponent as RefreshIcon } from "./assets/refresh.svg";

interface TaskRefreshButtonProps {
  entityId: string;
  source: TasksSource;
}

export const TaskRefreshButton: FC<TaskRefreshButtonProps> = ({
  entityId,
  source,
}) => {
  const {
    isFetching: isRefreshing,
    fulfilledTimeStamp,
    refetch: refetchTasks,
  } = useTasksQuery({ entityId, source });

  const { refetch: refetchTaskBoards } = useTaskBoards({ entityId });

  const { formatDateTimeLabel, humanizePastDate } = useFormattedDate();
  const { t } = useTranslations();

  const rerender = useRerender();

  const humanizedLastRefreshDate = humanizePastDate(
    new Date(fulfilledTimeStamp ?? 0),
    { customJustNowMessage: t({ id: "common.now", defaultMessage: "Now" }) }
  );

  const handleClick = () => {
    refetchTasks();
    // As a side effect, refetch task boards data
    refetchTaskBoards();
  };

  // Re-render component every minute to update the last refresh date label
  useEffect(() => {
    const intervalId = setInterval(() => {
      rerender();
    }, 60 * 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [rerender]);

  return (
    <Tooltip
      placement="bottom"
      isDisabled={isRefreshing}
      renderTrigger={({ props, ref }) => (
        <ControlBarButton
          ref={ref}
          startSlot={
            <RefreshIcon
              className={cx(
                isRefreshing ? "text-primary animate-spin" : "text-grey"
              )}
            />
          }
          className="hover:bg-grey-100 px-2.5"
          label={
            <span className="text-dark-700">
              {isRefreshing
                ? t({
                    id: "tasks-page.refreshing-ellipsis",
                    defaultMessage: "Refreshing...",
                  })
                : t(
                    {
                      id: "tasks-page.refreshed-x-time-ago",
                      defaultMessage: "Refreshed: {lastRefreshDate}",
                    },
                    {
                      lastRefreshDate: (
                        <span className="text-dark-800">
                          {humanizedLastRefreshDate}
                        </span>
                      ),
                    }
                  )}
            </span>
          }
          isActive={isRefreshing}
          isDisabled={isRefreshing}
          onClick={handleClick}
          {...props}
        />
      )}
    >
      {formatDateTimeLabel(fulfilledTimeStamp ?? 0)}
    </Tooltip>
  );
};
