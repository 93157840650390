import { WhatsappBody } from "../types/WhatsappBody";

/**
 * Converts a WhatsappBody to a processed body string.
 * @param whatsappBody The WhatsappBody to convert.
 * @returns The processed body string.
 * @example
 * whatsappBodyToProcessedBody({
 *  body: "Hello, *{{1}}*!",
 *  variables: ["John Doe"],
 * }); // "Hello, *John Doe*!"
 */
export const whatsappBodyToProcessedBody = (
  whatsappBody: WhatsappBody
): string => {
  let processedBody = whatsappBody.body;
  whatsappBody.variables.forEach((variable, idx) => {
    processedBody = processedBody.replaceAll(`{{${idx + 1}}}`, variable);
  });
  return processedBody;
};
