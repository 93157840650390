import { useRestApiProvider } from "@jugl-web/rest-api";
import { getImagePathForSharedLibrary, useTranslations } from "@jugl-web/utils";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useMemo } from "react";
import { CustomerDetails, getCustomerDetails } from "../../utils";
import memberAvatar from "./assets/member.webp";

export interface UseCustomerOptions {
  entityId: string;
  customerId?: string | null;
  skipLoading?: boolean;
}

export const useCustomer = ({
  entityId,
  customerId,
  skipLoading,
}: UseCustomerOptions) => {
  const { customersApi } = useRestApiProvider();
  const { t } = useTranslations();

  const { data: customer, isLoading } = customersApi.useCustomerQuery(
    customerId && !skipLoading ? { entityId, customerId } : skipToken
  );

  const customerDetails = useMemo<CustomerDetails | null>(() => {
    if (!customer) {
      return null;
    }

    return getCustomerDetails(customer.data);
  }, [customer]);

  const safeCustomerDetails = useMemo<CustomerDetails>(() => {
    if (customerDetails) {
      return customerDetails;
    }

    return {
      fullName: isLoading
        ? t({
            id: "common.jugl-customer",
            defaultMessage: "Jugl Customer",
          })
        : t({
            id: "tasks-page.former-client",
            defaultMessage: "Former Client",
          }),
      avatarUrl: getImagePathForSharedLibrary(memberAvatar),
    };
  }, [customerDetails, isLoading, t]);

  return {
    customerDetails,
    safeCustomerDetails,
    isLoading,
  };
};
