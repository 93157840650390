import {
  getLexicalConfig,
  getWhatsappMarkdownTransformers,
} from "@jugl-web/domain-resources/common/components/RichTextarea";
import { SUPPORTED_WHATS_APP_VARIABLES } from "@jugl-web/domain-resources/integrations/consts";
import { $generateHtmlFromNodes } from "@lexical/html";
import { $convertFromMarkdownString } from "@lexical/markdown";
import { createEditor, CreateEditorArgs } from "lexical";
import { useCallback, useId, useMemo } from "react";
import { WhatsappBody } from "../types/WhatsappBody";
import { whatsappBodyToProcessedBody } from "../utils/whatsappBodyToProcessedBody";

export const useWhatsappTemplateBodyRenderer = () => {
  const editorId = useId();

  const editor = useMemo(
    () => createEditor(getLexicalConfig(editorId, false) as CreateEditorArgs),
    [editorId]
  );

  const renderHtml = useCallback(
    (whatsappBody: WhatsappBody) => {
      const processedBody = whatsappBodyToProcessedBody(whatsappBody);

      return new Promise<string>((resolve) => {
        editor.update(
          () => {
            $convertFromMarkdownString(
              processedBody,
              getWhatsappMarkdownTransformers(SUPPORTED_WHATS_APP_VARIABLES)
            );
          },
          {
            onUpdate: () => {
              const html = editor
                .getEditorState()
                .read(() => $generateHtmlFromNodes(editor));

              resolve(html);
            },
          }
        );
      });
    },
    [editor]
  );

  return { renderHtml };
};
