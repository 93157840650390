import { useFilePreview } from "@jugl-web/domain-resources/files/providers/FilePreviewProvider";
import {
  TaskChecklist,
  taskChecklistItemAdapters,
} from "@jugl-web/domain-resources/tasks";
import { DescriptionBox } from "@jugl-web/domain-resources/tasks/components/DescriptionBox";
import { TaskAttachments } from "@jugl-web/domain-resources/tasks/components/TaskAttachments";
import { TaskPropertiesPanel } from "@jugl-web/domain-resources/tasks/components/TaskPropertiesPanel";
import { TitleBox } from "@jugl-web/domain-resources/tasks/components/TitleBox";
import { useTaskBoards } from "@jugl-web/domain-resources/tasks/hooks/useTaskBoards";
import { useTaskPermissions } from "@jugl-web/domain-resources/tasks/hooks/useTaskPermissions";
import { useRestApiProvider } from "@jugl-web/rest-api";
import {
  ArchivedTask,
  TaskAttachment,
  stripTaskDescriptionPrefix,
} from "@jugl-web/rest-api/tasks";
import { Button, LoadingAnimation } from "@jugl-web/ui-components";
import { BottomCenteredDrawer } from "@jugl-web/ui-components/web/BottomCenteredDrawer";
import { DrawerHeaderNavButton } from "@jugl-web/ui-components/web/DrawerHeader";
import { useTranslations } from "@jugl-web/utils";
import { useMe } from "@web-src/features/app/hooks/useMe";
import { TaskFormDialog } from "@web-src/features/tasks/TaskFormDialog";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useTimeZone } from "@web-src/modules/settings/providers/TimeZoneProvider";
import { isoToLocalDate } from "@web-src/utils/helper";
import { useMemo, useState } from "react";
import { ConfirmRemoveTaskDialog } from "../ConfirmRemoveTaskDialog";
import { ReactComponent as AttentionIcon } from "./assets/attention.svg";
import { ReactComponent as BinIcon } from "./assets/bin.svg";

export const TaskArchiveDetailsDrawer = ({
  archivedTask,
  isOpen,
  onClose,
  onRestoreTask,
  onRemoveTask,
}: {
  archivedTask: ArchivedTask | undefined;
  isOpen: boolean;
  onClose: () => void;
  onRestoreTask: (taskId: string) => void;
  onRemoveTask: (taskId: string) => void;
}) => {
  const { timeZone } = useTimeZone();
  const { t } = useTranslations();
  const { me } = useMe();
  const { tasksApi } = useRestApiProvider();
  const { entity } = useEntitySelectedProvider();
  const { previewFile } = useFilePreview();

  const [isConfirmRemoveTaskOpen, setConfirmRemoveTaskOpen] =
    useState<boolean>(false);

  const { hasAccess: hasBoardAccess } = useTaskBoards({ entityId: entity.id });

  const {
    data: detailedTask,
    isLoading,
    isFetching,
  } = tasksApi.useGetArchivedDetailedTaskQuery(
    {
      entityId: entity.id,
      taskId: archivedTask?.id || "",
    },
    { skip: !archivedTask?.id }
  );

  const permissions = useTaskPermissions({
    entityId: entity.id,
    meId: me?.id,
    task: detailedTask,
  });

  const adaptedChecklistItems = useMemo(
    () =>
      (detailedTask?.checklist || []).map(
        taskChecklistItemAdapters.toInternalModel
      ),
    [detailedTask?.checklist]
  );

  const validAttachmentsCount = useMemo(
    () =>
      detailedTask?.attachments.filter(
        (attachment) => attachment.status !== "invalid"
      ).length || 0,
    [detailedTask]
  );

  const handlePreviewAttachment = (attachment: TaskAttachment) =>
    previewFile({
      url: attachment.stream_url,
      name: attachment.name,
      mimeType: attachment.content_type,
    });

  if (isLoading || isFetching) {
    return (
      <BottomCenteredDrawer
        isOpen={isOpen}
        onClose={onClose}
        header={{
          type: "title",
          title: (
            <div className="flex w-full items-center justify-between">
              <div className="flex items-center">
                {t({
                  id: "task-details-page.task-details",
                  defaultMessage: "Task details",
                })}
              </div>
            </div>
          ),
        }}
      >
        <TaskFormDialog.Content className="animate-fade-in flex items-center justify-center">
          <LoadingAnimation />
        </TaskFormDialog.Content>
      </BottomCenteredDrawer>
    );
  }

  if (!detailedTask || !archivedTask || detailedTask.id !== archivedTask.id) {
    return null;
  }

  return (
    <BottomCenteredDrawer
      isOpen={isOpen}
      header={{
        type: "title",
        title: (
          <div className="flex w-full items-center justify-between">
            <div className="flex items-center">
              {t({
                id: "task-details-page.task-details",
                defaultMessage: "Task details",
              })}
              <DrawerHeaderNavButton
                tip={t({
                  id: "tasks-page.archived-task-cannot-be-edited",
                  defaultMessage: "Archived tasks cannot be edited ⚠️",
                })}
                className="ml-4 flex items-center justify-center gap-1.5 py-[7px] px-2"
              >
                <span className="text-dark-700 text-xs font-normal">
                  {t({
                    id: "tasks-page.view-only",
                    defaultMessage: "View only",
                  })}
                </span>
                <AttentionIcon />
              </DrawerHeaderNavButton>
            </div>
          </div>
        ),
        endSlot: permissions.canDeleteTask ? (
          <DrawerHeaderNavButton onClick={() => setConfirmRemoveTaskOpen(true)}>
            <BinIcon />
          </DrawerHeaderNavButton>
        ) : undefined,
      }}
      onClose={onClose}
    >
      <BottomCenteredDrawer.Content>
        <TaskFormDialog.Content className="animate-fade-in">
          <TaskFormDialog.TitleSection>
            <TitleBox
              title={detailedTask?.name || ""}
              isEditable={false}
              classes={{ titleText: "py-3" }}
            />
            {detailedTask?.desc && (
              <DescriptionBox
                description={stripTaskDescriptionPrefix(detailedTask.desc)}
                classes={{ descriptionText: "mb-8 mt-2" }}
                isEditable={false}
              />
            )}
            <TaskPropertiesPanel
              entityId={entity.id}
              config={{
                board: {
                  boardId: detailedTask.board_id,
                  isReadonly: true,
                  isHidden: detailedTask.is_self,
                  hasBoardAccess: detailedTask.board_id
                    ? hasBoardAccess(detailedTask.board_id)
                    : undefined,
                },
                status: {
                  statusId: detailedTask.status,
                  isReadonly: true,
                },
                dueDate: {
                  state: detailedTask.due_at
                    ? {
                        date: isoToLocalDate(detailedTask.due_at),
                      }
                    : null,
                  displayAs: "date",
                  isReadonly: true,
                },
                assignees: {
                  assigneeIds: detailedTask.assignees,
                  isReadonly: true,
                  isHidden: detailedTask.is_self,
                },
                priority: {
                  priority: detailedTask.priority,
                  isReadonly: true,
                },
                label: {
                  labelId: detailedTask.label_id,
                  isReadonly: true,
                },
                customer: {
                  customerId: detailedTask.cust_id,
                  isReadonly: true,
                },
                timeSpent: {
                  duration: detailedTask.duration,
                  isReadonly: true,
                },
                attachments: {
                  count: validAttachmentsCount,
                  onClick: () => {},
                },
                recurrence: {
                  state: detailedTask.recurrence,
                  defaultTimezone: timeZone.name,
                  isReadonly: true,
                },
                customFields: {
                  valuesById: detailedTask?.custom_fields || {},
                  isReadonly: true,
                },
              }}
            />
          </TaskFormDialog.TitleSection>

          {detailedTask.checklist.length > 0 && (
            <TaskFormDialog.ChecklistSection>
              <TaskChecklist
                entityId={entity.id}
                meId={me?.id || ""}
                items={adaptedChecklistItems}
                isManageable={false}
                isCompletable={false}
                displayDueDateAs="days"
              />
            </TaskFormDialog.ChecklistSection>
          )}

          {detailedTask.attachments.length > 0 && (
            <TaskFormDialog.AttachmentsSection>
              <TaskAttachments
                mode="uncontrolled"
                attachments={detailedTask.attachments}
                entityId={entity.id}
                taskId={detailedTask.id}
                isEditable={false}
                className="mt-4 px-4"
                onPreviewAttachment={handlePreviewAttachment}
              />
            </TaskFormDialog.AttachmentsSection>
          )}
        </TaskFormDialog.Content>
      </BottomCenteredDrawer.Content>
      {permissions.canRestoreTask && (
        <BottomCenteredDrawer.Actions className="flex items-center justify-center gap-3.5">
          <Button
            variant="contained"
            color="primary"
            className="h-10 w-[200px]"
            onClick={() => {
              onRestoreTask(archivedTask.id);
              onClose();
            }}
          >
            {t({
              id: "tasks-page.restore-task",
              defaultMessage: "Restore task",
            })}
          </Button>
        </BottomCenteredDrawer.Actions>
      )}
      <ConfirmRemoveTaskDialog
        isOpen={isConfirmRemoveTaskOpen}
        task={archivedTask}
        onClose={() => setConfirmRemoveTaskOpen(false)}
        onConfirm={() => {
          onRemoveTask(archivedTask.id);
          onClose();
        }}
      />
    </BottomCenteredDrawer>
  );
};
