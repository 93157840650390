import {
  Dialog,
  DroppableFileInputProps,
  PlainButton,
} from "@jugl-web/ui-components";
import { ScreenTransitionWrapper } from "@jugl-web/ui-components/cross-platform/ScreenTransitionWrapper";
import {
  Screen,
  ScreenTransitionManagerProvider,
  useScreenTransitionManager,
} from "@jugl-web/utils/utils/ScreenTransitionManager";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  cx,
  isImportTasksApiError,
  useToast,
  useTranslations,
} from "@jugl-web/utils";
import { Row } from "read-excel-file";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { ImportedTaskModel } from "@jugl-web/rest-api/tasks/models/ImportedTask/ImportedTaskModel";
import {
  TASK_TEXT_FIELD_CHAR_LIMIT,
  TITLE_MAX_LENGTH,
} from "@jugl-web/domain-resources/tasks";
import { useTaskFields } from "@jugl-web/domain-resources/tasks/hooks/useTaskFields";
import { DiscardChangesDialog } from "@web-src/modules/common/components";
import parser from "any-date-parser";
import { useLanguage } from "@jugl-web/utils/i18n/EnhancedIntlProvider";
import endOfDay from "date-fns/endOfDay";
import { DuplicateTaskAction, TasksImportScreenToParametersMap } from "./types";
import { FileUploadScreen } from "./components/FileUploadScreen";
import { TasksNameScreen } from "./components/TasksNameScreen";
import { ReactComponent as ImportIcon } from "./assets/import.svg";
import { ReactComponent as CloseIcon } from "./assets/close.svg";
import { AlignColumnScreen } from "./components/AlignColumnScreen";
import { ImportSettingsScreen } from "./components/ImportSettingsScreen";
import { ImportLoadingScreen } from "./components/ImportLoadingScreen";
import { ImportErrorScreen } from "./components/ImportErrorScreen";
import { ImportSuccessScreen } from "./components/ImportSuccessScreen";
import { useImportApiErrorMessage } from "./hooks/useImportApiErrorMessage";

interface TasksImportDialogProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

const TasksImportDialogContent: React.FC<TasksImportDialogProps> = ({
  isOpen,
  onRequestClose,
}) => {
  const { t } = useTranslations();
  const { dateLocale } = useLanguage();
  const { screen, transitionTo, renderContent } =
    useScreenTransitionManager<TasksImportScreenToParametersMap>();
  const { tasksApi } = useRestApiProvider();
  const [importTasks] = tasksApi.useImportTasksMutation();
  const { entityId } = useEntitySelectedProvider();
  const { toast } = useToast({ variant: "web" });

  const [isDiscardChangesDialogOpen, setIsDiscardChangesDialogOpen] =
    useState(false);
  const [inputValue, setInputValue] =
    useState<DroppableFileInputProps["value"]>();
  const [data, setData] = useState<Row[]>([]);
  const [taskNameColumn, setTaskNameColumn] = useState<number>();
  const [mappedColumns, setMappedColumns] = useState<
    { column: number; value: string }[]
  >([]);
  const [duplicateTaskAction, setDuplicateTaskAction] =
    useState<DuplicateTaskAction>(DuplicateTaskAction.newTask);
  const [boardId, setBoardId] = useState("");
  const [skipValue, setSkipValue] = useState<string>();
  const { getCustomFieldById } = useTaskFields({ entityId });
  const { getImportApiErrorMessage } = useImportApiErrorMessage();

  const handleResetState = useCallback(() => {
    setInputValue(undefined);
    setData([]);
    setTaskNameColumn(undefined);
    setMappedColumns([]);
    setDuplicateTaskAction(DuplicateTaskAction.newTask);
    setSkipValue(undefined);
    setBoardId("");
  }, []);

  const getFormattedDate = useCallback(
    (value: string) => {
      const date = parser.fromString(value);
      if (date.isValid()) {
        const dateObj = parser.attempt(value, dateLocale?.code);
        if ("hour" in dateObj || "minute" in dateObj || "second" in dateObj) {
          return date.toISOString().slice(0, -5);
        }
        return endOfDay(date).toISOString().slice(0, -5);
      }
      return undefined;
    },
    [dateLocale?.code]
  );

  const handleImportTasks = useCallback(async () => {
    if (taskNameColumn === undefined) return;
    transitionTo({
      name: "importLoading",
    });
    const response = await importTasks({
      entityId,
      data: {
        board_id: boardId,
        chk_duplicate_by:
          duplicateTaskAction === DuplicateTaskAction.skip
            ? skipValue
            : undefined,
        tasks: data
          .slice(1)
          .map((row) => {
            const name = row[taskNameColumn]?.toString().trim();
            if (!name || name.length < 2) return undefined;

            const task: ImportedTaskModel = {
              name: name.slice(0, TITLE_MAX_LENGTH),
            };

            mappedColumns.forEach((map) => {
              const value = row[map.column]?.toString().trim();
              if (!value) return;
              switch (map.value) {
                case "status":
                case "label":
                  task[
                    map.value as keyof Omit<ImportedTaskModel, "custom_fields">
                  ] = value.slice(0, TASK_TEXT_FIELD_CHAR_LIMIT);
                  break;
                case "due_at":
                  {
                    const date = getFormattedDate(value);
                    if (date) {
                      task[
                        map.value as keyof Omit<
                          ImportedTaskModel,
                          "custom_fields"
                        >
                      ] = date;
                    }
                  }
                  break;
                default: {
                  task.custom_fields = task.custom_fields || {};
                  const customField = getCustomFieldById(map.value);
                  if (!customField) return;

                  switch (customField.type) {
                    case "date": {
                      const date = getFormattedDate(value);
                      if (date) {
                        task.custom_fields[map.value] = date;
                      }
                      break;
                    }
                    case "number": {
                      const numberValue = Number(value);
                      if (!Number.isNaN(numberValue)) {
                        task.custom_fields[map.value] = numberValue;
                      }
                      break;
                    }
                    default:
                      task.custom_fields[map.value] = value;
                      break;
                  }
                }
              }
            });

            return task;
          })
          .filter((task): task is ImportedTaskModel => !!task),
      },
    });

    if (response && "data" in response) {
      const { duplicates, tasks_created: tasksCreated } = response.data;
      transitionTo({
        name: "importSuccess",
        duplicates,
        tasksCreated,
      });
      return;
    }
    if (isImportTasksApiError(response.error)) {
      toast(getImportApiErrorMessage(response.error.data), {
        variant: "error",
      });
      transitionTo({
        name: "importSettings",
      });
      return;
    }
    transitionTo({
      name: "importError",
    });
  }, [
    toast,
    boardId,
    data,
    duplicateTaskAction,
    entityId,
    importTasks,
    mappedColumns,
    skipValue,
    taskNameColumn,
    getFormattedDate,
    transitionTo,
    getCustomFieldById,
    getImportApiErrorMessage,
  ]);

  const content = useMemo<JSX.Element>(
    () =>
      renderContent({
        fileUpload: (
          <FileUploadScreen
            inputValue={inputValue}
            onInputValueChange={setInputValue}
            onReadFile={setData}
            onReset={handleResetState}
          />
        ),
        tasksName: (
          <TasksNameScreen
            data={data}
            taskNameColumn={taskNameColumn}
            onTaskNameColumnSelect={(nameColumn) => {
              setTaskNameColumn(nameColumn);
              setMappedColumns((prev) =>
                prev.filter((el) => el.column !== nameColumn)
              );
            }}
          />
        ),
        alignColumn: (
          <AlignColumnScreen
            data={data}
            taskNameColumn={taskNameColumn}
            mappedColumns={mappedColumns}
            onMappedColumnsChange={setMappedColumns}
          />
        ),
        importSettings: (
          <ImportSettingsScreen
            duplicateTaskAction={duplicateTaskAction}
            setDuplicateTaskAction={setDuplicateTaskAction}
            boardId={boardId}
            setBoardId={setBoardId}
            skipValue={skipValue}
            onSkipValueChange={setSkipValue}
            onImportTasks={handleImportTasks}
          />
        ),
        importLoading: <ImportLoadingScreen />,
        importError: <ImportErrorScreen onRetry={handleImportTasks} />,
        importSuccess: ({ duplicates, tasksCreated }) => (
          <ImportSuccessScreen
            boardName=""
            tasksCreated={tasksCreated}
            onClose={onRequestClose}
            duplicates={duplicates}
            isDuplicateTaskActionSkip={
              duplicateTaskAction === DuplicateTaskAction.skip
            }
          />
        ),
      }),
    [
      handleImportTasks,
      onRequestClose,
      renderContent,
      handleResetState,
      taskNameColumn,
      data,
      duplicateTaskAction,
      boardId,
      mappedColumns,
      skipValue,
      inputValue,
    ]
  );

  const getStepProgressTranslation = useCallback(
    (number: number) =>
      t(
        {
          id: "tasks-page.step-number",
          defaultMessage: "Step {number}/4",
        },
        { number }
      ),
    [t]
  );

  const stepProgressHeader = useMemo(
    () =>
      renderContent({
        fileUpload: getStepProgressTranslation(1),
        tasksName: getStepProgressTranslation(2),
        alignColumn: getStepProgressTranslation(3),
        importSettings: getStepProgressTranslation(4),
        importLoading: undefined,
        importError: undefined,
        importSuccess: undefined,
      }),
    [renderContent, getStepProgressTranslation]
  );

  const screenToDialogHeightClass = useMemo(
    () =>
      renderContent({
        fileUpload: "h-[600px]",
        tasksName: "h-[600px]",
        alignColumn: "h-[700px]",
        importSettings: "h-auto",
        importLoading: "h-auto",
        importError: "h-auto",
        importSuccess: "h-auto",
      }),
    [renderContent]
  );

  const handleSafeClose = useCallback(() => {
    if (screen.name === "importLoading") {
      return;
    }
    if (screen.name === "importSuccess" || !inputValue?.file) {
      onRequestClose();
      return;
    }

    setIsDiscardChangesDialogOpen(true);
  }, [screen, inputValue, onRequestClose]);

  useEffect(() => {
    if (!isOpen) {
      transitionTo({ name: "fileUpload" }, { force: true });
      handleResetState();
    }
  }, [isOpen, transitionTo, handleResetState]);

  return (
    <>
      <Dialog
        isOpen={isOpen}
        onClose={handleSafeClose}
        className={cx(
          "w-[720px] overflow-hidden rounded-2xl",
          screenToDialogHeightClass,
          isDiscardChangesDialogOpen && "!z-[50]"
        )}
      >
        <div className="flex h-full flex-col overflow-y-auto">
          <div className="border-dark-100 flex h-[60px] shrink-0 items-center gap-3 border-0 border-b border-solid px-6">
            <ImportIcon />
            <span className="font-secondary text-sm font-semibold text-[#383838]">
              {t({
                id: "tasks-page.import-tasks",
                defaultMessage: "Import Tasks",
              })}
            </span>
            {stepProgressHeader && (
              <span className="font-secondary text-sm text-[#828282]">
                {stepProgressHeader}
              </span>
            )}
            <PlainButton className="ml-auto" onClick={handleSafeClose}>
              <CloseIcon />
            </PlainButton>
          </div>
          <div className="grow overflow-y-auto px-10 py-8">
            <ScreenTransitionWrapper
              screenName={screen.name}
              className="h-full"
            >
              {content}
            </ScreenTransitionWrapper>
          </div>
        </div>
      </Dialog>
      <DiscardChangesDialog
        isOpen={isDiscardChangesDialogOpen}
        onClose={() => setIsDiscardChangesDialogOpen(false)}
        onSubmit={() => {
          setIsDiscardChangesDialogOpen(false);
          onRequestClose();
        }}
        content={t({
          id: "tasks-page.discard-changes-message",
          defaultMessage: "If you discard, entered info will not be saved",
        })}
      />
    </>
  );
};

export const TasksImportDialog: React.FC<TasksImportDialogProps> = (props) => {
  const initialScreen = useMemo<Screen<TasksImportScreenToParametersMap>>(
    () => ({ name: "fileUpload" }),
    []
  );

  return (
    <ScreenTransitionManagerProvider initialScreen={initialScreen}>
      <TasksImportDialogContent {...props} />
    </ScreenTransitionManagerProvider>
  );
};
