import { InteractiveContainer } from "@jugl-web/ui-components/cross-platform/InteractiveContainer";
import { cx } from "@jugl-web/utils";
import { FC } from "react";
import { useEditorToolbar } from "../../../common/components/RichTextarea/hooks/useEditorToolbar";
import { ReactComponent as BoldIcon } from "./assets/bold.svg";
import { ReactComponent as HighlightIcon } from "./assets/highlight.svg";
import { ReactComponent as ItalicIcon } from "./assets/italic.svg";
import { ReactComponent as OrderedListIcon } from "./assets/ordered-list.svg";
import { ReactComponent as StrikeIcon } from "./assets/strike.svg";
import { ReactComponent as UnderlineIcon } from "./assets/underline.svg";
import { ReactComponent as UnorderedListIcon } from "./assets/unordered-list.svg";

interface TaskDescriptionEditorToolbarProps {
  classes: { activeButton: string; inactiveButton: string };
}

export const TaskDescriptionEditorToolbar: FC<
  TaskDescriptionEditorToolbarProps
> = ({ classes }) => {
  const { toolbarState, formatSelection, insertList } = useEditorToolbar();

  return (
    <div className="flex w-full items-center justify-between">
      <InteractiveContainer
        onClick={() =>
          insertList(toolbarState.listType === "bullet" ? null : "bullet")
        }
        className={cx(
          "flex h-8 w-8 items-center justify-center rounded",
          toolbarState.listType === "bullet"
            ? classes.activeButton
            : classes.inactiveButton
        )}
      >
        <UnorderedListIcon />
      </InteractiveContainer>
      <InteractiveContainer
        onClick={() =>
          insertList(toolbarState.listType === "number" ? null : "number")
        }
        className={cx(
          "flex h-8 w-8 items-center justify-center rounded",
          toolbarState.listType === "number"
            ? classes.activeButton
            : classes.inactiveButton
        )}
      >
        <OrderedListIcon />
      </InteractiveContainer>
      <div className="bg-dark-200 h-8 w-px" />
      <InteractiveContainer
        onClick={() => formatSelection("bold")}
        className={cx(
          "flex h-8 w-8 items-center justify-center rounded",
          toolbarState.isBold ? classes.activeButton : classes.inactiveButton
        )}
      >
        <BoldIcon />
      </InteractiveContainer>
      <InteractiveContainer
        onClick={() => formatSelection("italic")}
        className={cx(
          "flex h-8 w-8 items-center justify-center rounded",
          toolbarState.isItalic ? classes.activeButton : classes.inactiveButton
        )}
      >
        <ItalicIcon />
      </InteractiveContainer>
      <InteractiveContainer
        onClick={() => formatSelection("strikethrough")}
        className={cx(
          "flex h-8 w-8 items-center justify-center rounded",
          toolbarState.isStrikethrough
            ? classes.activeButton
            : classes.inactiveButton
        )}
      >
        <StrikeIcon />
      </InteractiveContainer>
      <InteractiveContainer
        onClick={() => formatSelection("underline")}
        className={cx(
          "flex h-8 w-8 items-center justify-center rounded",
          toolbarState.isUnderline
            ? classes.activeButton
            : classes.inactiveButton
        )}
      >
        <UnderlineIcon />
      </InteractiveContainer>
      <div className="bg-dark-200 h-8 w-px" />
      <InteractiveContainer
        onClick={() => formatSelection("highlight")}
        className={cx(
          "flex h-8 w-8 items-center justify-center rounded",
          toolbarState.isHighlight
            ? classes.activeButton
            : classes.inactiveButton
        )}
      >
        <HighlightIcon />
      </InteractiveContainer>
    </div>
  );
};
