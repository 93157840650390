import { WhatsappAgentLogModel } from "@jugl-web/rest-api/integrations/models";
import { SidebarDrawer } from "@jugl-web/ui-components";
import React, { useEffect, useMemo, useState } from "react";
import { isoToLocalDate, useTranslations } from "@jugl-web/utils";
import format from "date-fns/format";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useWhatsappTemplateBodyRenderer } from "@web-src/modules/integrations/hooks/useWhatsappTemplateBodyRenderer";
import { AccountLogStatusPill } from "../AccountLogStatusPill";

export const AccountLogDetailsDialog: React.FC<{
  isOpen: boolean;
  onRequestClose: () => void;
  accountLog?: WhatsappAgentLogModel;
}> = ({ isOpen, onRequestClose, accountLog }) => {
  const { t } = useTranslations();
  const { tasksApi } = useRestApiProvider();
  const { entityId } = useEntitySelectedProvider();
  const [messageHtml, setMessageHtml] = useState("");

  const { renderHtml } = useWhatsappTemplateBodyRenderer();

  useEffect(() => {
    if (accountLog) {
      renderHtml({
        body: accountLog.msg.text,
        variables: accountLog.msg.params.map((item) => item.text),
      }).then(setMessageHtml);
    } else {
      setMessageHtml("");
    }
  }, [accountLog, renderHtml]);

  const { data: task, isSuccess } = tasksApi.useGetTaskQuery(
    accountLog?.type === "task"
      ? {
          entityId,
          taskId: accountLog.obj_id,
        }
      : skipToken
  );

  const AccountLogDetailsFields = useMemo(() => {
    if (!accountLog) {
      return [];
    }

    return [
      {
        label: t({
          id: "whatsapp-account-page.action-trigger",
          defaultMessage: "Action Trigger",
        }),
        value: <span className="font-semibold">{accountLog.trigger}</span>,
      },
      {
        label: t({
          id: "whatsapp-account-page.account-id",
          defaultMessage: "Account ID",
        }),
        value: accountLog.waba_id,
      },
      {
        label: t({
          id: "whatsapp-account-page.date",
          defaultMessage: "Date",
        }),
        value: format(
          isoToLocalDate(accountLog.inserted_at),
          "d MMM yyyy, h:mm a"
        ),
      },
      {
        label: t({
          id: "whatsapp-account-page.receiver-name",
          defaultMessage: "Receiver Name",
        }),
        value: (
          <span className="text-primary-800 underline">
            {accountLog.recipient_name}
          </span>
        ),
      },
      {
        label: t({
          id: "whatsapp-account-page.receiver-contact",
          defaultMessage: "Receiver Contact",
        }),
        value: (() => {
          const [, code, number] = accountLog.mobile.split(",");

          return `(${code}) ${number}`;
        })(),
      },
      ...(task && isSuccess
        ? [
            {
              label: t({
                id: "whatsapp-account-page.related-item",
                defaultMessage: "Related Item",
              }),
              value: (
                <span className="text-primary-800 underline">{task.name}</span>
              ),
            },
          ]
        : []),
      {
        label: t({
          id: "whatsapp-account-page.status",
          defaultMessage: "Status",
        }),
        value: <AccountLogStatusPill status={accountLog.status} />,
      },
    ];
  }, [accountLog, t, task, isSuccess]);

  return (
    <SidebarDrawer
      isOpen={isOpen}
      onClose={onRequestClose}
      title="Activity Log Details"
    >
      <SidebarDrawer.Content className="flex flex-col py-10 px-[50px]">
        {AccountLogDetailsFields.map((el, idx) => (
          <React.Fragment key={el.label}>
            <div className="flex items-center">
              <span className="text-dark-700 w-[200px] shrink-0">
                {el.label}
              </span>
              <div className="text-dark font-secondary overflow-hidden break-words">
                {el.value}
              </div>
            </div>
            {AccountLogDetailsFields.length - 1 !== idx && (
              <div className="bg-grey-200 my-4 h-px w-full" />
            )}
          </React.Fragment>
        ))}
        <div className="mt-12 flex flex-col gap-2">
          <span className="text-dark-500 font-secondary ml-2.5 truncate text-[15px]">
            {t({
              id: "whatsapp-account-page.sent-message",
              defaultMessage: "Sent Message",
            })}
          </span>
          <div
            className="border-grey-400 text-dark font-secondary overflow-hidden break-words rounded-[10px] border border-dashed bg-[#FAFAFA] px-5 py-4 leading-[150%]"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: messageHtml }}
          />
        </div>
      </SidebarDrawer.Content>
    </SidebarDrawer>
  );
};
