import { Button } from "@jugl-web/ui-components";
import { ResourcePickerPopover } from "@jugl-web/ui-components/web/ResourcePickerPopover";
import { cx, useTranslations } from "@jugl-web/utils";
import React, { useMemo } from "react";
import { useScreenTransitionManager } from "@jugl-web/utils/utils/ScreenTransitionManager";
import { Row } from "read-excel-file";
import { TasksImportScreenToParametersMap } from "../../types";
import { PickerButton } from "../PickerButton";

export const TasksNameScreen: React.FC<{
  data: Row[];
  taskNameColumn?: number;
  onTaskNameColumnSelect: (taskNameColumn: number) => void;
}> = ({ data, taskNameColumn, onTaskNameColumnSelect }) => {
  const { t } = useTranslations();
  const { transitionTo } =
    useScreenTransitionManager<TasksImportScreenToParametersMap>();

  const isColumnEmpty = useMemo(() => {
    if (typeof taskNameColumn !== "number") return false;
    const hasTaskNameColumnValue = data
      .slice(1)
      .map((row) => row[taskNameColumn])
      .some((el) => el?.toString().length > 1);
    if (hasTaskNameColumnValue) {
      return false;
    }
    return true;
  }, [data, taskNameColumn]);

  const selectedTaskNameColumn =
    typeof taskNameColumn === "number"
      ? data[0][taskNameColumn]?.toString()
      : undefined;

  return (
    <div className="flex h-full flex-col items-center text-center">
      <span className="text-dark font-secondary text-[22px] font-semibold leading-[42px]">
        {t({
          id: "tasks-page.name-your-tasks",
          defaultMessage: "Name Your Tasks 🖍️",
        })}
      </span>
      <span className="text-dark font-secondary mt-3 text-sm leading-[21px] tracking-[0.14px]">
        {t(
          {
            id: "tasks-page.select-column-with-task-name",
            defaultMessage:
              "Please select which Column should be saved as Task Name from your {br} uploaded Excel file",
          },
          { br: <br /> }
        )}
      </span>
      <ResourcePickerPopover
        placement="bottom"
        items={data[0].map((el, idx) => ({
          id: `${idx}`,
          value: {
            columnNumber: idx,
            label: el?.toString(),
          },
        }))}
        hasSearch
        shouldFilterOnSearch
        selectionBehavior={{ mode: "single", canToggle: false }}
        isCompact
        defaultSelectedIds={
          selectedTaskNameColumn ? [`${taskNameColumn}`] : undefined
        }
        maxVisibleItems={5}
        renderLabel={(item) => item.value.label}
        className="z-[2000]"
        onSelect={({ item, onClose }) => {
          onTaskNameColumnSelect(item.value.columnNumber);
          onClose();
        }}
        renderTrigger={({ Trigger, triggerRef, isOpen }) => (
          <Trigger
            ref={triggerRef}
            as={PickerButton}
            isOpen={isOpen}
            className={cx(
              "mt-12 w-[416px]",
              isColumnEmpty && "bg-tertiary-50 hover:bg-tertiary-50"
            )}
          >
            {selectedTaskNameColumn && (
              <span className="text-dark text-sm">
                {selectedTaskNameColumn}
              </span>
            )}
          </Trigger>
        )}
      />
      {isColumnEmpty && (
        <span className="text-tertiary-700 leading-2 mt-2 text-xs tracking-[0.12px]">
          {t({
            id: "tasks-page.column-is-empty",
            defaultMessage:
              "Column You selected is Empty. Please select different one",
          })}
        </span>
      )}
      <div className="mt-auto flex items-center justify-center gap-4">
        <Button
          className="w-[200px]"
          color="grey"
          onClick={() => transitionTo({ name: "fileUpload" })}
        >
          {t({
            id: "common.back",
            defaultMessage: "Back",
          })}
        </Button>
        <Button
          className="w-[200px]"
          onClick={() => transitionTo({ name: "alignColumn" })}
          isDisabled={!selectedTaskNameColumn || isColumnEmpty}
        >
          {t({
            id: "common.next",
            defaultMessage: "Next",
          })}
        </Button>
      </div>
    </div>
  );
};
