import { $insertDataTransferForPlainText } from "@lexical/clipboard";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_LOW,
  PASTE_COMMAND,
} from "lexical";
import { FC, useEffect } from "react";

interface RestrictedPastePluginProps {
  editorNamespace: string;
}

const LEXICAL_EDITOR_MIME_TYPE = "application/x-lexical-editor";

export const RestrictedPastePlugin: FC<RestrictedPastePluginProps> = ({
  editorNamespace,
}) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    const unregister = editor.registerCommand(
      PASTE_COMMAND,
      (event) => {
        event.preventDefault();

        const { clipboardData } = event as ClipboardEvent;

        if (!clipboardData) {
          // No clipboard data to handle
          return false;
        }

        if (clipboardData.types.includes(LEXICAL_EDITOR_MIME_TYPE)) {
          const lexicalData = JSON.parse(
            clipboardData.getData(LEXICAL_EDITOR_MIME_TYPE)
          ) as { namespace: string; nodes: unknown[] };

          if (lexicalData.namespace === editorNamespace) {
            // No need to handle the paste event if the data is from the same editor
            return false;
          }
        }

        editor.update(() => {
          const selection = $getSelection();

          if ($isRangeSelection(selection)) {
            // When the transferred data comes from an unknown source, paste it as plain text
            $insertDataTransferForPlainText(clipboardData, selection);
          }
        });

        return true;
      },
      COMMAND_PRIORITY_LOW
    );

    return unregister;
  }, [editor, editorNamespace]);

  return null;
};
