import { Avatar, InteractiveContainer, Popover } from "@jugl-web/ui-components";
import { cx } from "@jugl-web/utils";
import { useEntitiesListProvider } from "@web-src/features/entities/providers/EntitiesListProvider/EntitiesListProvider";
import { CreateNewEntityButton } from "@web-src/modules/entities/components/CreateNewEntityButton";
import { EntityCard } from "@web-src/modules/entities/components/EntityCard";
import { EntityInvitationCard } from "@web-src/modules/entities/components/EntityInvitationCard";
import {
  LeaveEntityConfirmationDialogsFlow,
  LeaveEntityConfirmationDialogsFlowHandle,
} from "@web-src/modules/entities/components/LeaveEntityConfirmationDialogsFlow";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useMemo, useRef } from "react";
import { useTheme } from "@web-src/themes/CustomThemeProvider";
import bellGif from "./assets/bell.gif";
import { ReactComponent as ChevronUpIcon } from "./assets/chevron-up.svg";

export const SelectEntityPopover: React.FC = () => {
  const { entity } = useEntitySelectedProvider();
  const { tcx } = useTheme();

  const {
    entitiesState: { entities },
    entityInvitesState: { entityInvites },
  } = useEntitiesListProvider();

  const reorderedEntities = useMemo(() => {
    const otherEntities = (entities || []).filter((e) => e.id !== entity.id);
    return [entity, ...otherEntities];
  }, [entities, entity]);

  const hasUnreadInDifferentEntity = useMemo(
    () => entities?.some((entityItem) => entityItem.unread),
    [entities]
  );

  const leaveEntityConfirmationDialogsFlowRef =
    useRef<LeaveEntityConfirmationDialogsFlowHandle | null>(null);

  return (
    <>
      <Popover
        className="z-40 ml-10 mt-[-8px]"
        placement="bottom-start"
        renderTrigger={({ Trigger, triggerRef, isOpen }) => (
          <Trigger
            as={InteractiveContainer}
            ref={triggerRef}
            className={tcx(
              "font-secondary hover:bg-primary-900 flex h-full w-[210px] items-center bg-transparent px-4 text-sm font-medium text-white outline-none duration-300 ease-out",
              {
                "bg-primary-900": isOpen,
                light: [
                  "hover:bg-primary-700",
                  {
                    "bg-[#1A75D2]": isOpen,
                  },
                ],
                dark: [
                  "hover:bg-[#3E3F40]",
                  {
                    "bg-[#3E3F40]": isOpen,
                  },
                ],
                blue: [
                  "hover:bg-primary-900",
                  {
                    "bg-primary-900": isOpen,
                  },
                ],
              }
            )}
          >
            <div className="relative mr-3 h-6 w-6">
              <div>
                <Avatar
                  size="sm"
                  username={entity.name}
                  imageUrl={entity.logoImg}
                  className="border-[0.2px] border-solid border-white"
                />
              </div>
              {hasUnreadInDifferentEntity && (
                <div className="bg-primary absolute right-[2px] bottom-[-1px] flex h-[15px] w-[15px] translate-x-1/2 items-center justify-center rounded-md">
                  <img src={bellGif} alt="" className="h-[21px] w-[21px]" />
                </div>
              )}
            </div>
            <div
              className="font-secondary mr-1 max-w-[300px] truncate text-sm font-medium text-white"
              title={entity.name}
            >
              {entity.name}
            </div>
            <ChevronUpIcon
              className={cx("shrink-0 transition-transform", {
                "rotate-180": isOpen,
              })}
            />
          </Trigger>
        )}
      >
        {({ onClose }) => (
          <>
            <div className="max-h-[551px] w-[440px] overflow-y-auto p-4">
              <CreateNewEntityButton />
              <div className="w-full py-4">
                <div className="bg-dark-100 h-[1px] w-full" />
              </div>
              {entityInvites?.map((invitation) => (
                <EntityInvitationCard
                  invitation={invitation}
                  key={invitation.id}
                />
              ))}
              {entityInvites?.length ? (
                <div className="w-full py-4">
                  <div className="bg-dark-100 h-[1px] w-full" />
                </div>
              ) : null}
              <div className="flex flex-col gap-1.5">
                {reorderedEntities.map((entityItem) => (
                  <EntityCard
                    key={entityItem.id}
                    entity={entityItem}
                    onLeaveEntity={() => {
                      onClose();
                      leaveEntityConfirmationDialogsFlowRef.current?.triggerLeaveEntityFlow(
                        entityItem
                      );
                    }}
                    onDeleteEntity={() => {
                      onClose();
                      leaveEntityConfirmationDialogsFlowRef.current?.triggerDeleteEntityFlow(
                        entityItem
                      );
                    }}
                    isSelected={entityItem.id === entity.id}
                    variant="popover"
                    showToastOnSwitch
                    onEntitySwitch={() => onClose()}
                  />
                ))}
              </div>
            </div>
          </>
        )}
      </Popover>
      <LeaveEntityConfirmationDialogsFlow
        ref={leaveEntityConfirmationDialogsFlowRef}
      />
    </>
  );
};
