import { WhatsappTemplateStatus } from "@jugl-web/rest-api/integrations/types";
import React, { useMemo } from "react";
import { cx, useTranslations } from "@jugl-web/utils";
import { Tooltip } from "@jugl-web/ui-components";
import { ReactComponent as InfoIcon } from "./assets/info.svg";
import { ReactComponent as VerifiedIcon } from "./assets/verified.svg";

export const TemplateApprovalTile: React.FC<{
  status: WhatsappTemplateStatus;
}> = ({ status }) => {
  const { t } = useTranslations();

  const { backgroundClassName, icon, text, textClassName, tooltipText } =
    useMemo(() => {
      switch (status) {
        case WhatsappTemplateStatus.pending:
          return {
            backgroundClassName: "bg-primary-50",
            text: t({
              id: "whatsapp-templates-page.pending",
              defaultMessage: "Pending",
            }),
            textClassName: "text-primary-900",
            icon: <InfoIcon />,
            tooltipText: t({
              id: "whatsapp-templates-page.pending-status-description",
              defaultMessage:
                "Update is going through WhatsApp approval process 🚀 It will take some time, you will receive email with the result 🙌",
            }),
          };
        case WhatsappTemplateStatus.rejected:
          return {
            backgroundClassName: "bg-tertiary-100",
            text: t({
              id: "whatsapp-templates-page.rejected",
              defaultMessage: "Rejected",
            }),
            textClassName: "text-tertiary-900",
            icon: <InfoIcon />,
            tooltipText: t({
              id: "whatsapp-templates-page.rejected-status-description",
              defaultMessage:
                "Due to WhatsApp policy, update will be rejected if it contains any words that don't align with the utility category 😓 Edit the content and try again 🙌",
            }),
          };
        case WhatsappTemplateStatus.approved:
          return {
            backgroundClassName: "bg-secondary-50",
            text: t({
              id: "whatsapp-templates-page.approved",
              defaultMessage: "Approved",
            }),
            textClassName: "text-secondary-900",
            icon: <VerifiedIcon />,
          };
        case WhatsappTemplateStatus.deleted:
          return {
            backgroundClassName: "bg-tertiary-100",
            text: t({
              id: "whatsapp-templates-page.deleted",
              defaultMessage: "Deleted",
            }),
            textClassName: "text-tertiary-900",
          };
        default:
          throw new Error("Unsupported status");
      }
    }, [t, status]);

  return (
    <Tooltip
      isDisabled={
        status === WhatsappTemplateStatus.approved ||
        status === WhatsappTemplateStatus.deleted
      }
      placement="bottom"
      renderTrigger={({ props, ref }) => (
        <div
          className={cx(
            "flex h-full w-full items-center justify-center gap-2.5 py-1 px-3",
            backgroundClassName
          )}
          ref={ref}
          {...props}
        >
          <span
            className={cx(
              "font-secondary text-sm leading-[140%] tracking-[0.14px]",
              textClassName
            )}
          >
            {text}
          </span>
          {icon}
        </div>
      )}
    >
      {tooltipText}
    </Tooltip>
  );
};
