import { insertList as lexicalInsertList, ListType } from "@lexical/list";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $setBlocksType } from "@lexical/selection";
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_CRITICAL,
  FORMAT_TEXT_COMMAND,
  SELECTION_CHANGE_COMMAND,
  TextFormatType,
} from "lexical";
import { useCallback, useEffect, useState } from "react";
import {
  $createEmptySpaceTextNode,
  $createExtendedTextNode,
} from "../nodes/ExtendedTextNode";
import { $createVariableNode } from "../nodes/VariableNode";
import { getListType } from "../utils";

const INITIAL_TOOLBAR_STATE = {
  isBold: false,
  isItalic: false,
  isUnderline: false,
  isStrikethrough: false,
  isHighlight: false,
  listType: null as ListType | null,
};

export const useEditorToolbar = () => {
  const [editor] = useLexicalComposerContext();

  const [toolbarState, setToolbarState] = useState(INITIAL_TOOLBAR_STATE);

  const formatSelection = (formatType: TextFormatType) => {
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, formatType);
  };

  const insertParagraph = useCallback(() => {
    editor.update(() => {
      const selection = $getSelection();

      if ($isRangeSelection(selection)) {
        $setBlocksType(selection, () => $createParagraphNode());
      }
    });
  }, [editor]);

  const insertText = useCallback(
    (text: string) => {
      editor.focus(() => {
        editor.update(() => {
          const selection = $getSelection();

          if ($isRangeSelection(selection)) {
            selection.insertNodes([$createExtendedTextNode(text)]);
          }
        });
      });
    },
    [editor]
  );

  const insertList = useCallback(
    (listType: ListType | null) => {
      if (listType === null) {
        insertParagraph();
        return;
      }

      lexicalInsertList(editor, listType);
    },
    [editor, insertParagraph]
  );

  const insertVariable = useCallback(
    (variableName: string) => {
      editor.focus(() => {
        editor.update(() => {
          const selection = $getSelection();

          if ($isRangeSelection(selection)) {
            const variableNode = $createVariableNode(variableName);

            let shouldInsertLeadingSpace = false;

            if (selection.isCollapsed()) {
              const anchorTextContent = selection.anchor
                .getNode()
                .getTextContent();

              const anchorOffset = selection.anchor.offset;

              if (anchorOffset > 0) {
                const charBeforeCursor = anchorTextContent[anchorOffset - 1];
                shouldInsertLeadingSpace = charBeforeCursor !== " ";
              }
            }

            const nodesToInsert = [variableNode, $createEmptySpaceTextNode()];

            if (shouldInsertLeadingSpace) {
              nodesToInsert.unshift($createEmptySpaceTextNode());
            }

            selection.insertNodes(nodesToInsert);
          }
        });
      });
    },
    [editor]
  );

  const $updateToolbar = useCallback(() => {
    const selection = $getSelection();

    if (!$isRangeSelection(selection)) {
      return;
    }

    setToolbarState({
      isBold: selection.hasFormat("bold"),
      isItalic: selection.hasFormat("italic"),
      isUnderline: selection.hasFormat("underline"),
      isStrikethrough: selection.hasFormat("strikethrough"),
      isHighlight: selection.hasFormat("highlight"),
      listType: getListType(selection),
    });
  }, []);

  useEffect(() => {
    const unregister = editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      () => {
        $updateToolbar();
        return false;
      },
      COMMAND_PRIORITY_CRITICAL
    );

    return () => {
      unregister();
    };
  }, [$updateToolbar, editor]);

  useEffect(() => {
    const unregister = editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        $updateToolbar();
      });
    });

    return () => {
      unregister();
    };
  }, [$updateToolbar, editor]);

  return {
    toolbarState,
    formatSelection,
    insertText,
    insertList,
    insertVariable,
  };
};
