import { Customer, useRestApiProvider } from "@jugl-web/rest-api";
import { useCallback, useEffect, useRef, useState } from "react";

interface UseCustomersOptions {
  entityId: string;
  searchQuery?: string;
  skipInitialLoading?: boolean;
}

interface CustomerListState {
  list: Customer[];
  currentPage: number;
  totalPages: number;
  isSearch?: boolean;
}

const DEFAULT_PAGE_SIZE = 20;

export const useCustomers = ({
  entityId,
  searchQuery,
  skipInitialLoading,
}: UseCustomersOptions) => {
  const [listState, setListState] = useState<CustomerListState>({
    list: [],
    currentPage: 1,
    totalPages: 0,
    isSearch: false,
  });

  const [isLoading, setIsLoading] = useState(false);

  const { customersApi } = useRestApiProvider();

  const [fetchCustomers] = customersApi.useLazyCustomersListQuery();

  const lastRequestRef = useRef<ReturnType<typeof fetchCustomers> | null>(null);

  const loadCustomers = useCallback(
    async (page = 1) => {
      setIsLoading(true);

      lastRequestRef.current?.abort();

      const request = fetchCustomers(
        {
          entityId,
          params: {
            page,
            page_size: DEFAULT_PAGE_SIZE,
            search_term: searchQuery || undefined,
          },
        },
        true
      );

      lastRequestRef.current = request;

      const response = await request;

      if (response.status === "fulfilled") {
        const fetchedCustomers = response.data.data;
        const isFirstPage = page === 1;

        setListState((previousState) => ({
          list: isFirstPage
            ? fetchedCustomers
            : [...previousState.list, ...fetchedCustomers],
          currentPage: response.data.page_number,
          totalPages: response.data.total_pages,
          isSearch: !!searchQuery,
        }));
      }

      setIsLoading(false);
    },
    [entityId, fetchCustomers, searchQuery]
  );

  const reachedEnd = listState.currentPage >= listState.totalPages;

  const loadMore = useCallback(() => {
    if (isLoading || reachedEnd) {
      return;
    }

    loadCustomers(listState.currentPage + 1);
  }, [isLoading, reachedEnd, loadCustomers, listState]);

  const setList = useCallback(
    (
      valueOrCallback: Customer[] | ((previousList: Customer[]) => Customer[])
    ) => {
      setListState((previousListState) => ({
        ...previousListState,
        list:
          typeof valueOrCallback === "function"
            ? valueOrCallback(previousListState.list)
            : valueOrCallback,
      }));
    },
    []
  );

  useEffect(() => {
    if (skipInitialLoading) {
      return;
    }

    loadCustomers();
  }, [loadCustomers, skipInitialLoading]);

  return {
    customers: listState.list,
    listState,
    isLoading,
    reachedEnd,
    loadMore,
    setList,
  };
};
