import {
  Collapse,
  InteractiveContainer,
} from "@jugl-web/ui-components/cross-platform";
import { cx } from "@jugl-web/utils";
import { forwardRef, HTMLAttributes, ReactNode, useState } from "react";
import { useTheme } from "@web-src/themes/CustomThemeProvider";
import { ReactComponent as ChevronRightSmIcon } from "../../assets/chevron-right-sm.svg";

interface SidebarAccordionProps extends HTMLAttributes<HTMLDivElement> {
  label: ReactNode;
  isHomeSidebarExpanded: boolean;
  children: ReactNode;
  onSideBarExpand: () => void;
  onClick?: () => void;
}

export const SidebarAccordion = forwardRef<
  HTMLDivElement,
  SidebarAccordionProps
>(
  (
    { label, isHomeSidebarExpanded, children, onSideBarExpand, onClick },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(false);

    const { tcx } = useTheme();

    const handleClick = () => {
      if (!isHomeSidebarExpanded) {
        onSideBarExpand();
        setIsOpen(true);
      } else {
        setIsOpen((prev) => !prev);
      }

      onClick?.();
    };

    return (
      <div ref={ref}>
        <InteractiveContainer
          onClick={handleClick}
          className={tcx(
            "mx-2.5 flex h-[41px] items-center rounded-lg text-sm transition-colors duration-300",
            {
              light: "text-dark hover:bg-[#ECF5FC]",
              dark: "text-white hover:bg-[#3E3F40]",
              blue: "text-white hover:bg-[#2F7DDA]",
            },
            isHomeSidebarExpanded
              ? "w-[190px] justify-between"
              : "w-[60px] justify-center"
          )}
        >
          {label}
          {isHomeSidebarExpanded && (
            <div
              className={cx(
                "text-primary-100 mr-[11px] flex h-[18px] w-[18px] items-center justify-center rounded transition-transform duration-300",
                isOpen && "rotate-90"
              )}
            >
              <ChevronRightSmIcon
                className={tcx("shrink-0", {
                  light: "fill-[#BBDEFB]",
                  dark: "fill-[#8F979A]",
                  blue: "fill-[#90C9F9]",
                })}
              />
            </div>
          )}
        </InteractiveContainer>
        <Collapse
          isOpen={isOpen}
          className={cx(!isHomeSidebarExpanded && "hidden")}
        >
          {children}
        </Collapse>
      </div>
    );
  }
);
