import { cx } from "@jugl-web/utils";
import { FC, PropsWithChildren, useMemo } from "react";
import { Tooltip } from "../../Tooltip";

export interface FormGroupProps {
  label: string;
  isInvalid?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  tooltip?: string;
  errorMessage?: string;
  classNames?: {
    wrapperClassName?: string;
    labelClassName?: string;
  };
  labelFor?: string;
  lengthIndicator?: {
    max?: number;
    min?: number;
    current?: number;
  };
}

export const FormGroup: FC<PropsWithChildren<FormGroupProps>> = ({
  label,
  isRequired,
  tooltip,
  errorMessage,
  isDisabled,
  labelFor,
  classNames: { labelClassName, wrapperClassName } = {},
  children,
  lengthIndicator,
  isInvalid,
}) => {
  const lengthIndicatorText = useMemo(() => {
    const { min, max, current } = lengthIndicator || {};
    let result = "";
    if (max) {
      result = `${current || 0}/${max} characters`;
    }
    if (min) {
      result = `${result ? `${result} ` : ""}(min.${min})`;
    }
    return result;
  }, [lengthIndicator]);
  return (
    <div className={cx("space-y-2", wrapperClassName)}>
      {label && (
        <Tooltip
          placement="right"
          isDisabled={!tooltip}
          className="font-secondary text-sm"
          renderTrigger={({ props, ref: tooltipRef }) => (
            <label
              ref={tooltipRef}
              htmlFor={labelFor}
              className={cx(
                "font-secondary border-grey-600 text-dark-700 relative ml-[10px] text-[15px] leading-[22px] transition-colors",
                tooltip &&
                  " hover:text-primary-900 border-0 border-b-[2px] border-dashed",
                isDisabled && "text-dark-500",
                labelClassName
              )}
              {...props}
            >
              {label}
              {isRequired && (
                <div className="text-gradients-danger absolute top-0 right-[-10px] text-base">
                  *
                </div>
              )}
            </label>
          )}
        >
          {tooltip}
        </Tooltip>
      )}
      {children}
      {(errorMessage || lengthIndicatorText) && (
        <div className="leading-2 font-secondary flex items-start justify-between gap-2 text-xs tracking-[0.14px]">
          {errorMessage ? (
            <div className="text-gradients-danger flex-1 break-words">
              {errorMessage}
            </div>
          ) : (
            <div />
          )}
          {lengthIndicatorText && (
            <div
              className={cx(
                "whitespace-nowrap",
                isInvalid ? "text-gradients-danger" : "text-grey"
              )}
            >
              {lengthIndicatorText}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
