import { useEntityProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useMemo } from "react";
import { apiUTCToLocalDateTime, cx } from "@jugl-web/utils";
import { EntityParticipantRole } from "@jugl-web/rest-api";
import differenceInDays from "date-fns/differenceInDays";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { InteractiveContainer } from "@jugl-web/ui-components";
import { useTheme } from "@web-src/themes/CustomThemeProvider";
import { ReactComponent as StarsDarkIcon } from "./assets/stars-dark.svg";
import { ReactComponent as StarsLightIcon } from "./assets/stars-light.svg";
import { ReactComponent as StarsBlueIcon } from "./assets/stars-blue.svg";

const themeIcons = {
  dark: <StarsDarkIcon />,
  light: <StarsLightIcon />,
  blue: <StarsBlueIcon />,
};

export const EntitySubscriptionInfoItem = () => {
  const { entity, subscriptionInfo } = useEntityProvider();
  const { navigateToPage } = useNavigation();
  const { tcx, customTheme } = useTheme();

  const text = useMemo(() => {
    if (!subscriptionInfo || subscriptionInfo.status === "free") {
      return null;
    }
    const planTillDays =
      differenceInDays(
        apiUTCToLocalDateTime(subscriptionInfo.planTill),
        new Date()
      ) || 1;
    const graceTillDays =
      differenceInDays(
        apiUTCToLocalDateTime(subscriptionInfo.graceTill),
        new Date()
      ) || 1;
    if (subscriptionInfo?.isInGrace) {
      return `Grace Period ends in ${graceTillDays} day${
        graceTillDays !== 1 ? "s" : ""
      }`;
    }
    if (subscriptionInfo?.isInTrial) {
      return `Trial ends in ${planTillDays} day${
        planTillDays !== 1 ? "s" : ""
      }`;
    }
    return null;
  }, [subscriptionInfo]);

  if (!text || !entity) {
    return null;
  }

  const handleClick = () => {
    if (entity.role !== EntityParticipantRole.admin) {
      return;
    }
    navigateToPage("workspaceSubscription");
  };

  return (
    <InteractiveContainer
      className={tcx("flex h-8 items-center gap-2 rounded-lg px-2.5", {
        dark: "bg-[#3E3F40] hover:bg-[#333333]",
        blue: "bg-[#1A75D2] hover:bg-[#1F87E5]",
        light: "bg-[#1A75D2] hover:bg-[#1764C0]",
      })}
      onClick={handleClick}
    >
      {themeIcons[customTheme]}
      <div className={cx("text-sm font-medium text-white")}>{text}</div>
    </InteractiveContainer>
  );
};
