import { useRestApiProvider } from "@jugl-web/rest-api";
import React, { useCallback, useRef, useState } from "react";
import { WhatsappAgentLogModel } from "@jugl-web/rest-api/integrations/models";
import { useEffectOnce } from "react-use";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { DataLoadingWrapper, TableGrid } from "@jugl-web/ui-components";
import { isoToLocalDate, useTranslations } from "@jugl-web/utils";
import format from "date-fns/format";
import { AccountLogDetailsDialog } from "./components/AccountLogDetailsDialog";
import { AccountLogStatusPill } from "./components/AccountLogStatusPill";

type PageState = {
  pageNumber: number;
  requestId: number;
  isError?: boolean;
  hasMore?: boolean;
};

export const AccountLogsTable: React.FC = () => {
  const { integrationsApi } = useRestApiProvider();
  const { t } = useTranslations();
  const [getAgentLogs, { isError, isLoading, isFetching }] =
    integrationsApi.useLazyGetAgentLogsQuery();
  const currentPageState = useRef<PageState | null>(null);
  const { entityId } = useEntitySelectedProvider();
  const [agentLogs, setAgentLogs] = useState<WhatsappAgentLogModel[]>([]);
  const [selectedAgentLog, setSelectedAccountLog] =
    useState<WhatsappAgentLogModel>();

  const loadNextPage = useCallback(async () => {
    let nextPageNumber = 1;
    if (currentPageState.current) {
      if (currentPageState.current.isError) {
        nextPageNumber = currentPageState.current.pageNumber;
      } else {
        nextPageNumber = currentPageState.current.pageNumber + 1;
      }
    }

    const nextPageState: PageState = {
      requestId: Math.random(),
      pageNumber: nextPageNumber,
    };

    currentPageState.current = nextPageState;

    const result = await getAgentLogs({
      entityId,
      page: nextPageNumber,
      page_size: 10,
    });

    if (currentPageState.current?.requestId !== nextPageState.requestId) {
      return;
    }

    if ("error" in result && result.error) {
      nextPageState.isError = true;
      currentPageState.current = nextPageState;
      return;
    }

    if ("data" in result && result.data && result.data.data) {
      const responseData = result.data.data;
      setAgentLogs((prev) =>
        nextPageNumber === 1 ? responseData : [...prev, ...responseData]
      );

      nextPageState.hasMore = result.data.total_pages > nextPageNumber;
      currentPageState.current = nextPageState;
    }
  }, [entityId, getAgentLogs]);

  useEffectOnce(() => {
    loadNextPage();
  });

  return (
    <>
      <DataLoadingWrapper
        onRetry={loadNextPage}
        isError={isError}
        isLoading={
          isLoading ||
          (isFetching && currentPageState.current?.pageNumber === 1)
        }
        isFetching={isFetching}
      >
        <TableGrid
          data={agentLogs}
          onReachEnd={() =>
            currentPageState.current?.hasMore && !isLoading && !isFetching
              ? loadNextPage()
              : undefined
          }
          className="overflow-hidden"
          cellClassName="font-secondary text-sm leading-[150%] text-dark overflow-hidden break-all flex items-center cursor-pointer"
          inline
          onRowClick={setSelectedAccountLog}
          columns={[
            {
              title: t({
                id: "whatsapp-account-page.action-trigger",
                defaultMessage: "Action Trigger",
              }),
              grow: true,
              className: "font-medium leading-[140%] tracking-[0.16px]",
              content: ({ trigger }) => <>{trigger}</>,
            },
            {
              title: t({
                id: "whatsapp-account-page.date",
                defaultMessage: "Date",
              }),
              grow: true,
              className: "text-primary-800 underline",
              content: ({ inserted_at }) => (
                <>{format(isoToLocalDate(inserted_at), "d MMM yyyy, h:mm a")}</>
              ),
            },
            {
              title: t({
                id: "whatsapp-account-page.update-sent-to",
                defaultMessage: "Update Sent to",
              }),
              grow: true,
              content: ({ recipient_name, mobile }) => {
                const [, code, number] = mobile.split(",");

                return (
                  <div className="flex flex-col gap-0.5">
                    <span>{recipient_name}</span>
                    <span className="text-[#828282]">
                      {`(${code}) ${number}`}
                    </span>
                  </div>
                );
              },
            },
            {
              title: t({
                id: "whatsapp-account-page.account-id",
                defaultMessage: "Account ID",
              }),
              grow: true,
              content: ({ waba_id }) => <>{waba_id}</>,
            },
            {
              title: t({
                id: "whatsapp-account-page.status",
                defaultMessage: "Status",
              }),
              grow: true,
              content: ({ status }) => <AccountLogStatusPill status={status} />,
            },
          ]}
        />
        {!agentLogs.length && (
          <span className="text-dark-700 ml-8">
            {t({
              id: "whatsapp-account-page.activity-logs-will-be-listed-here",
              defaultMessage:
                "🗒️ After setting WhatsApp Updates, all Activity Logs will be listed here",
            })}
          </span>
        )}
      </DataLoadingWrapper>
      <AccountLogDetailsDialog
        isOpen={!!selectedAgentLog}
        onRequestClose={() => setSelectedAccountLog(undefined)}
        accountLog={selectedAgentLog}
      />
    </>
  );
};
