import { PageNavParams } from "@web-src/modules/navigation/types";
import { ModulePagesConfig } from "@web-src/modules/navigation/types/ModulePagesConfigs";
import { TravelLogPage } from "./TravelLogPage";
import { LocationPage } from "./LocationPage";

export type TravelLogPagesNavigationParams = {
  travelLogSchedule: PageNavParams;
  travelLogScheduleDate: PageNavParams<{ date: string }, { search?: string }>;
  travelLogLocation: PageNavParams<{ userId: string; date: string }>;
};

export const travelLogPagesNavigationConfig: ModulePagesConfig<TravelLogPagesNavigationParams> =
  {
    travelLogSchedule: {
      element: <TravelLogPage />,
      path: "travel-log",
      isPrivate: true,
    },
    travelLogScheduleDate: {
      element: <TravelLogPage />,
      path: "travel-log/:date",
      isPrivate: true,
    },
    travelLogLocation: {
      element: <LocationPage />,
      path: "travel-log/location/:userId/:date",
      isPrivate: true,
      isAdminRequired: true,
    },
  };
