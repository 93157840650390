import { Menu, MenuProps } from "@jugl-web/ui-components/cross-platform/Menu";
import { useTranslations } from "@jugl-web/utils";
import { FC } from "react";
import { ReactComponent as DeleteIcon } from "./icons/delete.svg";

interface TaskChecklistItemContextMenuProps {
  itemId: string;
  renderTrigger: MenuProps["renderTrigger"];
  onDelete: (itemId: string) => void;
}

export const TaskChecklistItemContextMenu: FC<
  TaskChecklistItemContextMenuProps
> = ({ itemId, renderTrigger, onDelete }) => {
  const { t } = useTranslations();

  return (
    <Menu
      placement="bottom-start"
      className="min-w-[155px]"
      sections={[
        [
          {
            id: "delete",
            label: t({
              id: "common.delete",
              defaultMessage: "Delete",
            }),
            icon: <DeleteIcon />,
            labelClassName: "text-gradients-danger",
            onSelect: (event, close) => {
              event.stopPropagation();
              onDelete(itemId);
              close();
            },
          },
        ],
      ]}
      renderTrigger={renderTrigger}
    />
  );
};
