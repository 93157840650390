import {
  ModulePagesConfig,
  PageNavParams,
} from "@web-src/modules/navigation/types";
import { WhatsappTemplatesPage } from "./WhatsappTemplatesPage";
import { WhatsappTemplateFormPage } from "./WhatsappTemplateFormPage";
import { WhatsappAccountPage } from "./WhatsappAccountPage";

export type IntegrationsPagesNavigationParams = {
  integrationsWhatsappTemplates: PageNavParams;
  integrationsWhatsappTemplateCreate: PageNavParams;
  integrationsWhatsappAccount: PageNavParams;
  integrationsWhatsappTemplateEdit: PageNavParams<{
    templateId: string;
  }>;
};

export const integrationsPagesNavigationConfig: ModulePagesConfig<IntegrationsPagesNavigationParams> =
  {
    integrationsWhatsappTemplates: {
      element: <WhatsappTemplatesPage />,
      path: "integrations/whatsapp/templates",
      isPrivate: true,
      isAdminRequired: true,
    },
    integrationsWhatsappTemplateCreate: {
      element: <WhatsappTemplateFormPage />,
      path: "integrations/whatsapp/templates/create",
      isPrivate: true,
      isAdminRequired: true,
    },
    integrationsWhatsappAccount: {
      element: <WhatsappAccountPage />,
      path: "integrations/whatsapp/account",
      isPrivate: true,
      isAdminRequired: true,
    },
    integrationsWhatsappTemplateEdit: {
      element: <WhatsappTemplateFormPage />,
      path: "integrations/whatsapp/templates/edit/:templateId",
      isPrivate: true,
      isAdminRequired: true,
    },
  };
