import {
  InteractiveContainer,
  Menu,
} from "@jugl-web/ui-components/cross-platform";
import { useTranslations } from "@jugl-web/utils";
import { useTheme } from "@web-src/themes/CustomThemeProvider";

import { IconButton } from "../IconButton";
import { ReactComponent as BlueThemeIcon } from "./assets/blue-theme.svg";
import { ReactComponent as DarkThemeIcon } from "./assets/dark-theme.svg";
import { ReactComponent as LightThemeIcon } from "./assets/light-theme.svg";
import { ReactComponent as ThemeIcon } from "./assets/theme.svg";

export const ThemePicker = () => {
  const { customTheme, setCustomTheme } = useTheme();
  const { t } = useTranslations();

  return (
    <Menu
      className="flex h-[240px] w-[222px] flex-col items-center gap-2 p-4"
      placement="bottom"
      selectedId={customTheme}
      renderTrigger={({ Trigger, triggerRef }) => (
        <Trigger ref={triggerRef} as={InteractiveContainer}>
          <IconButton
            tooltipText={t(
              {
                id: "main-navbar-component.theme-tooltip",
                defaultMessage: "{theme} Theme",
              },
              {
                theme: <span className="capitalize">{customTheme}</span>,
              }
            )}
            onClick={() => {}}
            content={<ThemeIcon />}
            isActive={false}
            className="h-8 w-8"
          />
        </Trigger>
      )}
      sections={[
        [
          {
            id: "light",
            label: t({
              id: "main-navbar-component.light-theme",
              defaultMessage: "Light Theme",
            }),
            onSelect: (_, close) => {
              setCustomTheme("light");
              close();
            },
            icon: (
              <div className="bg-primary-50 flex h-[32px] w-[32px] items-center justify-center rounded-md border-[1px] border-solid border-white">
                <LightThemeIcon className="fill-primary h-[19.2px] w-[19.2px]" />
              </div>
            ),
            labelClassName: "text-base font-medium font-secondary text-dark",
            className: `active:bg-primary-50 w-full ${
              customTheme === "light"
                ? "hover:bg-[#D2ECFF] "
                : "hover:bg-grey-200"
            } rounded-xl h-[64px] ${
              customTheme === "light" ? "bg-primary-50" : ""
            }`,
          },

          {
            id: "blue",
            label: t({
              id: "main-navbar-component.blue-theme",
              defaultMessage: "Blue Theme",
            }),
            icon: (
              <div className="bg-primary flex h-[32px] w-[32px] items-center justify-center rounded-md border-[1px]">
                <BlueThemeIcon className="h-[19.2px] w-[19.2px]" />
              </div>
            ),
            onSelect: (_, close) => {
              setCustomTheme("blue");
              close();
            },
            labelClassName: "text-base font-medium font-secondary text-dark",
            className: `active:bg-primary-50 w-full ${
              customTheme === "blue"
                ? "hover:bg-[#D2ECFF] "
                : "hover:bg-grey-200"
            } rounded-xl h-[64px] ${
              customTheme === "blue" ? "bg-primary-50" : ""
            }`,
          },

          {
            id: "dark",
            label: t({
              id: "main-navbar-component.dark-theme",
              defaultMessage: "Dark Theme",
            }),
            icon: (
              <div className="flex h-[32px] w-[32px] items-center justify-center rounded-md border-[1px] bg-[#333333]">
                <DarkThemeIcon />
              </div>
            ),
            onSelect: (_, close) => {
              setCustomTheme("dark");
              close();
            },
            labelClassName: "text-base font-medium font-secondary text-dark",
            className: `active:bg-primary-50 w-full ${
              customTheme === "dark"
                ? "hover:bg-[#D2ECFF] "
                : "hover:bg-grey-200"
            } rounded-xl h-[64px] ${
              customTheme === "dark" ? "bg-primary-50" : ""
            }`,
          },
        ],
      ]}
    />
  );
};
