import React from "react";

export const SectionHeader: React.FC<{
  title: string;
  subtitle?: string;
  number: number;
}> = ({ title, subtitle, number }) => (
  <div className="flex items-center gap-4">
    <div className="bg-primary-600 font-secondary flex h-6 w-6 items-center justify-center rounded-full text-xs font-bold text-white">
      {number}
    </div>
    <div className="flex flex-col gap-1">
      <span className="font-secondary text-xl font-medium text-[#171717]">
        {title}
      </span>
      {subtitle && (
        <span className="txt-dark-700 font-secondary text-sm">{subtitle}</span>
      )}
    </div>
  </div>
);
