import { useTheme } from "@web-src/themes/CustomThemeProvider";

interface UnreadCountBoxProps {
  count: number;
  className?: string;
}

const MAX_COUNT = 99;

export const UnreadCountBox = ({ count, className }: UnreadCountBoxProps) => {
  const { tcx } = useTheme();

  return (
    <div
      className={tcx(
        "flex h-[24px] w-[26px] items-center justify-center rounded-lg bg-white px-1 transition-colors duration-300",
        {
          light: "bg-primary-400 text-white",
          dark: "text-[#3E3F40]",
          blue: "text-primary-700",
        },
        className
      )}
    >
      <span className="text-xs font-black">
        {count > MAX_COUNT ? `${MAX_COUNT}+` : count}
      </span>
    </div>
  );
};
